@charset "UTF-8";

/* mixins */

/**
*    create color-pattern-placeholder
*
*    desc:
*        the following script creates placeholders which can be extended into existing elements
*
*        following placeholders will be created:
*            %patter-font-color                        //overwrites the color
*            %patter-background-color                //overwrites the background-color
*            %patter-border-color                    //overwrites the border-color
*
*    usage:
*        @extend %patter-font-color;
*        @extend %patter-background-color;
*        @extend %patter-border-color;
*
*    info:
*
**/
@each $current-color in $color-pattern-list {
    $i: index($color-pattern-list, $current-color);
    $current-contrast-color: nth($color-pattern-list-contrast, $i);

    //generate pattern-classes and apply css
    %color-pattern-#{$i} {
        //font-color
        %patter-font-color {
            color: $current-color;
        }

        //background-color
        %patter-background-color {
            background-color: $current-color;
        }

        //background-color
        %patter-sub-background-color {
            background: rgba($current-color, .5);
        }

        //border-color
        %patter-border-color {
            border-color: $current-color;
        }

        &.color-pattern-contrast {
            //font-color
            %patter-font-color {
                color: $current-contrast-color;
            }

            //background-color
            %patter-background-color {
                background-color: $current-contrast-color;
            }

            //background-color
            %patter-sub-background-color {
                background: rgba($current-contrast-color, .5);
            }

            //border-color
            %patter-border-color {
                border-color: $current-contrast-color;
            }
        }
    }
    .color-pattern-#{$i} {
        @extend %color-pattern-#{$i};
    }
}

/**
 * Calculate percent value to given property
 *
 * @param {string} $cssProperty - which property
 * @param {number} $targetSize - element size in pixel
 * @param {number} $contextSize - context element size in pixel
 * @param {boolean} $important - set !important property
 *
 */
@mixin calculatePercentValueOfProperty($cssProperty, $targetSize, $contextSize, $important: false) {
    @if $important == true {
        #{$cssProperty}: percentage($targetSize/$contextSize) !important;
    } @else {
        #{$cssProperty}: percentage($targetSize/$contextSize);
    }
}

/**
 * Calculate PX font size to REM
 *
 * - add PX fallback for older browser
 *
 * @param {number} $size - element size in pixel
 * @param {number} $base - body font size in pixel
 *
 */
@mixin fontSizeREM($size: $body-font-size-px, $base: $base-em) {
    font-size: $size + px;
    font-size: ($size / $base) + rem;
}

/**
 * Calculates given px value to rem value
 *
 * @param {string} $prop - css property name, e.g. font-size, line-height; Default: 'font-size'
 * @param {number} $size - size in px; Default: $body-font-size-px
 *
 */
@mixin propToREM($prop: 'font-size', $size: $body-font-size-px) {
    #{$prop}: $size + px;
    #{$prop}: ($size / $base-em) + rem;
}

/**
* Mixin for adding vendor prefixes to CSS attributes; useful for the following CSS attributes
*
* - border-radius
* - box-shadow
* - transition
* - transform
* - background-size
* - box-sizing
* - animate
*
* @param {string} $attribute - CSS attribute that needs vendor prefixes, e.g. border-radius or transition
* @param {string} $value - The specific CSS value, e.g. "translateX .2s ease-out"
*/
$prefixes: ("-webkit-", "-moz-", "-o-", "-ms-", "");
@mixin prefix($attribute, $value) {
    @each $prefix in $prefixes {
        #{$prefix}#{$attribute}: unquote("#{$value}");
    }
}

/**
* Mixin for adding svg background image + png fallback
*
* - add png fallback for older browser
*
* @param {string} $name - image name
* @param {string} $extension - file extension + folder
* @param {string} $color - background-color
* @param {number} $position - background position
* @param {string} $repeat - background repeat
*
* Use:
* @include svgBG('logo')
* @include svgBG('logo', $color: #fff, ...);
*
*/
@mixin svgBG($name: image-name, $extension: png, $color: transparent, $position: 0 0, $repeat: no-repeat) {
    background: url(../img/svg/#{$name}.svg) $color $position $repeat;
}

/**
* Clearfix
*
* For modern browsers
* 1. The space content is one way to avoid an Opera bug when the
*     contenteditable attribute is included anywhere else in the document.
*     Otherwise it causes space to appear at the top and bottom of elements
*     that are clearfixed.
* 2. The use of `table` rather than `block` is only necessary if using
*     `:before` to contain the top-margins of child elements.
*
* Source: http://nicolasgallagher.com/micro-clearfix-hack/
*/

@mixin clearfix() {
    &:before,
    &:after {
        content: " "; // 1
        display: table; // 2
    }
    &:after {
        clear: both;
    }
}

/**
 * px to em
 *
 * - calculate em from px
 *
 * $size = size in pixel
 * $base = body font size in pixel
 *
 */
@function calcPxToEm($size: $body-font-size-px, $base: $body-font-size-px) {
    @return ($size / $base) * 1em;
}

/**
* Grid mixins
*
* Generate semantic grid columns with these mixins.
* based on the bootstrap-sass partial
*/

// Centered container element
@mixin containerFixed($gutter: $grid-gutter-width) {
    margin-right: auto;
    margin-left: auto;
    padding-left: ($gutter / 2);
    padding-right: ($gutter / 2);
    @include clearfix();
}

// Creates a wrapper for a series of columns
@mixin makeRow($gutter: $grid-gutter-width) {
    margin-left: ($gutter / -2);
    margin-right: ($gutter / -2);
    @include clearfix();
}

// [converter] This is defined recursively in LESS, but Sass supports real loops
@mixin makeGridColumns($i: 1, $list: ".col-xs-#{$i},  .col-s-#{$i}, .col-m-#{$i}, .col-l-#{$i}, .col-xl-#{$i}") {
    @for $i from (1 + 1) through $grid-columns {
        $list: "#{$list}, .col-xs-#{$i}, .col-s-#{$i}, .col-m-#{$i}, .col-l-#{$i}, .col-xl-#{$i}";
    }
    #{$list} {
        //position: relative;
        // Prevent columns from collapsing when empty
        min-height: 1px;
        // Inner gutter via padding
        padding-left: ($grid-gutter-width / 2);
        padding-right: ($grid-gutter-width / 2);
    }
}

// [converter] This is defined recursively in LESS, but Sass supports real loops
@mixin floatGridColumns($class, $i: 1, $list: ".col-#{$class}-#{$i}") {
    @for $i from (1 + 1) through $grid-columns {
        $list: "#{$list}, .col-#{$class}-#{$i}";
    }
    #{$list} {
        float: left;
    }
}

// [converter] This is defined recursively in LESS, but Sass supports real loops
@mixin loopGridColumns($columns, $class, $type) {
    @for $i from 0 through $columns {
        @include calcGridColumn($i, $class, $type);
    }
}

@mixin calcGridColumn($index, $class, $type) {
    @if ($type == width) and ($index > 0) {
        .col-#{$class}-#{$index} {
            width: percentage(($index / $grid-columns));
        }
    }
}

// Create grid for specific class
@mixin makeGrid($class) {
    @include floatGridColumns($class);
    @include loopGridColumns($grid-columns, $class, width);
}


