@charset "UTF-8";

/* mod-lexicon */

@each $color in $color-pattern-list {
    $index: index($color-pattern-list, $color);
    $contrast-color: nth($color-pattern-list-contrast, $index);

    .color-pattern-#{$index} .mod-lexicon .lexicon-item {
        background: $color;
    }

    .color-pattern-#{$index} .mod-lexicon .controls-lexicon select,
    .color-pattern-#{$index} .mod-lexicon .controls-lexicon span:after,
    .color-pattern-#{$index} .mod-lexicon .controls-lexicon a.prev:before,
    .color-pattern-#{$index} .mod-lexicon .controls-lexicon a.next:before {
        color: $color;
    }

    .color-pattern-contrast.color-pattern-#{$index} .mod-lexicon .lexicon-item {
        background: $contrast-color;
    }
}

.mod-lexicon {
    .headline-lexicon {
        margin-top: 0;
        font-weight: inherit;
        margin-bottom: 16px;
        @include respond-max($breakpoint-max-xs) {
            @include prefix(hyphens, 'auto');
        }
        @include fontSizeREM(30);
        float: left;
    }

    ul {
        @extend %row;
        clear: both;
        padding: 0;
        list-style: none;

        li {
            padding-left: 10px;
            padding-right: 10px;

            @include respond-min($breakpoint-min-s) {
                width: 50%;
                float: left;
            }

            @include respond-min($breakpoint-min-l) {
                width: 25%;
                float: left;
            }
        }
    }

    .lexicon-item {
        padding: $grid-gutter-width*.75;
        display: block;
        margin: 0 auto $grid-gutter-width auto;

        div {
            margin: 0 auto;
            min-height: 70px;
            line-height: 70px;
            text-align: center;
        }

        strong {
            color: $white;
            vertical-align: middle;
            display: inline-block;
            line-height: normal;
        }

        background: $main-color !important;
        transition: all ease-in-out .2s;

        @media only screen and (min-width: $breakpoint-min-m) {
            &:hover {
                background: #dfd9d1 !important;
            }
        }

        strong {
            color: $text-color !important;
        }
    }

    .controls-lexicon {
        float: right;
        position: relative;

        > * {
            float: left;
            background: #f3f3f3;
            border: solid 1px $border-color;

            @media only screen and (min-width: $breakpoint-min-m) {
                &:hover {
                    background: #ecebea;
                }
            }
        }

        a {
            height: 44px;
            width: 42px;
            display: block;
            text-align: center;

            &.prev,
            &.next {
                font-size: 0;

                &:before {
                    @extend %icon-font;
                    @include fontSizeREM(25);
                    margin-right: $grid-gutter-width/2;
                    color: $default-color;
                    position: relative;
                    top: 8px;
                    left: 5px;
                    vertical-align: -30%;
                }

                &:after {
                    display: none !important;
                }
            }

            &.prev {
                border-right: none;
                margin-left: $grid-gutter-width*.75;

                &:before {
                    @extend .icon-top;
                }
            }

            &.next {

                &:before {
                    @extend .icon-down;
                }
            }

            &:active,
            &:hover {
                background: #ecebea;
            }

            &.is-disabled {
                cursor: default;

                &:before {
                    color: #dadcde !important;
                }

                &:hover {
                    background: #f3f3f3;
                }
            }
        }

        /* Custom Select: Start */
        select {
            @include fontSizeREM(14);
            padding: 11px $grid-gutter-width 10px $grid-gutter-width;
            width: 109px;
            cursor: pointer;
            font-family: inherit;
            -moz-box-sizing: border-box; /* Firefox */
            -webkit-box-sizing: border-box; /* Safari */
            box-sizing: border-box;
            -webkit-appearance: none;
            -moz-appearance: none;
            -o-appearance: none;
            appearance: none;
            border: 0;
            font-weight: 600;
            vertical-align: top;
            z-index: 1;
            position: relative;
            background: transparent;

            @include respond-max($breakpoint-max-s) {
                @include fontSizeREM(16);
            }

            &:focus {
                outline: none;
            }
        }

        select::-ms-expand {
            display: none;
        }

        &:after {
            @extend %clearfix;
        }

        span {
            position: absolute;
            top: 21px;
            left: 120px;
            @include fontSizeREM(12);
            z-index: 0;

            &:before {
                display: none !important;
            }

            &:after {
                @extend %icon-font;
                @extend .icon-down;
                @include icon-background(30, #fff);
                @include fontSizeREM(20);
                margin-left: $grid-gutter-width/2;
                border: 1px solid $border-color-form;
                position: absolute;
                right: 30px;
                top: -15px;
                vertical-align: -30%;
            }
        }
        /* Custom Select: End */
    }

    section {
        padding: 20px 0 0;
    }
}

// Contrast-Styles
.color-pattern-contrast {

    .lexicon-item {
        background: $white !important;
        outline: 1px solid;

        &:hover {
            background: $white !important;
            outline: 2px solid;
        }

        strong {
            color: $black !important;
        }
    }

    .controls-lexicon {

        .field {
            background: $white;
            border: 1px solid $black !important;
            color: $black;

            &:hover {
                outline: 1px solid $black !important;
            }
        }

        .prev,
        .next {
            background: $white !important;
            border: 1px solid;
            color: $black !important;

            &:hover {
                outline: 1px solid;
            }
        }
        .prev:hover {
            border-right: 1px solid;
        }

        span:after {
            background: $white;
            border: 1px solid $black;
        }
    }
}