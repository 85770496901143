@charset "UTF-8";

/* buttons */

/*
*    @desc:    button-mixin to create consistent buttons
*
*    @param    $btn-bg-color                [any color]
*    @param    $btn-bg-color-hover            [any color]
*    @param    $btn-border-color            [any color]
*    @param    $btn-font-color                [any color]
*
*    @how-to:
*        // define all the button properties in the $button-properties list
*        // call the button-mixin to create the initial css like this:
*
*        .button-classname {
*            @include createBtn();
*        }
*
*/

/* button-mixin: vars */
$button-properties: (
    /* 1*/
        'Roboto', // default btn font-family's
            /* 2*/
        #565656, // default btn font-color
            /* 3*/
        16, // default btn font-size in px
            /* 4*/
        0, // default btn border-radius
            /* 5*/
        solid 1px, // default btn border
            /* 6*/
        none, // default btn box-shadow
            /* 7*/
        none, // default btn text-decoration
            /* 8*/
        none, // default btn text-transform
            /* 9*/
        10px 20px, // default btn padding
            /*10*/
        .7, // default btn opacity
            /*11*/
        #f3f3f3 // default btn background
);

/* button-mixin: basic-styles */
%btn-basics {
    display: inline-block;
    cursor: pointer;
    position: relative;

    /* types */
    &.btn-full-width {
        display: block;
        text-align: center;
        width: 100%;
        margin-bottom: 10px;
    }

    &[disabled] {
        cursor: default;
        opacity: .7;

        &:hover {
            opacity: .7;
        }
    }
}

@mixin createBtn(//mixin parameters
    $btn-bg-color: nth($button-properties, 11),
    $btn-bg-color-hover: $btn-bg-color,
    $btn-border-color: $btn-bg-color,
    $btn-font-color: nth($button-properties, 2)
) {
    //includes & requirements
    border-radius: nth($button-properties, 4);
    box-shadow: nth($button-properties, 6);
    @include fontSizeREM(nth($button-properties, 3));

    //basic style
    @extend %btn-basics;

    border: nth($button-properties, 5) #c6c6c6;
    background: $btn-bg-color;
    color: $btn-font-color;
    font-family: nth($button-properties, 1);
    padding: nth($button-properties, 9);
    text-decoration: nth($button-properties, 7);
    text-transform: nth($button-properties, 8);

    // interaction style
    &:hover {
        border: 1px solid $icon-hover-color !important;
        color: $btn-font-color;
    }

    &:visited {
        color: $btn-font-color;
    }

    &[disabled] {
        &:hover {
            background: $btn-bg-color;
        }
    }
}

// button declaration
.btn {
    border: 1px solid #c6c6c6;
    + .icon-after, + .icon-before, + .icon-only {
        min-height: 45px;
        &:before, &:after {
            font-size: 42px;
            display: inline;
            position: absolute;
            bottom: 0;
            padding: 0 5px;
        }
        &:before {
            left: 0;
        }
        &:after {
            right: 0;
        }
    }
    + .icon-before {
        padding-left: 50px;
    }
    + .icon-only {
        padding: 0 30px;
    }
    + .btn-full-width.icon-only {
        padding: 0;
        &:before {
            left: 48%;
        }
    }
    + .icon-after {
        padding-right: 50px;
    }
    @include createBtn();

    //responsive behaviour
    @include respond-max($breakpoint-max-s) {
        float: none;
        display: block;
        margin: 0 auto ($grid-gutter-width/2) auto !important;
        text-align: center;
    }
}

// button-set
.btn-set {
    text-align: center;
    margin-bottom: 10px;

    > .btn {
        @include respond-min($breakpoint-min-m) {
            float: left;
        }

        + .btn {
            margin-left: 4px;
        }

        &.center {
            display: inline-block;
            float: none;
        }

        &.right {
            float: right;
        }

        @include respond-max($breakpoint-max-s) {
            &.left {
                float: left;
            }

            &.center {
                clear: both;
                float: none;
                display: block;
            }
        }
    }

    &:after {
        @extend %clearfix;
    }

    input.submit {
        font-size: 1rem !important;
        font-weight: 300 !important;
        padding: 10px 20px !important;
        -webkit-border-radius: 0 !important;
        -moz-border-radius: 0 !important;
        -o-border-radius: 0 !important;
        -ms-border-radius: 0 !important;
        border-radius: 0 !important;

        background-color: #f3f3f3 !important;
        color: #565656 !important;
        font-family: Roboto !important;

        &:hover {
            border-color: black;
        }
    }
}
