@charset "UTF-8";

/* mod-carousel */

@each $color in $color-pattern-list {
    $index: index($color-pattern-list, $color);
    $contrast-color: nth($color-pattern-list-contrast, $index);

    .color-pattern-#{$index} .mod-carousel .flexslider li .zoom,
    .color-pattern-#{$index} .mod-carousel-variant .flexslider li .zoom {
        background: $color;
    }

    .color-pattern-contrast.color-pattern-#{$index} .mod-carousel .flexslider li .zoom,
    .color-pattern-contrast.color-pattern-#{$index} .mod-carousel-variant .flexslider li .zoom {
        background: $contrast-color;
    }
}

.mod-carousel,
.mod-carousel-variant {
    margin-bottom: $grid-gutter-width;
    background: $main-color;
    padding: $grid-gutter-width;

    .slides {
        margin: 0 -10px;
    }

    .flexslider {
        &.hidden-for-zoom {
            display: none;
        }

        .flex-direction-nav {
            .flex-nav-prev {
                a.flex-prev {
                    top: 40%;
                    left: -20px;
                }
            }

            .flex-nav-next {
                a.flex-next {
                    top: 40%;
                    right: -20px;
                }
            }
        }

        &.single-item-slider {
            .slides {
                margin: 0;
                li {
                    > div {
                        margin: 0;

                        a {
                            position: relative;

                            > span.img-info {
                                display: block;
                                padding: 5px;
                                /*
                                position: absolute;
                                 */
                                width: 100%;
                                bottom: 0;
                                /*
                                background-color: rgba(0, 0, 0, 0.75);
                                color: $white;
                                 */

                                .description {
                                    float: left;
                                    padding-right: 5px;
                                }

                                .img-count {
                                    float: right;
                                    padding-left: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }

        li {
            box-sizing: border-box;

            * {
                backface-visibility: hidden;
            }

            > a,
            > span.extern-teaser {
                margin: 0 10px;
            }

            > div {
                position: relative;
                margin: 0 10px;
                &:hover {
                    .zoom {
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }

            .row {
                margin-left: auto;
                margin-right: auto;
            }

            .col-s-6 {
                float: left;
                max-width: 48%;
                width: 100%;

                &:first-child {
                    margin: 0 4% 0 0;
                }

                .lt-ie9 & {
                    max-width: 45%;
                    margin: 0 0 0 0;
                }
            }

            .section-teaser {
                height: 200px;
                margin-bottom: 0;

                .teaser-image {
                    // RV this padding is mandatory, was removed meanwhile (on behalf of radiologie) but requested back (by UK)
                    // this is what radiologie wanted: nor borders in carousel
                    padding-right: 0;
                }

            }

            .zoom {
                @extend .icon-zoom-in;
                @include icon-background(40);
                transition: opacity .3s ease 0s;
                cursor: pointer;
                position: absolute;
                top: 10px;
                right: 10px;
                z-index: 1;
                text-decoration: none;

                &.zoom-out {
                    @extend .icon-zoom-out;
                }

                &:before {
                    display: none !important;
                }

                &:after {
                    @extend %icon-font;
                    @include fontSizeREM(42);
                    margin: -2px 0 0 0;
                    vertical-align: -30%;
                }

                @include respond-min($breakpoint-min-l) {
                    visibility: hidden;
                    opacity: 0;
                }
            }

            span.img-info {
                display: none;
            }

            p.caption {
                padding: 8px;
                margin: 0;
            }
        }
    }

    p {
        margin: 8px 0 0 0;
    }

    p.above {
        margin: 0 0 8px 0;
    }

    .mod-teaser {
        margin-bottom: 0;
        font-size: 16px;
    }

    .mod-carousel-stage {
        display: none
    }

    &.is-active {
        .flex-direction-nav {
            display: none !important;
        }

        .slides {
            transform: none !important;
            transition-duration: 0s !important;
            position: relative;
            width: 100% !important;
            margin: 0 !important;

            li {
                display: none !important;

                &.is-active {
                    display: block !important;
                    width: 100% !important;
                }
            }
        }

        > div {
            margin: 0;
        }

    }
}

.mod-carousel-logos {
    margin-bottom: $grid-gutter-width;
    .flex-viewport {
        margin: 0 38px;
    }

    .slides {
        h1,h2,h3,h4,h5 {
            display: none;
        }
    }

}

// Contrast-Styles
.color-pattern-contrast {
    .mod-carousel {
        border: 1px solid $black;
        background: $white;
    }
}

.zoom-link {
    display: block;
}

.landpro {
    .mod-carousel,
    .mod-carousel-variant,
    .mod-carousel-logos {
        background: $white;

        padding: $grid-gutter-width;
    }
}