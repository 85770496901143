@charset "UTF-8";

/* mod-video */

@each $color in $color-pattern-list {
    $index: index($color-pattern-list, $color);
    $contrast-color: nth($color-pattern-list-contrast, $index);

    .color-pattern-#{$index} {
        a.video, figure.video {
            &::before {
                color: $color !important;
            }
        }
    }

    .color-pattern-#{$index}.color-pattern-contrast {
        a.video, figure.video {
            &::before {
                color: $contrast-color !important;
            }
        }
    }
}

.mod-video {
    max-width: 640px;
    margin: $grid-gutter-width*2 auto;
    width: 80%;
    overflow: hidden;

    &:hover .vjs-big-play-button {
        background: $main-color;
        border: 4px solid $grey;
        opacity: 1 !important;
    }

    @include respond-min($breakpoint-min-m) {
        margin: $grid-gutter-width*2 0;
    }

    .if-ie8 {
        display: none;
    }
}

.video-js {
    padding-top: 55.25%;
    padding-bottom: 8%;
}

.vjs-fullscreen {
    padding-top: 0;
}

.vjs-default-skin {
    color: #928f8f;

    .vjs-play-progress,
    .vjs-volume-level {
        background-color: #7e8182
    }

    .vjs-volume-control {
        margin: 3px 25px 0 5px;
    }

    .vjs-control-bar,
    .vjs-big-play-button {
        background: $main-color
    }

    .vjs-slider {
        background: rgba(220, 214, 214, 0.3333333333333333)
    }

    .vjs-control-bar {
        font-size: 118%;
        padding: 12px;
        height: 60px;
    }

    .vjs-control {

        &:before {
            text-shadow: none !important;
            @include icon-background(35, #fff);
            border: 1px solid $icon-color;
            z-index: 1;
            color: $icon-color;
        }
    }

    .vjs-play-control,
    .vjs-mute-control,
    .vjs-fullscreen-control {
        width: 3em;
    }

    .vjs-big-play-button,
    .vjs-big-play-button:hover {
        border-radius: 50%;
        left: 54.5%;
        width: 2.6em;
        opacity: .9;
        z-index: 0;

        &::before {
            text-shadow: none;
            left: 2px;
            line-height: 2.5em;
        }
    }

    .vjs-volume-control,
    .vjs-mute-control {
        display: none;

        @include respond-min($breakpoint-min-s) {
            display: block;
        }
    }

    &.vjs-big-play-centered .vjs-big-play-button {
        left: 58%;

        @include respond-min($breakpoint-min-s) {
            left: 55%;
        }
    }
}

.video-wrapper {
    position: absolute;
    top: 0;
    left: 0;
}

// default play button is ugly, so hide it completly
.video-js .vjs-big-play-button {
    display: none;
}

.vjs-control {
    &:before {
        text-shadow: none !important;
        @include icon-background(35, #fff);
        border: 1px solid $icon-color;
        z-index: 1;
        color: $icon-color;
    }
}

a.video {
    position: relative;

    &::before {
        content: "\e901";
        font-family: 'charite-icons';
        font-size: 2em;
        line-height: 1.5em;

        position: absolute;
        left: 0.5em;
        bottom: 0.5em;
        border-radius: 50%;
        width: 1.5em;
        height: 1.5em;
        text-align: center;
        background-color:rgba(255, 255, 255, 1);
        cursor: pointer;
        -webkit-transition: all .4s;
        transition: all .4s;
        padding: 0;
        font-style: normal;
        color: #1d9a73;
    }
}

figure.video {
    position: relative;

    &::before {
        content: "\e613";
        font-family: 'charite-icons';
        z-index: 10;

        font-size: 1.5625rem;
        position: absolute;
        top: 0;
        right: 0;

        background-color:rgba(255, 255, 255, 1);
        padding-left: 5px;
        padding-right: 5px;
    }
}

figure.remove-external-icon {
    &::before {
        content: none !important;
    }
}


/* unified styles to support all browsers (down to ie 10) */
figure.video {
    display: inline-block;

    figcaption {
        display: block;
    }

    a.video {
        display: block;
        margin: 0 auto;
    }
    .video-wrapper {
        background-color: #f6f3ed;
    }
    .video-js {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.position-relative {
    position: relative;
}