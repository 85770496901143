@charset "UTF-8";

/* mod-article */

@each $color in $color-pattern-list {
    $index: index($color-pattern-list, $color);
    $contrast-color: nth($color-pattern-list-contrast, $index);

    .color-pattern-#{$index} .mod-article article:not(.mod-bloc-contact) > ul > li:before {
        background: $color;
    }

    .color-pattern-#{$index} .external-link,
    .color-pattern-#{$index} .glossary,
    .color-pattern-#{$index} .internal-link,
    .color-pattern-#{$index} .download,
    .color-pattern-#{$index} .mail,
    .color-pattern-#{$index} aside ul li a,
    .color-pattern-#{$index} .mod-article article:not(.mod-bloc-contact) ol li:before {
        color: $color !important;
    }

    .color-pattern-contrast.color-pattern-#{$index} .mod-article article:not(.mod-bloc-contact) > ul > li:before {
        background: $contrast-color;
    }

    .color-pattern-contrast.color-pattern-#{$index} .external-link,
    .color-pattern-contrast.color-pattern-#{$index} .glossary,
    .color-pattern-contrast.color-pattern-#{$index} .internal-link,
    .color-pattern-contrast.color-pattern-#{$index} .download,
    .color-pattern-contrast.color-pattern-#{$index} .mail,
    .color-pattern-contrast.color-pattern-#{$index} aside ul li a,
    .color-pattern-contrast.color-pattern-#{$index} .mod-article article:not(.mod-bloc-contact) > ol > li:before {
        color: $contrast-color !important;
    }
}

// ON EXPLICIT WISH FOR BITV - darken font for section "Die Charité
.color-pattern-6 .external-link,
.color-pattern-6 .glossary,
.color-pattern-6 .internal-link,
.color-pattern-6 .download,
.color-pattern-6 .mail,
.color-pattern-6 aside ul li a,
.color-pattern-6 .mod-article article:not(.mod-bloc-contact) ol li:before {
    color: darken(nth($color-pattern-list-contrast, 6), 15%) !important;
}

.mod-article {
    &:after {
        @extend %clearfix;
    }

    article:not(.mod-bloc-contact) {
        margin-top: $grid-gutter-width * 1.5;
        transform: translateZ(0);

        > ul > li {
            list-style: none;
            position: relative;
            margin: 10px 0;

            &:before {
                border-radius: 50%;
                content: '';
                display: inline-block;
                margin: 0 5px 0 0;
                height: 5px;
                vertical-align: middle;
                width: 5px;
                /* RV why
                position: absolute;
                top: 7px;
                left: -10px;
                */
                position: relative;
                top: -1px;
            }
        }

        ol {
            margin-top: 10px;

            li {
                counter-increment: numeric-list;
                list-style: none;
                position: relative;

                &:before {
                    content: counter(numeric-list) ".";
                    margin-right: .2em;
                    /* RV why
                    left: -15px;
                    position: absolute;
                    */
                }
            }
        }

        h3 {
            margin-bottom: 5px;
        }

        h4 span {
            font-weight: 500;
        }

        > p,
        > ul > li,
        > div.news-boilerplate {
            @include respond-min($breakpoint-min-l) {
                max-width: 73%;
            }
        }

        > h5 {
            margin-bottom: 0;
        }

        p:last-child {
            margin-bottom: $grid-gutter-width * 2;
        }
    }

    .mod-download-link-list p:last-child {
        margin-bottom: 0 !important;
    }

    article.restore-listbreaks {
        ul > li:before {
            position: absolute;
            top: 7px;
            left: -10px;
        }

        ol > li:before {
            left: -15px;
            position: absolute;
        }
    }
}

figure {
    margin: 1em 0;
    max-width: 440px;
    display: table;

    figcaption {
        background: $main-color;
        font-size: .8em;
        padding: .5em 1.5em 1em 1.5em;
        display: table-caption;
        caption-side: bottom;

        strong {
            display: block;
        }

        p {
            max-width: 100%;
        }
    }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    figure {
        img {
            max-width: 440px;
        }
    }
}

// RV this was the fallback behavior before classes below were introduced
@include respond-min($breakpoint-min-m) {
    figure {
        position: relative;
        float: right;
        margin: 0 0 $grid-gutter-width $grid-gutter-width;
        clear: right;
    }
}

.intext_justify .ce-image {
    position: relative;
    width: 100%;
    max-width: 100%;
    clear: both;
    float: none;

    figure,
    figure.video {
        display: table;
        max-width: 100%;
        margin: 0 auto;
        float:none;
        margin-bottom: $grid-gutter-width;

        img {
            max-width: 100%;
            display: block;
            margin: 0 auto;
        }
    }
}


.intext_justify_stretched figure {
    display:table;
    position: relative;
    width: 100%;
    max-width: 100%;
    clear: both;
    float: none;
    margin: 0 0 $grid-gutter-width 0;

    img {
        max-width: 100%;
        display: block;
        margin: 0 auto;
    }
}

.intext_justify_stretched img {
    max-width: 100%;
}

.intext_justify_stretched figure img {
    width: 100%;
}

.intext_right figure {
    position: relative;
    float: right;
    clear: right;
    margin: 0 0 $grid-gutter-width $grid-gutter-width;
}

.intext_left figure {
    position: relative;
    float: left;
    margin: 0 $grid-gutter-width $grid-gutter-width 0;
    clear: left;
}

/* RV allow floating images aside to use full space, even in accordions
    440px +20px 440px (remove second 20px left margin)
 */
.intext_right_nowrap:not(.accordion-item),
.intext_right_nowrap .accordion-content .inner-wrap {
    margin-left: -$grid-gutter-width;

    figure {
        position: relative;
        float: right;
        clear: none;
        margin: 0 0 $grid-gutter-width $grid-gutter-width;
    }

    > * {
        margin-left: $grid-gutter-width;
    }
}

/* RV allow floating images aside to use full space, even in accordions
    440px +20px 440px (remove second 20px right margin)
 */
.intext_left_nowrap:not(.accordion-item),
.intext_left_nowrap .accordion-content .inner-wrap {
    margin-right: -$grid-gutter-width;

    figure {
        position: relative;
        float: left;
        margin: 0 $grid-gutter-width $grid-gutter-width 0;
        clear: none;
    }

    > *:not(a) {
        margin-right: $grid-gutter-width;
    }
}

// old rtehtmlarea style
p.news-boilerplate,
div.news-boilerplate {
    @include fontSizeREM(11);
    line-height: 15px;
}

// new ckeditor style
p.news-boilerplate p,
div.news-boilerplate p {
    font-size: inherit;
    line-height: inherit;
}

@include respond-min($breakpoint-min-m) {

    .highlighted .mod-article aside {
        margin-right: $grid-gutter-width/2;
    }
}

.external-link {
    text-decoration: underline;
    margin-right: 15px;
    -webkit-hyphens: none;

    &:hover {
        text-decoration: none;
    }

    &:after {
        @extend %icon-font;
        @extend .icon-extern;
        color: inherit;
        font-size: 16px;
        text-decoration: none !important;
        vertical-align: top;
        position: absolute;
        margin-left: 2px;
        text-indent: 0;
        padding-top: 1px;
    }

    span:after {
        @extend %icon-font;
        @extend .icon-extern;
        color: inherit;
        font-size: 16px;
        text-decoration: none !important;
        vertical-align: top;
        position: absolute;
        margin-left: 2px;
        text-indent: 0;
    }
}

// RV first two are new on explicit wish of KB
.csc-default p a,
.csc-default .csc-bulletlist li a,
.internal-link,
.download,
.mail {
    text-decoration: underline;
    -webkit-hyphens: none;

    &:hover {
        text-decoration: none;
    }
}

/* Glossary-Links */
.glossary {
    background: $main-color;
    border-bottom: 1px dotted $grey;

    &:hover {
        border-bottom: 1px solid;
    }
}

.mod-stage-static,
.highlighted {
    .glossary {
        background: none;
    }
}

.color-pattern-contrast .glossary {
    border: 1px solid black;
    background: #fff;
}

// Link-Color in "CAREER" is black.

.color-pattern-1 #main .glossary,
.color-pattern-2 #main .glossary,
.color-pattern-3 #main .glossary,
.color-pattern-4 #main .glossary,
.color-pattern-5 #main .glossary,
.color-pattern-6 #main .glossary,
.color-pattern-7 #main .glossary,
.color-pattern-8 #main .glossary,
.color-pattern-9 #main .glossary {
    color: #565656 !important;
}

.landpro {
    figure figcaption {
        background: $white;
    }
}
