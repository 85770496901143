@charset "UTF-8";

/* mod-csc-customization */

@each $color in $color-pattern-list {
    $index: index($color-pattern-list, $color);
    $contrast-color: nth($color-pattern-list-contrast, $index);

    .color-pattern-#{$index} .csc-sitemap .icon-before {
        padding-left: 56px;
    }

    .color-pattern-#{$index} .csc-sitemap .icon-before:before {
        -webkit-border-radius: 22px;
        -moz-border-radius: 22px;
        -o-border-radius: 22px;
        -ms-border-radius: 22px;
        border-radius: 22px;

        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        -o-box-sizing: border-box;
        -ms-box-sizing: border-box;
        box-sizing: border-box;

        display: inline-block;
        text-align: center;
        line-height: 44px;
        height: 44px;
        width: 44px;
        left: 0;
        margin-right: 10px;
        vertical-align: -30%;

        font-size: 1.875rem;
        position: absolute;
        top: 5px;

        background: $color;
        color: $white;
    }

    .color-pattern-#{$index}.color-pattern-contrast .csc-sitemap .icon-before:before {
        background: $contrast-color;
        color: $white;
    }

}

/*** forms ***/
.csc-mailform-check,
.csc-mailform-radio {
    margin: 3px 5px 0 2px;
    display: block;
    float: left;
    vertical-align: baseline;
}

/* sitemap */
.csc-sitemap {
    margin-top: $grid-gutter-width;

    ul {
        list-style: none;
        margin: 0 0 10px 0;
        padding: 0;
    }

    > ul > li {
        margin-bottom: $grid-gutter-width;
    }

    li {
        position: relative;
    }

    li a {
        display: block;
        font-size: 32px;
        font-size: 2rem;
        line-height: 110%;
        border-bottom: 1px solid #ccc;
        padding: 10px 0 10px 0;
    }

    li li a {
        font-size: 24px;
        font-size: 1.5rem;
        border-bottom: none;
        padding: 10px 0 10px 0;
    }

    li li li a {
        font-size: 16px;
        font-size: 1rem;
        padding: 5px 0 5px 16px;
    }

    li li li li a {
        font-size: 16px;
        font-size: 1rem;
        padding: 5px 0 5px 32px;
    }

    li li li li li a {
        font-size: 14px;
        font-size: .875rem;
        padding: 4px 0 4px 48px;
    }

    li li li li li li a {
        padding: 3px 0 3px 64px;
    }

    li li li li li li li a {
        padding: 2px 0 2px 80px;
    }

    a {
        display: block;
        &:hover {
            background: #f3f3f3;
        }
    }

    .icon-before:before {
        vertical-align: -18%;
    }
}

/** debug **/
.csc-textpicHeader {
    border: 2px solid red;
}
