@charset "UTF-8";

/* webfonts */

/*
 *    google fonts Roboto
 *    placeholder selector: %webfont-light, %webfont-medium
 *    Light 300, Medium 500
 */

$webfont-light: 300;
$webfont-medium: 500;

/* font-elements */

@each $color in $color-pattern-list {
    $index: index($color-pattern-list, $color);
    $contrast-color: nth($color-pattern-list-contrast, $index);

    .color-pattern-#{$index} h2:after,
    .color-pattern-#{$index} h2:before {
        border: 1px solid $color !important;
        background: $color;
        color: $white;
    }

    .color-pattern-contrast.color-pattern-#{$index} {
        h2:after,
        h2:before {
            background: $contrast-color;
            border: 1px solid $contrast-color !important;
        }
    }

    .color-pattern-#{$index} {
        ul.csc-bulletlist > li:before,
        ol.csc-bulletlist > li:before {
            color: $color;
        }
    }

    .landpro {
        .color-pattern-#{$index} h2:after,
        .color-pattern-#{$index} h2:before {
            color: $white;
            background: $color;
        }

        .color-pattern-contrast.color-pattern-#{$index} h2:after,
        .color-pattern-contrast.color-pattern-#{$index} h2:before {
            background: $contrast-color;
        }
    }
}

body {
    font-family: 'Roboto', sans-serif;
    font-weight: $webfont-light;
}

@media screen and (max-width: $breakpoint-max-s) {
    h1, h2, h3, h4, h5, h6 {
        -ms-word-break: break-all;
    }
}

h1, h2, h3, h4, h5, h6,
p {
    margin-top: 0;
    font-weight: inherit;
    margin-bottom: 16px;
    @include respond-max($breakpoint-max-xs) {
        @include prefix(hyphens, 'auto');
    }
    backface-visibility: hidden;
}

b, strong {
    font-weight: $webfont-medium;
}

h1 {
    @include fontSizeREM(30);
    max-width: 850px;
}

h2 {
    @include fontSizeREM(24);
    max-width: 850px;
    margin: 20px 0 20px 0;

    &[class*="icon-"] {
        position: relative;
        margin-right: 0;

        &:before,
        &:after {
            @include fontSizeREM(30);
            position: absolute;
            top: 50%;
            margin-top: -23px;
        }
    }

    &[class*="icon-before"] {
        padding-left: 56px;
        // RV clearing is required or else icon might be somewhere else
        // RV nope, ruins float beside table of content
        // clear: both;

        &:before {
            @extend %icon-font;
            @include icon-circle(44);
            left: 0;
            margin-right: $grid-gutter-width/2;
            vertical-align: -30%;
        }

        &:after {
            display: none !important;
        }

        &.icon-organisation:before {
            line-height: 40px;
        }

        &.icon-mail:before {
            @include fontSizeREM(40);
        }
    }

    &[class*="icon-after"] {

        &:before {
            display: none !important;
        }

        &:after {
            @extend %icon-font;
            @include icon-circle(44);
            margin-left: $grid-gutter-width/2;
            vertical-align: -30%;
            right: -40px;
        }
    }
}

.h3 {
    @extend h2;
    margin-top: 30px;
}

.mod-teaser.icon-organisation:before {
    line-height: 72px !important;
}

.headline-container,
section > header {
    margin-bottom: $grid-gutter-width;

    &:before {
        @extend %icon-font;
        margin-right: $grid-gutter-width/2;
        vertical-align: -30%;
    }

    &:after {
        display: none !important;
        @extend %clearfix;
    }

    h2, a {
        display: inline-block;
        vertical-align: middle;
    }

    h2 {
        width: 79%;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    a {
        @include fontSizeREM(18);

        width: 19%;
        text-align: right;

        &:hover {
            text-decoration: underline;
        }
    }
}

h3 {
    @include fontSizeREM(16);
    font-weight: $webfont-medium;
    max-width: 850px;
}

h4 {
    @include fontSizeREM(16);
    font-weight: $webfont-light;
    max-width: 850px;
}

h5 {
    @include fontSizeREM(14);
    font-weight: $webfont-medium;
    max-width: 850px;
}

h6 {
    @include fontSizeREM(14);
    max-width: 850px;
}

p {
    @include fontSizeREM(14);
}

a {
    word-wrap: break-word;
    text-decoration: none;

    > span {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

label {
    font-weight: $webfont-medium;

    input[type="checkbox"] + span,
    input[type="radio"] + span {
        font-weight: $webfont-light;
    }
}

.powermail_field .checkbox label,
.powermail_field .radio label {
    font-weight: $webfont-light;
}

.copyright {
    @include fontSizeREM(12);
}

.landingpage {
    h2,
    h3 {
        margin: 30px 0;
    }

    .mod-stage-static h2 {
        @include respond-min($breakpoint-min-l) {
            font-size: 2rem;
        }
    }
}