@charset "UTF-8";

/* font */

/* RV 170818
    imported google fonts, see https://google-webfonts-helper.herokuapp.com
    Brandung delivered Roboto 300,500 so we disable all other font weights
    even if they are desired in the css desired are 100,300,400,500,600,700
*/

/* roboto-300 - vietnamese_greek_greek-ext_cyrillic_latin_cyrillic-ext_latin-ext */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('../font/roboto/roboto-300.eot');
    src: local('Roboto Light'), local('Roboto-Light'),
    url('../font/roboto/roboto-300.eot?#iefix') format('embedded-opentype'),
    url('../font/roboto/roboto-300.woff2') format('woff2'),
    url('../font/roboto/roboto-300.woff') format('woff'),
    url('../font/roboto/roboto-300.ttf') format('truetype'),
    url('../font/roboto/roboto-300.svg#Roboto') format('svg');
}

/* roboto-300italic - vietnamese_greek_greek-ext_cyrillic_latin_cyrillic-ext_latin-ext */
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 300;
    src: url('../font/roboto/roboto-300italic.eot');
    src: local('Roboto Light Italic'), local('Roboto-LightItalic'),
    url('../font/roboto/roboto-300italic.eot?#iefix') format('embedded-opentype'),
    url('../font/roboto/roboto-300italic.woff2') format('woff2'),
    url('../font/roboto/roboto-300italic.woff') format('woff'),
    url('../font/roboto/roboto-300italic.ttf') format('truetype'),
    url('../font/roboto/roboto-300italic.svg#Roboto') format('svg');
}

/* roboto-500 - vietnamese_greek_greek-ext_cyrillic_latin_cyrillic-ext_latin-ext */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('../font/roboto/roboto-500.eot');
    src: local('Roboto Medium'), local('Roboto-Medium'),
    url('../font/roboto/roboto-500.eot?#iefix') format('embedded-opentype'),
    url('../font/roboto/roboto-500.woff2') format('woff2'),
    url('../font/roboto/roboto-500.woff') format('woff'),
    url('../font/roboto/roboto-500.ttf') format('truetype'),
    url('../font/roboto/roboto-500.svg#Roboto') format('svg');
}

/* roboto-500italic - vietnamese_greek_greek-ext_cyrillic_latin_cyrillic-ext_latin-ext */
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 500;
    src: url('../font/roboto/roboto-500italic.eot');
    src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
    url('../font/roboto/roboto-500italic.eot?#iefix') format('embedded-opentype'),
    url('../font/roboto/roboto-500italic.woff2') format('woff2'),
    url('../font/roboto/roboto-500italic.woff') format('woff'),
    url('../font/roboto/roboto-500italic.ttf') format('truetype'),
    url('../font/roboto/roboto-500italic.svg#Roboto') format('svg');
}
