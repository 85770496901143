@charset "UTF-8";

/* mod-accordion */

$accordion-transition-duration: 250ms;

@each $color in $color-pattern-list {
    $index: index($color-pattern-list, $color);
    $contrast-color: nth($color-pattern-list-contrast, $index);

    .color-pattern-#{$index} .accordion-item > .content-wrap {
        > h1, > h2, > h3, > h4, > h5, > h6 {
            &::after {
                background-color: $color;
                color: $white;
            }
        }
    }

    .color-pattern-contrast.color-pattern-#{$index} .accordion-item > .content-wrap {
        > h1, > h2, > h3, > h4, > h5, > h6 {
            &::after {
                background-color: $contrast-color;
            }
        }
    }
}

.accordion-item {

    > .content-wrap {

        /* first: hide all elements in the content-wrap as long as they are not wrapped with accordion-content */
        > *:not(h1):not(h2):not(h3):not(h4):not(h5):not(h6):not(.accordion-content) {
            display: none;
        }

        /* show only the headlines... */
        > h1, > h2, > h3, > h4, > h5, > h6 {
            position: relative;
            padding: 16px 55px 18px 10px !important;
            @include respond-min($breakpoint-min-s) {
                padding: 16px 55px 18px 20px !important;
            }
            cursor: pointer;
            margin: 4px 0 0 0;
            max-width: 100%;
            font-weight: 500;
            font-size: 1rem;
            background: $main-color;

            &::after {
                @extend %icon-font;
                content: "\e610";
                font-size: 25px;
                position: absolute;
                right: 15px;
                top: 50%;
                margin-top: -18px;
                border: none !important;
                @include icon-circle(36);

                transition: transform ease #{$accordion-transition-duration};

                backface-visibility: hidden;

                transform: translate3d(0, 0, 0);
            }

            &.close {
                &::after {
                    transform: rotate(180deg);
                }
            }
        }

        /*  all elements after the headline will be wrapped with the accordion-content and inner-wrap via js */
        > .accordion-content {
            height: 0;
            margin-bottom: 0;
            overflow: hidden;

            transition: all ease #{$accordion-transition-duration};

            backface-visibility: hidden;

            transform: translate3d(0, 0, 0);

            > .inner-wrap {
                overflow: hidden;
            }
        }
    }

    .inner-wrap {
        background: #E4E3DE;
        padding: $grid-gutter-width/2;
        @include respond-min($breakpoint-min-s) {
            padding: $grid-gutter-width;
        }
    }

    /* if the accordion-item is open, set the accordion-content height to auto */
    &.open {
        > .content-wrap {
            > .accordion-content {
                height: auto;
            }
        }
    }

    // RV restore behavior falsely overwritten by other rules
    .mod-download-link-list a {
        width: 100%;

        p:not(.doctype) {
            max-width: 80%;
        }

        @include respond-max($breakpoint-max-s) {
            p:not(.doctype) {
                max-width: 66%;
            }
        }
    }
}

.landpro {

    .accordion-item > .content-wrap {
        > h1, > h2, > h3, > h4, > h5, > h6 {
            background: $white;
        }
    }

    .accordion-item .box {
        background: #E4E3DE;
    }
}