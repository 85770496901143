@charset "UTF-8";

/* mod-search-events */

.mod-search-events {

    fieldset {
        margin-bottom: 0;
    }

    .mod-form ul {
        margin: 0;
        padding: 0;
        list-style: none;

        input {
            background: $white !important;
            padding: 1.5em 3.5em 1.5em 0.7em;
        }

        ::-webkit-input-placeholder {
            font-style: italic;
            @include fontSizeREM(16);
        }
        ::-moz-placeholder {
            font-style: italic;
            @include fontSizeREM(16);
        }
        /* firefox 19+ */
        :-ms-input-placeholder {
            font-style: italic;
            @include fontSizeREM(16);
        }
        /* ie */
        input:-moz-placeholder {
            font-style: italic;
            @include fontSizeREM(16);
        }

        .fields {
            @include respond-min($breakpoint-min-m) {
                .field:last-child {
                    margin-left: 0;
                }
            }

            @include respond-min($breakpoint-min-l) {
                &:first-child .field:last-child {
                    margin-left: 2%;
                }

                &:first-child .field:only-child {
                    margin-left: 0;
                }
            }
        }
    }

    @include respond-max($breakpoint-max-xs) {
        .btn-set {
            .right {
                float: left;
            }

            .left {
                margin: 25px $grid-gutter-width $grid-gutter-width 0;
            }
        }
    }

    @include respond-max($breakpoint-max-m) {
        .mod-form .form-list .fields .field.size-one-third {
            width: 49%;
        }
    }

    .list-events a {
        width: 100%;
    }
}

/* List event teaser */
.list-events {
    width: 100%;
    padding: 0;

    &:after {
        @extend %clearfix;
    }

    li {
        background: $main-color;
        margin: 1em 0 0 0;
        float: left;
        position: relative;
        vertical-align: top;
        display: block;
        width: 100%;

        .event-description {
            padding: 1em;
            width: 100%;

            @include respond-min($breakpoint-min-m) {
                width: 66%;
                float: left;
            }
        }
        a {
            float: left;
        }

        img {
            display: none;
            /* stretches images in press/events list
            max-height: 200px;
            */
        }

        h5 {
            float: left;
            margin-right: 10px;
        }

        h3 {
            text-decoration: underline;
            clear: left;

            &:before {
                text-decoration: none;
                display: inline-block;
            }
        }

        p, h5, h3 {
            margin-bottom: 10px;
        }

        p:last-child {
            margin-bottom: 0;
        }

        strong {
            color: $black;
        }

        @include respond-min($breakpoint-min-m) {

            img {
                /* RV no more fixed with for list images
                width: 26%;
                */
                max-width: 200px;
                margin-right: $grid-gutter-width*.5;
                padding: 0;
                float: right;
                display: inline-block;
                position: relative;
            }
        }

        @include respond-min($breakpoint-min-l) {
            /* RV no more fixed with for list images
            img {
                width: 25%;
            }
            */

            .event-description {
                width: 66%;
            }
        }
    }
}

// Contrast-Styles
.color-pattern-contrast {
    .list-events li:hover {
        outline: 1px solid $black;
    }
}

/* list-facility */
.list-facilities {
    width: 100%;
    padding: 0;

    li {
        background: #f6f3ed;
        margin: 1em 0 0 0;
        padding: 1em;
        list-style-type: none;
    }

    h3 {
        margin-bottom: 10px;
        a {
            text-decoration: underline;
        }
    }

    p {
        margin-top: 15px;
        a {
            text-decoration: underline;
            &:hover, &:focus {
                text-decoration: none;
            }
        }
    }

}

/* Firefox: or else large the characters get cut off,
the !important was already in the original */
.wrapper .mod-search-events .mod-form ul input,
.wrapper .mod-search-jobs ul input {
    padding-top: 1.2em !important;
    padding-bottom: 1.2em !important;
}

@include respond-min($breakpoint-min-l) {
    #main .mod-search-events .mod-form ul .fields:first-child .single-filter {
        margin-left: 0;
    }
}

.project {
    .list-events li {
        background: $white;
    }
}

a.getFalImageLink {
    display:inline !important;
    width:auto !important;
    float: none !important;
    padding: 0 !important;
    margin: 0 !important;
    border: none !important;
    text-decoration: none !important;
}
