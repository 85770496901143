@charset "UTF-8";

/* header */

.wrapper > header,
.wrapper > nav {
    z-index: 13;
    background: #fff;
    min-height: 60px;
    position: fixed;

    /* phone */
    @include respond-min($breakpoint-min-xs) {

        width: 100%;
        border-bottom: solid 1px $border-color;
    }

    /* tablet */
    @include respond-min($breakpoint-min-m) {
        border-bottom: none;
        min-height: 66px;
    }

    /* desktop */
    @include respond-min($breakpoint-min-l) {
        left: 0;
        top: 0;
        position: absolute;
        padding-top: ($grid-gutter-width * .5);
        right: auto;
        bottom: 0;
        width: calcPxToEm(240);
        max-width: calcPxToEm(240);
        border-right: solid 1px $border-color;

        &.is-fixed {
            position: fixed;
        }
    }

    &:after {
        @extend %clearfix;
    }
}

/* logo */
.logo, .logo_vivantes {
    @include svgBG('logo');
    background-size: 100%;
    text-indent: -999em;
    height: 66px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 140px;
    margin-top: 10px;

    a, span {
        display: block;
        height: 65px;
        padding: 5px;
    }

    /* tablet */
    @include respond-min($breakpoint-min-m) {
        max-width: 160px;
        margin-left: 50%;
        position: relative;
        left: -120px;
    }

    /* desktop */
    @include respond-min($breakpoint-min-l) {
        margin-bottom: 30px;
        max-width: 180px;
        margin-left: auto;
        left: auto;
    }
}

.logo_vivantes {
    @include svgBG('logo_vivantes');
    background-size: 98%;
}

/** logo fallback ***/
.no-svg .logo {
    background-image: url(../img/png/logo.png) !important;
    height: 68px !important;
}

@each $color in $color-pattern-list {
    $index: index($color-pattern-list, $color);
    $contrast-color: nth($color-pattern-list-contrast, $index);

    .color-pattern-#{$index} .sitename {
        background: $color;
    }

    .color-pattern-contrast.color-pattern-#{$index} .sitename {
        background: $contrast-color;
    }
}

.microsite {
    .sitename {
        height: 61px;
        top: 77px;
        position: relative;
        max-width: (940 / $body-font-size-px) + em;
        z-index: 4;

        p {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            font-size: .9rem;
            line-height: 1.1;
            padding: $grid-gutter-width/2;
            margin: 0;
            color: $white;
        }

        a {
            color: $white;
        }

        @include respond-min($breakpoint-min-s) {

            p {
                font-size: 1.2rem;
                padding: 0 $grid-gutter-width;
                /* RV bold is not good readable
                font-weight: 600;
                */
            }
        }

        @include respond-min($breakpoint-min-l) {
            height: 111px;
            top: 0;

            p {
                max-width: 64%;
                line-height: 1.4;
            }
        }
    }

    /* move a little bit lower with two logos */
    .sitename_vivantes {
        top: 150px;

        @include respond-min($breakpoint-min-l) {
            top: 0;
        }
    }
}

.landingpage {
    @include respond-min($breakpoint-min-l) {
        .wrapper > header.is-fixed {
            position: absolute;
        }
    }

    header {
        bottom: auto;
        nav {
            display: none;
        }
    }

    .sitename {
        max-width: none !important;
    }

    @include respond-min($breakpoint-min-l) {

        .wrapper {
            padding-left: 0;
        }

        .sitename {
            left: 240px;

            p {
                font-size: 1.5rem;
                line-height: 1.6;

                // RV new rule, should be applied above for everyone
                max-width: 100%;
                margin-right: 20em;
            }
        }

        .logo {
            margin-bottom: 26px;
        }
    }
}