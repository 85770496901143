@charset "UTF-8";

/* print */

@media print {

    * {
        float: none !important;
        height: auto !important;
    }

    .wrapper {
        width: 100% !important;
        padding: 20px !important;
    }

    .print-only {
        text-align: center !important;
        padding-top: 5px !important;
        border-top: solid 1px black !important;
    }

    // display
    .max-width,
    .wrapper > header > nav,
    .nav-toggle,
    .section-teaser,
    .moreLink,
    .logo,
    .flex-control-nav,
    .mod-simple-flexslider,
    .mod-slider-full-width,
    footer .logo,
    .mod-breadcrumbs a:after,
    .mod-map,
    .mod-finder,
    .mod-lexicon,
    a:after,
    footer .footer-content,
    .mod-carousel,
    .mod-carousel-variant,
    .mod-google-search .gsc-search-box,
    .mod-google-search .gsc-above-wrapper-area,
    .mod-google-search .gsc-tabsArea,
    .mod-video,
    .btn,
    .mod-goto-article,
    .browsebox,
    /*
    .mod-link,
    */
    .mod-stage-static .mod-teaser[class*="from-"],
    .mod-stage-static .mod-teaser[class*="icon-"],
    .highlighted .mod-teaser[class*="from-"],
    .highlighted .mod-teaser[class*="icon-"],
    #TSFE_ADMIN_PANEL_FORM,
    #TSFE_ADMIN,
    .mod-download-link-list,
    .btn-set,
    .btn-wrap,
    .mod-table-of-content,
    .hide-in-print,
    .hide-in-print *,
    .hideInPrint {
        display: none !important;
    }

    .mod-search-topics .section-teaser,
    .print-only,
    .showInPrint,
    .lt-ie9 .print-ie-only,
    .finder-result .mod-finder {
        display: block !important;
    }

    .section-teaser {
        max-height: 600px;
    }

    .section-teaser .teaser-content,
    .section-teaser .teaser-image {
        width: 50%;
        max-height: 200px;
        display: block;
    }

    .mod-finder .tx-frontendlist-pi-searchbox-wrap input {
        padding: .5em 4em .5em 1em !important;
    }

    // header
    header {
        position: static !important;
        height: 67px !important;
        top: auto !important;

        &:before {
            position: static !important;
            content: url(../img/png/logo.png) !important;
            height: 67px !important;
            width: 180px !important;
            display: block !important;
        }
    }

    // width
    [class*="col-"],
    .container,
    .headline-container h2,
    section > header h2 {
        width: 100% !important;

    }
    .container {
        max-width: none !important;
    }

    // height {
    .mod-teaser,
    .default-teaser,
    .mod-teaser-info,
    .extern-teaser,
    .emergency-teaser,
    .location-teaser {
        height: auto !important;
        min-height: inherit !important;
    }

    .mod-teaser span:first-child,
    .mod-teaser strong:first-child,
    .mod-finder .mod-link div,
    .mod-link div,
    .mod-map ul li a div,
    .news-teaser-big,
    .mod-teaser-list h3 {
        min-height: inherit !important;
        line-height: inherit !important;
    }

    .mod-teaser-info span:first-child,
    .mod-teaser-info strong:first-child {
        min-height: inherit !important;
    }

    // text-align
    .mod-teaser,
    .mod-teaser-info,
    .extern-teaser,
    .mod-finder,
    .mod-link div,
    .mod-map ul li a div,
    .default-teaser {
        text-align: left !important;
    }

    // margin
    .mod-stage-static .stage-content,
    .mod-stage-static .mod-teaser {
        margin-top: 0 !important;
    }
    .logo,
    .news-teaser-big .date,
    .news-teaser-middle .date,
    .news-teaser-small .date,
    .news-teaser-big h5,
    .news-teaser-middle h5,
    .news-teaser-small h5 {
        margin-bottom: 0 !important;
    }

    .highlighted h1,
    .highlighted h2,
    .highlighted p,
    .highlighted .row {
        margin-left: 0 !important;
    }

    .mod-teaser span:first-child,
    .default-teaser span:first-child,
    .mod-teaser-info span:first-child,
    .extern-teaser span:first-child,
    .emergency-teaser span:first-child,
    .location-teaser span:first-child,
    .mod-teaser strong:first-child,
    .default-teaser strong:first-child,
    .mod-teaser-info strong:first-child,
    .extern-teaser strong:first-child,
    .emergency-teaser strong:first-child,
    .location-teaser strong:first-child {
        margin-bottom: 0 !important;
    }

    .extern-teaser {
        margin-left: 10px !important;
    }

    // padding
    .news-teaser-big .teaser-content,
    .news-teaser-middle .teaser-content,
    .news-teaser-small .teaser-content,
        /* those with a background-color need padding
        .mod-teaser,
        */
    .default-teaser,
        /* ruins teaser boxes e,g, job detail
        .mod-teaser-info,
        .mod-info-teaser .teaser-content,
        */
    .extern-teaser,
    .emergency-teaser,
    .location-teaser,
    .location-teaser p,
    .col-s-6.col-l-6.col-xl-3 /*teaser*/
    ,
    .mod-vcard .teaser-content,
    .mod-breadcrumbs,
    .mod-stage-static,
    .mod-stage-static .col-s-8,
    .mod-stage-static .col-s-6.col-m-8.col-xl-9 /*static-stage-content*/
    ,
    .mod-stage-static .col-m-8.col-xl-9 /*static-stage-content*/
    ,
    .mod-stage-static.stage-fullheight-image .col-m-6:last-child,
    .highlighted .row .col-s-6.col-m-8.col-xl-9,
    .highlighted .col-s-6.col-m-4.col-xl-3,
    .highlighted .col-s-6.col-l-6.col-xl-3,
    .highlighted .col-s-6.col-l-6.col-xl-3 .mod-teaser,
    .highlighted .col-m-6,
    .highlighted .mod-teaser,
    .highlighted.mod-info,
    .mod-stage-static.stage-fullsize-image .slide-content,
    .highlighted.mod-info,
    .mod-bloc-contact.vcard .person,
    .mod-download-link-list a,
    .list-events li .event-description,
    .mod-bloc-contact > div,
    .mod-bloc-contact > div div,
    .list-topics .section-teaser .teaser-content,
    .mod-stage-static.stage-fullsize-image .slide-content {
        padding-left: 0 !important;
    }

    // scroll-table
    .scroll-table {
        overflow: inherit !important;
        max-height: 100% !important;
    }

    // position
    .mod-stage-static.stage-fullsize-image .slide-content {
        position: static !important;
    }

    // page break
    .mod-vcard,
    .mod-table table,
    footer {
        page-break-inside: avoid !important;
    }

    .container {
        padding: 0 !important;
    }

    // table
    .mod-table table {
        width: 90% !important;
    }
    .mod-table table thead th:first-child,
    .mod-table table tbody tr td:first-child {
        padding-left: 0 !important;
    }

    // list-jobs
    .list-jobs .meta-info {
        padding-left: 0 !important;
        font-size: 0 !important;
        span {
            font-size: 14px !important;
        }
    }

    // google search
    .mod-google-search .gsc-results .gsc-cursor-box .gsc-cursor-current-page {
        font-weight: 400 !important;
        margin-right: 0 !important;
    }

    // breadcrumb
    .mod-breadcrumbs {
        @include fontSizeREM(12);
    }

    a.glossary,
    acronym,
    abbr,
    dfn {
        border-bottom: none !important;
        text-decoration: none !important;
        background-color: transparent !important;
    }

    body {
        font-family: Verdana, "Helvetica Neue", Helvetica, Arial, sans-serif;
    }

    .page-wrapper {
        margin: 0 30px;
        position: relative;
    }

    .microsite .sitename {
        text-align: center;
        max-width: none;
        top: 100px;

        p {
            line-height: 1.3;
        }
    }

    header {
        height: 100px !important;
        position: absolute !important;
        top: 15px !important;
        width: 100%;

        &::before {
            position: relative !important;
            margin: 0 auto;
        }
    }

    .mod-breadcrumbs {
        margin-bottom: 20px;
        position: absolute;
        top: 130px;
        left: 0;
    }

    main {
        position: relative;
        padding-top: 140px;
    }

    footer {
        max-width: none;
    }

    // stage
    .mod-stage-static {
        &.stage-fullheight-image {
            padding-top: 30px;
        }

        .stage-content {
            padding-left: 0 !important;
        }

    }

    //////////////// content

    // remove padding and margins
    .row {
        margin-left: 0;
    }
    .col-xs-1, .col-s-1, .col-m-1, .col-l-1, .col-xl-1, .col-xs-2, .col-s-2, .col-m-2, .col-l-2, .col-xl-2, .col-xs-3, .col-s-3, .col-m-3, .col-l-3, .col-xl-3, .col-xs-4, .col-s-4, .col-m-4, .col-l-4, .col-xl-4, .col-xs-5, .col-s-5, .col-m-5, .col-l-5, .col-xl-5, .col-xs-6, .col-s-6, .col-m-6, .col-l-6, .col-xl-6, .col-xs-7, .col-s-7, .col-m-7, .col-l-7, .col-xl-7, .col-xs-8, .col-s-8, .col-m-8, .col-l-8, .col-xl-8, .col-xs-9, .col-s-9, .col-m-9, .col-l-9, .col-xl-9, .col-xs-10, .col-s-10, .col-m-10, .col-l-10, .col-xl-10, .col-xs-11, .col-s-11, .col-m-11, .col-l-11, .col-xl-11, .col-xs-12, .col-s-12, .col-m-12, .col-l-12, .col-xl-12,
    .mod-teaser-list h3,
    .mod-teaser-list article div {
        padding-left: 0;
        padding-right: 0;
    }
    // no images
    figure,
    img {
        display: none;
    }

    // display link target after link
    /* RV link target display in print view was disabled so this is not needed - messes up .mod-bloc-contact>div:after in print
    main a {
        &:after {
            // this is set per inline style!!! because of the base href
            // content: "(" $domain attr(href) ")" !important;
            color: $black;

            display: block !important;
            text-decoration: underline;

            // make it visible for all constellations
            font-size: inherit !important;
            line-height: 1.2 !important;
            height: auto !important;
        }
    }
    */

    h2 a:after {
        font-size: .875rem !important;
    }

    .highlighted {
        padding: 0;
        max-width: none;
        > div {
            margin: 0;
        }
    }

    // overwrite helpers - background
    .from-clinic-bg,
    .from-research-bg,
    .from-study-bg,
    .from-career-bg,
    .from-international-bg,
    .from-charite-bg,
    .from-emergency-bg,
    .from-contact-bg,
    .from-service-bg,
    .from-bih-bg {
        background: none !important;
    }
    .from-clinic-text,
    .from-research-text,
    .from-study-text,
    .from-career-text,
    .from-international-text,
    .from-charite-text,
    .from-contact-bg,
    .from-service-text,
    .from-bih-text {
        color: $black !important;
    }

    // icons
    p.phone,
    p.fax,
    p.email,
    p.location,
    p.route {
        &:before {
            color: $black;
            background: $white !important;
            border: 1px solid $black;
        }
    }

    // icon color
    @each $color in $color-pattern-list {
        $index: index($color-pattern-list, $color);
        $contrast-color: nth($color-pattern-list-contrast, $index);

        // icons before h2
        .color-pattern-#{$index} h2:after,
        .color-pattern-#{$index} h2:before {
            border: 1px solid $black !important;
            background: $white;
            color: $black;
        }

        .color-pattern-#{$index} .external-link,
        .color-pattern-#{$index} .glossary,
        .color-pattern-#{$index} .internal-link,
        .color-pattern-#{$index} .download,
        .color-pattern-#{$index} .mail,
        .color-pattern-#{$index} aside ul li a,
        .color-pattern-#{$index} .mod-article article:not(.mod-bloc-contact) ol li:before {
            color: $black !important;
            /*
            text-decoration: none;
            */
        }

        .color-pattern-#{$index} .mod-bloc-contact {
            .tel,
            .fax,
            .mail,
            .route,
            .street-address,
            .street-address > p {
                &:before {
                    border: 1px solid $black !important;
                    background: $white;
                    color: $black;
                }
            }
        }

        .color-pattern-#{$index} .mod-stage-static {
            &.stage-persona {

                li:before {
                    @include icon-background(26, $white);
                    color: $black;
                    border: 1px solid $black !important;
                }

                .street-address:before {
                    @include icon-background(26, $white);
                    color: $black;
                    border: 1px solid $black !important;
                }
            }
        }

        .color-pattern-#{$index} .mod-search-jobs,
        .color-pattern-#{$index} .mod-search-events,
        .color-pattern-#{$index} .mod-search-topics,
        .color-pattern-#{$index} .mod-goto-article {
            .wide button:after {
                background: $white;
                color: $color;
            }
            .wide button:hover:after {
                background: $white;
                color: $color;
            }

            .field button:after,
            .field span:after {
                background: $white !important;
                color: $black !important;
            }
        }

        .landpro {
            .color-pattern-#{$index} h2:after,
            .color-pattern-#{$index} h2:before {
                color: $black;
                background: $white;
            }
        }
    }

    // tiles
    a.mod-teaser {
        font-size: .875rem;
        padding: 0;

        &:before {
            display: none !important;
        }
    }
    a.mod-teaser-info {
        font-size: .875rem;
        border-bottom: none;
        padding: 0;
    }
    a.location-teaser {
        p {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
    a.news-teaser-big {
        .teaser-content {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
    a.mod-link {
        font-size: .875rem;
        padding: 0;
    }
    a.extern-teaser {
        font-size: .875rem;
        margin-left: 0 !important;
        padding: 0;

        &:before {
            display: none;
        }
    }

    // lists
    .mod-form .form-list .fields .field.size-one-third {
        display: inline-block;
        margin-right: 1%;
    }

    .list-jobs {
        strong {
            margin-right: 10px;

        }
    }

    // glossary
    .accordion-item {
        > .content-wrap {
            > h1, > h2, > h3, > h4, > h5, > h6 {
                font-size: 1.5rem;
                padding-bottom: 0 !important;
                padding-left: 0 !important;
                &::after {
                    display: none;
                }
            }
        }
    }

    // surgery
    .mod-surgery {
        padding: 0;
    }

    // forms
    .mod-form {
        fieldset {
            border-bottom-color: $black;
        }
        .form-list .fields {
            .field-wrapper,
            .field {
                margin-left: 0;
                background: green;
            }
        }
    }

    // contact
    .mod-bloc-contact.big-vcard > div .person {
        width: auto;
    }

    // accordion
    .accordion-item .inner-wrap {
        padding: 0 0 0 2px;
    }

    // partnerlogos
    .mod-carousel-logos {
        &:after {
            content: "Die Logos wurden für die Druckversion ausgeblendet.";
        }
    }

    // mod-bloc-contact
    .mod-bloc-contact {
        margin-bottom: 0;

        &.small-vcard > div .person,
        &.small-vcard > div .person strong,
        &.big-vcard > div .person strong,
        &.vcard .person strong {
            min-height: auto;
            margin: 0 0 10px 0;
        }
    }

    // downloads
    .mod-download-link-list {
        a:after {
            font-size: .875rem !important;
        }
        .doctype {
            display: none;
        }
    }

    // flexslider
    .flex-direction-nav {
        display: none;
    }

    // bulletlists
    ol.csc-bulletlist {
        list-style-type: decimal;
        margin-left: 10px;
        > li {
            padding: 0 0 0 20px;
            &:before {
                display: none;
            }
        }
    }
    ul.csc-bulletlist {
        list-style-type: circle;
        > li {
            &:before {
                display: none;
            }
        }
    }

    a.video {
        &:before {
            display: none;
        }
    }

    .project .homepage .highlighted {
        margin: 0;
    }

    .landpro {
        main {
            padding-top: 100px;
        }

        .box {
            padding: 0;
        }

        .mod-carousel,
        .mod-carousel-variant,
        .mod-carousel-logos {
            padding: 0;
        }
    }

    .landingpage {
        .mod-stage-static.stage-only-image .header {
            padding: 0;
        }
    }

    // EU cookie consent
    /*
    .cc_banner-wrapper,
    .cc_banner-wrapper * {
        display: none !important;
    }
     */
}
