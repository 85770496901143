@charset "UTF-8";

/* navigation */

@each $color in $color-pattern-list {

    $index: index($color-pattern-list, $color);
    $contrast-color: nth($color-pattern-list-contrast, $index);

    .color-pattern-contrast.color-pattern-#{$index} #navigationmark {
        li div:first-child.active {
            color: $contrast-color;
        }

        .level-1 div:after,
        .color-pattern-7 div:after {
            //background: rgba($contrast-color, .25) !important;
            background: rgba($contrast-color, .55) !important;
        }

        li.level-1 > .active-level {
            a,
            &:before {
                color: $contrast-color;
            }
        }

        // analog level-3
        .level-back div {
            background: $contrast-color !important;
            &:after {
                background: darken($contrast-color, 10%) !important;
            }
        }

        .flyout {

            div.level-2 {
                background: rgba($contrast-color, .55) !important;
                &:after {
                    background: $contrast-color !important;
                }
            }

            div.level-3 {
                background: $contrast-color !important;
                &:after {
                    background: darken($contrast-color, 10%) !important;
                }
            }

            div.level-4 {
                background: darken($contrast-color, 10%) !important;
                &:after {
                    background: darken($contrast-color, 10%) !important;
                }
            }
        }
    }


}

/* navigation specific variables */
$navigation-width: 239px;

/* navigation toggle */
.nav-toggle {
    @extend %improve-animation;
    text-decoration: none;
    cursor: pointer;
    background: $white;
    z-index: 10;
    font-size: 0 !important;

    &:before {
        @extend %icon-font;
        @extend .icon-menu;
        margin: 0;
        vertical-align: -30%;
        font-size: 3rem;
        color: $grey-light;
    }

    &:after {
        display: none !important;
    }

    /* transition */
    transition: left .3s ease-out;
    position: relative;
    left: $grid-gutter-width;

    /* phone */
    @include respond-min($breakpoint-min-xs) {
        position: fixed;
        left: 0;
        top: -14px;
        width: 60px;
        height: 90px;
        text-align: center;

        &:before {
            top: 35%;
            position: relative;
        }
    }

    /* tablet */
    @include respond-min($breakpoint-min-m) {
        position: fixed;
        border-right: 1px solid $border-color;
        height: 76px;
        top: 0;
        width: 77px;

        &:before {
            top: 20%;
        }
    }

    /* desktop */
    @include respond-min($breakpoint-min-l) {
        display: none;
    }
}

/* navigation styles */
#navigationmark {
    position: relative;
    z-index: 5;
    background: #fff;
    width: $navigation-width;
    top: 137px;

    > ul > li:first-child {
        border-top: 0;
    }

    li {
        overflow: hidden;
        @include fontSizeREM(16);
        border-top: solid 1px $border-color;

        div:first-child {
            @extend %nav-color-label;
            display: block;
            position: relative;
            z-index: 1;

            p {
                margin: 0;
            }

            &.active {
                font-weight: 400;
                padding: $grid-gutter-width $grid-gutter-width*2.2 $grid-gutter-width $grid-gutter-width*1.5;
            }

            &.active-level:before {
                @extend .icon-down;
                font-size: 25px !important;
            }
        }

        a {
            text-decoration: none;
            padding: $grid-gutter-width $grid-gutter-width*2.2 $grid-gutter-width $grid-gutter-width*1.5;
            display: block;
        }

        &.color-pattern-7 {
            border-bottom: solid 1px $border-color;

            a {
                padding: 0;
            }
        }
    }

    .flyout {
        li {
            &:last-child {
                border-bottom: none;
            }
        }
        &.hidden-level {
            display: none !important;
        }
    }

    &:after {
        @extend %clearfix;
    }

    /* transition */
    transition: left .3s ease-out;

    /* phone */
    @include respond-min($breakpoint-min-xs) {
        left: - $navigation-width;
        bottom: 0;
        position: fixed;
    }

    // tablet
    @include respond-min($breakpoint-min-m) {
        left: -243px;
        overflow: hidden;
        width: 320px;

        &:after {
            height: auto;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: transparent;
            z-index: 3;
        }
    }

    // max tablet
    @include respond-max($breakpoint-max-m) {
        border-right: solid 1px $border-color;
    }

    /* desktop */
    @include respond-min($breakpoint-min-l) {
        margin-top: 0;
        overflow-y: auto;
        left: 0;
        top: 110px;
        bottom: auto;
        position: absolute;
        border-right: none;
        width: $navigation-width;

        &:after {
            display: none;
        }

        > ul > li:first-child {
            border-top: solid 1px $border-color;
        }
    }
}

/* navigation transition */
.navigation-is-active {
    overflow: hidden;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    #navigationmark {
        width: 100%;

        /* phone */
        @include respond-min($breakpoint-min-xs) {
            overflow-y: auto;
        }

        // navigation-width: full width in smartphones (max.: iPhone 6 Plus Portrait)
        @media (min-width: 415px) {
            width: 320px;
        }

        /* tablet */
        @include respond-max($breakpoint-max-m) {
            left: 0;
        }

        @include respond-min($breakpoint-min-m) {
            overflow-y: auto;

            &:after {
                display: none;
            }
        }

        @include respond-min($breakpoint-min-l) {
            width: 240px !important;
        }
    }
}

/* bugfix for falsely rendered empty <div class="level-4">|</div> */
#navigationmark li div.level-4:empty {
    display: none !important;
}

/**
*    navigation color pattern
*
*    desc:
*        apply color to all navigation items
*        color is retrieved from $color-pattern-list in _variables.scss
*
*    info:
*        navigation-items count = 7
*
**/
@each $current-color in $color-pattern-list {
    $i: index($color-pattern-list, $current-color);
    $current-contrast-color: nth($color-pattern-list-contrast, $i);

    //generate classes and apply css
    .color-pattern-#{$i} #navigationmark li {

        &.level-1 > .active-level,
        &.level-1 > .active-level a {
            color: $current-color;
        }

        div {
            &:after {
                background: rgba($current-color, .55);
            }
        }

        // like level2!!
        .level-back div {
            background: $current-color;

            &:after {
                background: darken($current-color, 10%);
            }
            a {
                color: $white;
            }
        }

        .flyout {
            color: $white;
            a {
                color: $white;
            }

            div.level-2 {
                font-size: 14px;
                background: rgba($current-color, .55);

                &:before {
                    font-size: 20px !important;
                    margin-right: 3px;
                }

                &:after {
                    background: $current-color;
                }
            }

            div.level-3 {
                background: $current-color;

                &:after {
                    background: darken($current-color, 10%);
                }
            }

            div.level-4 {
                background: darken($current-color, 10%);

                &:after {
                    background: darken($current-color, 10%);
                }
            }
        }

        /* desktop */
        @include respond-min($breakpoint-max-m) {
            &.is-open {
                a {
                    color: $current-color;
                }
            }
        }
    }

    /*
    .color-pattern-contrast.color-pattern-#{$i} #navigationmark li {
        div {
            &.active {
                border: 2px solid $current-contrast-color;
            }
        }
    }
    */
}

/**
*    navigation exceptions
*
*    desc:
*        navigation styles that are not consistent!
*
**/
#navigationmark {

    .color-pattern-7 {
        margin-bottom: 20px;

        div:first-child {
            @include fontSizeREM(14);
            position: relative;
            padding: 0 !important;

            &:before {
                @extend %icon-font;
                @extend .icon-expert;
                @include fontSizeREM(42);
                @include icon-background(40, #a0a5a9);
                margin-right: $grid-gutter-width/2;
                vertical-align: -30%;
                font-size: 35px;
                opacity: 1;
                position: absolute;
                left: 35px;
                top: 40%;
                margin-top: -18px;
                text-align: center;
            }
            p {
                padding: 20px 20px 20px 80px;

                @media (min-width: 415px) {
                    margin: 0;
                }
            }
        }
    }

    .network,
    .portal,
    .appointment {
        margin-bottom: 0;
        border-bottom: none !important;

        div:first-child {
            &:before {
                width: 35px;
                height: 35px;
                @include svgBG('navi-network');
                background-size: 35px 35px;
                content: "";
                margin-top: -10px;
            }
        }

        div.icon-after {
            &:before {
                display: block !important;
            }

            &.active-level:before {
                content: "";
            }
        }

    }
    .portal {
        border-bottom: solid 1px $border-color !important;

        div:first-child:before {
            @include svgBG('navi-portal');
            background-size: 35px 35px;
        }
    }

    .appointment {
        // border-bottom: solid 1px $border-color !important;
        div:first-child:before {
            background:none;
            // content: "\e940" !important;
            content: "\e629" !important;

            font-family: 'charite-icons';
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            // font-size: 25px;
            font-size: 40px;
            color: $text-color;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    }
}


@each $current-color in $color-pattern-list {
    $i: index($color-pattern-list, $current-color);
    // $current-contrast-color: nth($color-pattern-list-contrast, $i);

    //generate classes and apply css
    .color-pattern-#{$i} #navigationmark .appointment div:first-child:before {
        color: $current-color;
    }
}


.navigation-is-active #navigationmark .color-pattern-7 div:first-child p {
    padding: 12px 44px 12px 80px;
}

.level-back div {
    border-bottom: none;
    padding: 0;
}

// international
.color-pattern-5:not(.color-pattern-contrast) {
    #navigationmark li {
        //+border: 2px solid red;

        .level-back div {
            a,
            &:before {
                color: #5d5126;
            }
        }

        .flyout a,
        .level2 p,
        .level-2:before,
        .level-2.active,
        .level-3:before,
        .level-3.active {
            color: #5d5126;
        }
        .level-4 a {
            color: $white;
        }
    }
}

/**
*    Tablet Portrait View of Navigation
*
*    desc:
*        hides navigation links for portrait tablet-view of navigation
*        and pulls back-button-arrow to right side if navigation is not active.
*
**/

#navigationmark {

    p {
        opacity: 0;
        margin-bottom: 0;
        transition: all .25s ease-in-out;
    }

    .level-back {
        border-bottom: none !important;

        div:first-child {
            @include fontSizeREM(14);

            &:before {
                @extend .icon-left;
                right: $grid-gutter-width * 1.25;
                width: 20px;
                color: $white;
            }

            a {
                padding-left: 0;
            }
        }
    }

    @include respond-min($breakpoint-min-m) {

        .color-pattern-7 {

            div:first-child {
                padding-right: 50px;
                padding-left: 30px;

                &:before {
                    right: $grid-gutter-width * .75;
                    left: auto;
                }
            }
        }
    }

    @include respond-min($breakpoint-min-l) {

        div:first-child p {
            opacity: 1;
        }

        .level-back {

            div:first-child {
                a {
                    padding-left: 50px;
                }

                &:before {
                    width: 20px;
                    left: $grid-gutter-width;
                }
            }
        }

        .color-pattern-7 {

            div:first-child {
                padding: ($grid-gutter-width*.6) ($grid-gutter-width*1.25) ($grid-gutter-width*.6) ($grid-gutter-width*3.75);

                &:before {
                    left: 32px;
                }

            }

            &.network,
            &.portal,
            &.appointment {
                div:first-child p {
                    padding: 20px 20px 20px 80px !important;
                }
            }
        }

    }
}

.navigation-is-active {

    div:first-child p {
        opacity: 1 !important;
        transition: all ease-in-out .25s;
    }

    .level-back {
        border-bottom: none !important;

        div:first-child {
            padding-left: 50px;

            &:before {
                @extend .icon-left;
                left: $grid-gutter-width;
            }
        }
    }

    .color-pattern-7 {

        div:first-child {
            padding: ($grid-gutter-width) ($grid-gutter-width * 1.25) ($grid-gutter-width) ($grid-gutter-width * 3.75);

            &:before {
                left: ($grid-gutter-width * 1.25) !important;
                right: auto;
            }

            @include respond-min($breakpoint-min-m) {
                padding: ($grid-gutter-width*.6) ($grid-gutter-width*1.25) ($grid-gutter-width*.6) ($grid-gutter-width*3.75);
            }
        }

        &.network,
        &.portal,
        &.appointment {
            div:first-child p {
                padding: 20px 20px 20px 80px !important;
            }
        }

    }
}
