@charset "UTF-8";

/* mod-baustelle */

#baustelle {

    .wrapper {
        @include respond-min($breakpoint-min-m) {
            padding-left: 0;
        }
        @include respond-min($breakpoint-min-l) {
            padding-left: 0;
        }
    }

    .mod-stage-static,
    footer {
        max-width: (1155 / $body-font-size-px) + em;
    }

    .mod-finder,
    .mod-breadcrumbs,
    .highlighted {
        max-width: (1175 / $body-font-size-px) + em;
    }

    .container {
        max-width: (1155 / $body-font-size-px) + em;

        .box p,
        .box ul,
        .box ol {
            max-width: 100%;
        }
    }

    // stage
    .mod-stage-static {
        padding-top: 0;
        height: auto;
        .row {
            max-width: (1155 / $body-font-size-px) + em;
        }

        @include respond-min($breakpoint-min-l) {
            .col-m-6:nth-child(1) {
                width: 40%;
            }
            .col-m-6:nth-child(2) {
                width: 60%;
            }
        }
    }

    // contents
    @include respond-min($breakpoint-min-xl) {

        // for 5er tiles
        .col-xl-3 {
            width: 20% !important;
        }

        // Ansprechpartner
        .col-l-6 {
            width: 40%;
        }

        // Campi
        .col-l-3 {
            width: 20%;
        }
    }

    strong.extra {
        font-size: 185%;
    }

    strong.extra2 {
        font-size: 177%;
    }

    strong.extra3 {
        font-size: 170%;
    }

    // footer
    footer {

        nav {
            margin: 0;
            padding: 0;
        }

        .metanavi {
            margin: 0 auto;
            width: 140px;

            li {
                margin-bottom: 20px;
                display: block;

                a {
                    display: block;
                }
            }
        }

        @include respond-min($breakpoint-min-m) {
            #logo {
                float: left;
                width: 180px;
                margin-left: $grid-gutter-width;
            }
            .logo {
                margin: 0;
                left: 0;
                margin-left: 0;
                max-width: 180px;
            }

            nav {
                display: inline-block;
            }

            .metanavi {
                margin: 28px 0 0 70px;
                display: inline-block;
                width: auto;

                li {
                    float: left;
                    margin: 0 50px 0 0;
                }
            }
            .copyright {
                margin-top: 28px;
            }
        }
    }
}