@charset "UTF-8";

/* mod-custom-fixes */

/* debug */
.typo3-debug {
    position: relative;
    top: 0;
    left: 15em;
}

.list-related-diseases li {
    margin: .4em 0;
}

/*** remove it before launch ***/
/*
h1:before {
  content: "h1:";
}

h2:before {
  content: "h2:";
}

h3:before {
  content: "h3:";
}

h4:before {
  content: "h4:";
}

h5:before {
  content: "h5:";
}

h6:before {
  content: "h6:";
}
*/