@charset "UTF-8";

/* mod-form */

.mod-form {
    input[type=text],
    input[type=email],
    input[type=number],
    input[type=password],
    input[type=tel],
    input[type=url],
    input[type=date],
    input[type=time],
    select,
    textarea {
        box-sizing: border-box;
        width: 100%;
        padding: 8px;
        margin: 0;
        border: none;
        background: #f3f3f3;
        border-radius: 0;
        -webkit-appearance: none;
        vertical-align: top;
    }

    a {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    p {
        @include respond-min($breakpoint-min-m) {
            max-width: 80%;
        }
    }

    > p:not(.no_style) {
        border-bottom: solid 1px $border-color;
        padding-bottom: $grid-gutter-width*1.25;
        margin-bottom: $grid-gutter-width*1.25;

        @include respond-min($breakpoint-min-m) {
            max-width: 100%;
        }
    }

    /* RV why
    textarea {
        height: 335px;
    }
    */

    /* RV too much space cause br of field already existent */
    .powermail_form fieldset {
        border-bottom: none;
        margin-bottom: 0;
    }

    fieldset {
        border-bottom: solid 1px $border-color;
        margin-bottom: $grid-gutter-width;
        padding-bottom: $grid-gutter-width*.5;

        legend {
            margin-bottom: 10px;
        }

        legend.tx_powermail_pi1_fieldwrap_html_checkbox_title {
            font-weight: bold;
        }

        &:last-of-type {
            border-bottom: 0 !important;
            padding-bottom: 0;
        }
    }

    /* label */
    label {
        margin-bottom: .5em;
        cursor: pointer;

        &:after {
            @extend %clearfix;
        }

        input {
            margin-top: .3em;
            margin-right: .5em;
            float: left;
        }

        input[type=radio] {
            margin-top: 0;
            @include respond-min($breakpoint-min-m) {
                margin-top: .2em;
            }
        }

        span:not(.powermail_mandatory) {
            float: left;
            padding-right: 5px;

            width: 92%;
        }

        p {
            &.error {
                display: none !important;
            }
        }

        display: block;
    }

    .tx_powermail_pi1_fieldwrap_html_check {
        legend span {
            float: left;
            padding-right: 5px;
        }

        label {
            font-weight: 300;
        }
    }

    /* form-list */
    .form-list {
        margin: 0;
        padding: 0;
        list-style: none;

        max-width: 100% !important;

        li {
            width: 100%;
        }

        /* RV they provide their own br for each field */
        .powermail_form {
            .fields {
                .field {
                    margin-bottom: 0;
                }
            }
        }

        .fields {

            &:after {
                @extend %clearfix;
            }

            @include respond-max($breakpoint-max-s) {
                margin-bottom: 0;
            }

            .field-wrapper {
                width: 49%;
                float: left;
                margin-left: 2%;

                &:first-child {
                    margin-left: 0;
                }

                @include respond-max($breakpoint-max-s) {
                    width: 100%;
                    float: none;
                    margin-left: 0;
                }
            }

            .field {
                float: left;
                margin-bottom: calcPxToEm(20);
                /* NONO for powermail textarea
                margin-left: 2%;
                 */
                width: 49%;
                position: relative;

                &.margin-left {
                    margin-left: 2%;
                }

                @include respond-max($breakpoint-max-s) {
                    width: 100% !important;
                    margin-left: 0;
                    margin-bottom: 20px;
                }

                &:first-child {
                    margin-left: 0;
                }

                .input-box {
                    display: block;
                    border: 1px solid $border-color-form;

                    &:hover {
                        border: 1px solid $icon-hover-color;
                    }
                }

                .radio-box {
                    display: block;

                    @include respond-min($breakpoint-min-m) {
                        label {
                            margin-right: 30px;
                        }
                    }
                }

                label[for="message"] + .input-box {
                    border: none;

                    textarea {
                        border: 1px solid $border-color-form;

                        &:hover {
                            border: 1px solid $icon-hover-color;
                        }
                    }
                }

                + .field {
                    float: right;
                }

                &.size-one-quarter {
                    width: 23.5%;
                    float: left;
                }

                &.size-three-quarter {
                    width: 74%;
                }

                &.size-one-third {
                    width: 32%;
                    float: left;
                }

                &.size-two-third {
                    width: 64%;
                }

                &.wide {
                    width: 100%;
                }

                p {
                    &.error {
                        @include fontSizeREM(11);
                        display: block;
                    }
                }
            }
        }
    }

    /* date-select-fields */
    .input-date-wrapper {
        &:after {
            @extend %clearfix;
        }

        .input-box {
            float: left;
            /*
            margin-left: 2%;
             */
            width: 32%;

            &:first-child {
                margin-left: 0;
            }
        }

        span {
            float: left;
            margin-left: 2%;
            padding: 8px 0;
        }
    }

    /* error */
    .error,
    .error a,
    .error a:visited,
    .error a:hover,
    .error a:focus {
        color: $error-color;
    }

    .error {
        input,
        select,
        textarea {
            border-color: $error-color;
            color: $error-color;
        }
    }

    p.error {
        @include fontSizeREM(11);
        display: block;
    }

    .darkBg {
        input[type=text],
        input[type=email],
        input[type=number],
        input[type=password],
        input[type=tel],
        input[type=url],
        input[type=date],
        input[type=time],
        input[type=submit],
        button,
        select,
        textarea {
            background: #fff;
        }

        hr {
            color: #fff;
            border: none;
            border-top: 1px solid #FFF;
            margin: 10px 0;
        }

        .input-box-select {
            background: #fff;
        }

        fieldset {
            border-bottom: red;
        }

        .btn-set {
            margin-bottom: 0;
        }
    }
}

/* RV too much space for powermail having a br */
.powermail_form .btn-set {
    margin-bottom: $grid-gutter-width*0.5;
}

.btn-set {
    margin-bottom: $grid-gutter-width*2.5;

    .btn {
        border: 1px solid $border-color-form;
    }

    a {
        @include fontSizeREM(14);
        margin-top: 25px;
    }

    @include respond-min($breakpoint-min-m) {
        a {
            @include fontSizeREM(16);
        }
    }
}

.no-js {
    .mod-form {
        .additional-fields {
            display: block;
        }
    }
}

// contrast-styles
.color-pattern-contrast {

    .mod-form {
        .input-box,
        .btn,
        textarea {
            background: $white;
            border: 1px solid $black !important;
            color: $black;

            &:hover {
                outline: 1px solid $black !important;
                opacity: 1;
            }
        }

        label[for="message"] + .input-box {
            border: none !important;

            &:hover {
                outline: 0 !important;
            }
        }

        input {
            background: $white !important;
        }

        input[type=radio] {
            background: none !important;
        }
    }
}

p.thankyou {
    font-size: 1em;
    font-weight: bold;
}

.powermail_confirmation table {
    margin-bottom: 20px;
}

.powermail_confirmation table td {
    padding: 0 10px 2px 0;
}

input.powermail-submit-button-left {
    float: left;
    margin-right: 20px;
}


// formerly powermail.css
.powermail_fieldwrap label a {

    .link_icon_info,
    .link_icon_info:hover,
    .link_icon_info:active {
        text-decoration: none;
        border: none;
        position: relative;
    }

    img.icon_info {
        padding: 0 2px;
    }

    cursor: help;

    dfn {
        display: none;
        text-decoration: none;
        position: absolute;
        z-index: 1;
        width: auto;
        min-height: 15px;
        padding: 3px 10px 5px 10px;
        background-color: #00559E;
        border: 1px solid #00559E;
        color: #fff;
    }
}

.powermail_fieldwrap label a:hover dfn {
    display: block;
}

ul.powermail_message_error {
    padding-left: 0;
}

ul.powermail_message_error li {
    list-style-type: none;
    font-weight: bold;
    color: $error-color;
    margin: $grid-gutter-width 0;
    @include fontSizeREM(16);
    display: block;
}

.powermail_field ul.parsley-errors-list,
.powermail_field ul.parsley-errors-list li {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.powermail_field ul.parsley-errors-list li.parsley-custom-error-message,
.powermail_field ul.parsley-errors-list li.parsley-required {
    font-size: 100%;
    color: #CF0403;
}

.tx-powermail-pi1_confirmation td {
    font-size: 14px;
    font-size: .875rem;
    padding: 1em;
}

.tx-powermail-pi1_thx td,
.tx-powermail-pi1_confirmation td {
    font-size: 14px;
    font-size: .875rem;
    padding: 1em;
}

.tx-powermail .mod-form .csc-default p,
.tx-powermail .mod-form .csc-default ul.csc-bulletlist,
.tx-powermail .mod-form .csc-default ul.form-list {
    max-width: 100%;
}

.tx-powermail .mod-form .form-list .fields .field {
    width: 70%;
}
