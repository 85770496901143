@charset "UTF-8";

/* mod-table-of-content */

.mod-table-of-content {
    background: $finder-elements-bg;
    border: 1px solid $border-color-form;
    margin: 1em auto;
    padding: $grid-gutter-width;
    z-index: 1;

    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            display: block;
            margin: 1em 0;

            &:before {
                display: none;
            }

            a {
                @extend %patter-font-color;
                font-weight: 500;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    @include respond-min($breakpoint-min-m) {
        & {
            float: right;
            position: relative;
            margin: 0 0 1em $grid-gutter-width;
            max-width: 220px;
        }
    }
}


