@charset "UTF-8";

/* mod-slider-full-width */

@each $color in $color-pattern-list {
    $index: index($color-pattern-list, $color);
    $contrast-color: nth($color-pattern-list-contrast, $index);

    .color-pattern-#{$index} .mod-slider-full-width .flexslider .slides li .lupe:before {
        background-color: $color;
    }

    /* image X/Y */
    .color-pattern-#{$index} .mod-slider-full-width .flexslider .slides .flex-caption > span.labelwrap-slide {
        color: $color;
    }

    .color-pattern-contrast.color-pattern-#{$index} .mod-slider-full-width .flexslider .slides li .lupe:before {
        background-color: $contrast-color;
    }

    /* image X/Y */
    .color-pattern-contrast.color-pattern-#{$index} .mod-slider-full-width .flexslider .slides .flex-caption > span.labelwrap-slide {
        color: $contrast-color;
    }

}

.mod-slider-full-width {
    clear: both;

    .flexslider {

        &.zoom .slides {

            .slider-image {
                background-size: cover;
                transition: all ease-out .3s;
                background-position: center center;
                /*
                max-height: 600px;
                */
                height: auto;
                max-height: auto;
                text-indent: -999em;

                .focuspoint {
                    max-height: 470px;
                }

                img {
                    margin: 0;
                    transition: all ease-out .3s;
                }

                /* phone */
                @include respond-min($breakpoint-min-xs) {
                    height: auto;
                }
            }
        }

        .slides {

            li {
                position: relative;

                img {
                    width: 100%;
                    transition: all ease-out .3s;
                }

                .lupe {
                    position: absolute;
                    top: $grid-gutter-width * .5;
                    right: $grid-gutter-width * .5;
                    font-size: 0;
                    text-decoration: none;
                    opacity: 0;
                    transition: opacity .3s ease 0s;

                    &:before {
                        @extend %icon-font;
                        @extend .icon-zoom-in;
                        border-radius: 40px;
                        @include fontSizeREM(42);
                        @include icon-background(42);

                        margin-left: $grid-gutter-width/2;
                        vertical-align: -30%;
                        color: #fff;

                    }
                }
                .zoom-image:hover .lupe {
                    opacity: 1;
                }

                &:hover a {
                    visibility: visible;
                    opacity: 1;
                }
            }

            .slider-image {
                background-size: cover;
                transition: all ease-out .3s;

                background-position: center center;
                /*
                max-height: 270px;
                */

                text-indent: -999em;

                /* phone */
                @include respond-min($breakpoint-min-xs) {
                    height: auto;
                }
            }

            .flex-caption {
                /*
                background: $main-color;
                 */
                color: $black;

                padding: $grid-gutter-width*.5 $grid-gutter-width;
                margin: 0;
                position: relative;

                &:after {
                    @extend %clearfix;
                }

                > span.labelwrap-slide {
                    float: right;
                }
            }
        }

        &.zoom {
            .slides li .lupe {
                &:before {
                    @extend .icon-zoom-out;
                }
            }
        }

        @include respond-max($breakpoint-max-m) {
            .slides li .lupe {
                opacity: 1 !important;
            }
        }
    }
}