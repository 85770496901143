@charset "UTF-8";

/* grid */

// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.
.container {
    @include containerFixed();
    min-width: ($container-min-width / $body-font-size-px) + em;
    max-width: ($container-max-width / $body-font-size-px) + em;

    @include respond-min($breakpoint-min-s) {
        margin-left: 10px;
        margin-right: 10px;
    }
    @include respond-min($breakpoint-min-xxl) {
        max-width: (920 / $body-font-size-px) + em;
    }
}

// Row
//
// Rows contain and clear the floats of your columns.
%row {
    @include makeRow();
    margin-bottom: ($grid-gutter-width)
}

.row {
    @extend %row;
}

// Columns
//
// Common styles for small and large grid columns

@include makeGridColumns();

// Extra small grid
//
// Columns, offsets, pushes, and pulls for extra small devices like
// smartphones.

@include makeGrid(xs);

// Small grid
//
// Columns, offsets, pushes, and pulls for the small device range, from phones
// to tablets.

@include respond-min($breakpoint-min-s) {
    @include makeGrid(s);
}

// Medium grid
//
// Columns, offsets, pushes, and pulls for the desktop device range.

@include respond-min($breakpoint-min-m) {
    @include makeGrid(m);
}

// Large grid
//
// Columns, offsets, pushes, and pulls for the large desktop device range.
@include respond-min($breakpoint-min-l) {
    @include makeGrid(l);
}

// Extra Large grid
//
// Columns, offsets, pushes, and pulls for the extra-large desktop device range.

@include respond-min($breakpoint-min-xl) {
    @include makeGrid(xl);
}