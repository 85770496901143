@charset "UTF-8";

/* mod-breadcrumbs */

@each $color in $color-pattern-list {
    $index: index($color-pattern-list, $color);
    $contrast-color: nth($color-pattern-list-contrast, $index);

    .color-pattern-#{$index} .mod-breadcrumbs a {
        color: $color;
    }

    .color-pattern-#{$index} .mod-breadcrumbs li.current a:hover span {
        color: $color;
    }

    .color-pattern-contrast.color-pattern-#{$index} .mod-breadcrumbs a {
        color: $contrast-color;
    }

    .color-pattern-contrast.color-pattern-#{$index} .mod-breadcrumbs li.current a:hover span {
        color: $contrast-color;
    }
}

.mod-breadcrumbs {
    margin: 0;
    list-style: none;
    padding: ($grid-gutter-width * .25) $grid-gutter-width;
    /* RV SUGGESTION padding: $grid-gutter-width; */
    max-width: (940 / $body-font-size-px) + em;
    clear: both;

    &:after {
        @extend %clearfix;
    }

    li {
        display: inline-block;
        margin: 0 .2em 0 0;

        &:before {
            display: none !important;
        }

        &:after {
            @extend %icon-font;
            @extend .icon-right;
            @include fontSizeREM(11);
            color: $grey;
            vertical-align: middle;
            text-decoration: none;
            margin-left: $grid-gutter-width / 4;
        }

        &:last-child:after {
            display: none;
        }

        a {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        a span {
            text-decoration: none;

            &:hover span {
                text-decoration: underline;
            }
        }
    }

    li.current {
        a span {
            color: $text-color;

            &:hover span {
                text-decoration: underline;
            }
        }
    }
}

.project {
    .homepage {
        .mod-breadcrumbs {
            display: none;
        }
    }

    .be-layout-stage-only-image {
        .mod-breadcrumbs {
            position: relative;
            background: $white;
            max-width: none;
            margin: 0 $grid-gutter-width/2;
            @include respond-min($breakpoint-min-s) {
                margin: 0 $grid-gutter-width;
            }
        }
    }
}
