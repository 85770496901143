@charset "UTF-8";

/* mod-search */

@each $color in $color-pattern-list {
    $index: index($color-pattern-list, $color);
    $contrast-color: nth($color-pattern-list-contrast, $index);

    .color-pattern-#{$index} .mod-search .search-toggle:before,
    .color-pattern-#{$index} .mod-search button:before {
        background-color: $color;
    }

    .color-pattern-contrast.color-pattern-#{$index} .mod-search .search-toggle:before,
    .color-pattern-contrast.color-pattern-#{$index} .mod-search button:before {
        background-color: $contrast-color !important;
    }

    // desktop
    @include respond-min($breakpoint-min-l) {

        .color-pattern-#{$index} .mod-search .search-toggle,
        .color-pattern-#{$index} .mod-search button {
            background-color: $color;
        }

        .color-pattern-contrast.color-pattern-#{$index} .mod-search .search-toggle,
        .color-pattern-contrast.color-pattern-#{$index} .mod-search button {
            background-color: $contrast-color !important;
        }

        .color-pattern-#{$index} .mod-search .search-toggle:before,
        .color-pattern-#{$index} .mod-search button:before {
            background-color: white;
            color: $color;
        }

        .color-pattern-contrast.color-pattern-#{$index} .mod-search .search-toggle:before,
        .color-pattern-contrast.color-pattern-#{$index} .mod-search button:before {
            background-color: white !important;
            color: $contrast-color !important;
        }
    }
}

.mod-search {
    position: relative;
    z-index: 100;
    top: 25px;

    &.active {
        .field {
            width: 110%;
            border: 1px solid $grey;

            @include respond-min($breakpoint-min-m) {
                width: 99%;
            }
        }

        form button {
            z-index: 90;
        }
    }

    .field {
        position: absolute;
        top: -25px;
        right: -20px;
        overflow: hidden;
        width: 0;
        z-index: 2;
        transition: width .3s ease 0s;

        input {
            border: none;
            background: $white;
            padding: .5em;
            height: 60px;
            width: 70%;

            @include respond-min($breakpoint-min-s) {
                width: 80%;
            }

            @include respond-min($breakpoint-min-l) {
                width: 76%;
                height: 42px;
            }
        }
    }

    .search-toggle,
    button {
        position: absolute;
        cursor: pointer;
        z-index: 3;
        top: 0;
        right: 0;

        &:before {
            @extend %icon-font;
            @extend .icon-search;
            border-radius: 40px;
            color: $white;
            display: inline-block;
            font-size: 38px;
            height: 40px;
            width: 40px;
        }

        &:focus,
        &:visited {
            outline: 0;
        }
    }

    button {
        border-radius: 20px;
        border: none;
        position: absolute;
        padding: 0;
        z-index: 3;
    }

    /* desktop */
    @include respond-min($breakpoint-min-l) {

        .search-toggle,
        button {
            right: -16px;
            padding: 9px 15px 9px 10px;
            border-radius: 0;
            height: 61px;
        }

        .field {
            right: -16px;
            width: 0;
            border: none;
            top: 0;

            input {
                padding: 9px 9px 8px 9px;
                border: 1px solid #e9e9e9;
            }
        }

        &.active {

            .field {
                width: 373px;
                border: none;
            }
        }
    }
}

.ui-widget-content {
    background: $main-color !important;
    position: fixed;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100px;
    left: -1px;

    @include respond-min($breakpoint-min-l) {
        max-width: 375px;
        width: 100% !important;
        right: 2px;
    }

    &:hover li {
        background: none !important;
    }

    li {
        font-family: 'Roboto', sans-serif !important;
        font-weight: $webfont-light !important;
        font-size: 0.8em; /* autocomplete size in global search box */
        padding: .2rem 0;
        line-height: 1.5rem;
        background: none;
        border: none;
        color: rgba(86, 86, 86, .8);

        &:hover,
        &.ui-state-focus {
            color: $text-color;
            background: none;
            border: 1px solid transparent;
            text-decoration: underline;
        }
    }

    @include respond-min($breakpoint-min-m) {
        max-height: none;
    }
}

.mod-meta-navigation + ul {
    list-style: none;
    position: fixed;
    width: 100%;
    z-index: 20;
    right: 15px;
    top: 0;

    li {
        margin-left: 70px;
    }

    @media (min-width: 1180px) {
        max-width: 414px;
        left: 750px;
    }

    @include respond-min($breakpoint-min-s) {
        li {
            margin-left: 90px;
        }
    }

    @include respond-min($breakpoint-min-m) {
        li {
            margin-left: 55px;
        }
    }
}

// Contrast-Color
.color-pattern-contrast {

    .mod-search input {
        border: none !important;
    }

    .mod-search.active .field {
        outline: 2px solid $grey;
    }

    .ui-widget-content {
        background: $white !important;
        border: 1px solid $black;
        padding-left: -1px !important;

        li {
            color: rgba(0, 0, 0, 1) !important;
        }
    }
}

@include respond-min($breakpoint-min-l) {
    .mod-search.fixTop0 {
        top: 0;
    }
}
