@charset "UTF-8";

/* mod-table-surgery */

.mod-surgery {
    background: $main-color;
    margin: 0;
    padding: $grid-gutter-width;
}

.mod-table-surgery {
    @include respond-max($breakpoint-max-xs) {
        margin: 0 0 20px 0;
    }

    tbody {
        tr {
            background: none;
            border-bottom: none;
        }

        td,
        th {
            padding: 1em 1em .5em 0;
        }

        p {
            margin-bottom: 5px;

            @include respond-min($breakpoint-min-m) {
                margin-bottom: 0;
            }
        }

        @include respond-max($breakpoint-max-xs) {
            th {
                display: none;
            }
            td {
                display: block;

                &:before {
                    content: attr(data-title) ' ';
                    font-weight: bold;
                    display: block;
                }
            }
        }
    }
}

.project .mod-surgery {
    background: $white;
}