@charset "UTF-8";

/* mod-highlighted */

@each $color in $color-pattern-list {
    $index: index($color-pattern-list, $color);
    $contrast-color: nth($color-pattern-list-contrast, $index);

    .color-pattern-#{$index} .highlighted .mod-teaser,
    .color-pattern-#{$index} .highlighted .section-teaser div {
        background-color: $color;
    }

    .color-pattern-contrast.color-pattern-#{$index} .highlighted .mod-teaser,
    .color-pattern-contrast.color-pattern-#{$index} .highlighted .section-teaser div {
        background-color: $contrast-color;
    }

    .color-pattern-#{$index} .highlighted {
        background: rgba($color, .11);
    }
}

.highlighted {
    margin-bottom: $grid-gutter-width;
    /*
    1px padding is required for top and bottom or else inner margin of csc-default does not apply
     */
    padding: $grid-gutter-width 0 1px 0;
    max-width: (940 / $body-font-size-px) + em;

    /* RV WHY, we want regular headings even in highlighted area
    h1,
    h2:not([class*="icon-"]) {
        margin: 0;
    }
    */

    .mod-teaser,
    .section-teaser div {

        &:after {
            display: none;
        }
    }

    [class*="icon-"] {

        &.mod-teaser {
            color: $white;

            &:before {
                color: $white;
                border-color: $white;
            }
        }
    }

    &.mod-info {
        padding: $grid-gutter-width * .5;
        clear: both;
        margin-bottom: $grid-gutter-width;

        h2[class*="icon-"] {
            padding: 0;
            margin-left: 0;

            &:before {
                position: relative;
                top: auto;
                vertical-align: sub;
            }
        }

        h3 {
            margin-bottom: 5px;
        }
    }

    .csc-default {
        margin: 0 $grid-gutter-width;

        .csc-default {
            margin: 0;
        }
    }

    .row {
        max-width: 920px;
        /* RV WHY - ruins teaser boxes on start page portal
        margin: 0 10px;
        */
        @include respond-min($breakpoint-min-xxl) {
            max-width: (920 / $body-font-size-px) + em;
            /* RV WHY
            margin: 0 0 0 10px;
            */
        }

        .csc-default {
            margin: 0;
        }
    }

    .date,
    .job-section {
        display: block;
        margin-bottom: ($grid-gutter-width/2);
    }

    .highlight {
        border: solid 1px $border-color;
        background: #fff;
        padding: $grid-gutter-width;
    }

    @include respond-min($breakpoint-min-s) {

        &.mod-info {
            padding: $grid-gutter-width * .25 $grid-gutter-width;

            h2[class*="icon-"] {
                padding-left: 56px;

                &:before {
                    position: absolute;
                    top: 50%;
                    margin-top: -23px;
                    display: inline-block;
                }
            }

            p {
                margin-bottom: 1.5em;
            }

            .info-text {
                margin: 0;

                > div {
                    padding: 0 $grid-gutter-width 0 56px;
                }
            }
        }
    }

    @include respond-min($breakpoint-min-m) {
        &.mod-info {
            margin-bottom: 0;
        }
    }

    /* tablet max */
    @include respond-max($breakpoint-max-m) {
        img {
            display: block;
            margin: 0 auto;
        }
    }
}

// Contrast-Styles
.color-pattern-contrast {
    aside,
    figure figcaption,
    .highlighted {
        background: $white;
        color: $black;
        border: 1px solid $black;
    }
}

.two-cols {
    font-size: .875rem;

    @include respond-min($breakpoint-min-s) {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;

        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;
    }

    /* not needed anymore with page break avoid
    padding-bottom: 40px;
    */

    > ul {
        margin-bottom: -1em;
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;
    }

    > p {
        display: inline-block;
        margin-bottom: -1em;
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;
    }
}

.project {
    .highlighted {
        margin: 0 $grid-gutter-width/2 $grid-gutter-width $grid-gutter-width/2;

        @include respond-min($breakpoint-min-s) {
            margin: 0 $grid-gutter-width $grid-gutter-width $grid-gutter-width;
        }
    }

    .homepage .highlighted,
    .be-layout-stage-only-image .highlighted {
        background: $white !important;
        position: relative;
    }

    .homepage .highlighted {
        @include respond-min($breakpoint-min-m) {
            /* RV csc-default already adds 20px left/right
            padding: 30px 50px 50px 50px;
            */
            padding: 30px 10px;
        }
    }

}

.landingpage {
    .highlighted {
        background: $white;
        position: relative;

        @include respond-min($breakpoint-min-m) {
            margin: -(50px+74px) $grid-gutter-width 0 $grid-gutter-width;
            // RV On my own to support tiles of 440px width padding: 30px 50px 50px 50px;
            padding: 30px 0 20px 0;
        }

        @include respond-min($breakpoint-min-l) {
            margin: -(80px+74px) auto 0 auto;
        }

        @include respond-min($breakpoint-min-xl) {
            margin: -(100px+74px) auto 0 auto;
        }

        @include respond-min($breakpoint-min-xxl) {
            margin: -(124px+74px) auto 0 auto;
        }
    }
}
