@charset "UTF-8";

/* mod-link */

@each $color in $color-pattern-list {
    $index: index($color-pattern-list, $color);
    $contrast-color: nth($color-pattern-list-contrast, $index);

    .color-pattern-#{$index} .mod-link {
        background-color: $color;
    }

    .color-pattern-contrast.color-pattern-#{$index} .mod-link {
        background-color: $contrast-color;
    }
}

.color-pattern-1 .mod-link:hover {
    background: #1E8766;
}

.color-pattern-2 .mod-link:hover {
    background: #20629a;
}

.color-pattern-3 .mod-link:hover {
    background: #8f2a57;
}

.color-pattern-4 .mod-link:hover {
    background: #817159;
}

.color-pattern-5 .mod-link:hover {
    background: #ae902b;
}

.color-pattern-6 .mod-link:hover {
    background: #828485;
}

.color-pattern-7 .mod-link:hover {
    // TODO FIGURE OUT background: #828485;
}

.color-pattern-8 .mod-link:hover {
    background: #828485;
}

.color-pattern-9 .mod-link:hover {
    /* TODO BIH Link color Julianes proposal */
    background: #5A96AA;
}


%mod-link {
    padding: $grid-gutter-width*.75;
    display: block;
    margin: 0 auto $grid-gutter-width auto;
    color: #fff !important;

    div {
        margin: 0 auto;
        min-height: 70px;
        line-height: 70px;
        text-align: center;
    }

    strong {
        color: $white;
        vertical-align: middle;
        display: inline-block;
        line-height: normal;
    }
}

.mod-link {
    @extend %mod-link;

    // for microsites
    height: 200px;
}