@charset "UTF-8";

/* mod-map */

.mod-map {

    .my-map {
        min-height: 400px;
        margin: 10px;

        img {
            max-width: none;
        }
    }

    ul {
        padding: 0;
        list-style: none;
        margin: $grid-gutter-width 0;

        li {

            a {
                @extend %mod-link;
                transition: all ease-in-out .2s;
                background: $main-color !important;

                &.active {
                    background: #dfd9d1 !important;
                }

                @media only screen and (min-width: $breakpoint-min-m) {
                    &:hover {
                        background: #dfd9d1 !important;
                    }
                }

                strong {
                    color: $grey;
                }
            }
        }
    }
}

// Contrast-Styles
.color-pattern-contrast {

    .mod-map ul li a {
        background: $white !important;
        outline: 1px solid $black;
        color: $black;

        &.active,
        &:hover {
            outline: 2px solid $black;
        }
    }
}