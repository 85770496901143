@charset "UTF-8";

/* functions */

/**
 * Calculate PX to EM
 *
 * @param {number} $targetPx - pixel value without unit
 *
 */
@function pxToEm($targetPx) {
    // base em is 16px wide
    @return ($targetPx / 16) + 0em;
}
