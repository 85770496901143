@charset "UTF-8";

/* mod-simple-teaser */

@each $color in $color-pattern-list {
    $index: index($color-pattern-list, $color);
    $contrast-color: nth($color-pattern-list-contrast, $index);

    .color-pattern-#{$index} .flexslider .flex-direction-nav .flex-next,
    .color-pattern-#{$index} .flexslider .flex-direction-nav .flex-prev {
        background-color: $color;
    }

    .color-pattern-contrast.color-pattern-#{$index} .flexslider .flex-direction-nav .flex-next,
    .color-pattern-contrast.color-pattern-#{$index} .flexslider .flex-direction-nav .flex-prev {
        background-color: $contrast-color;
    }
}

.flexslider {
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background: none;
    border: none;
    margin: 0;

    ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    .flex-direction-nav {
        li {
            margin: 0;
        }

        a {
            transition: none;
            text-align: center;
            opacity: 0.5;
            z-index: 2;
            top: 43%;

            &:before {
                @extend %icon-font;
                @include fontSizeREM(30);
                line-height: 40px;
                margin-right: $grid-gutter-width/2;
                vertical-align: -30%;
            }

            &:after {
                display: none !important;
            }

            &.flex-next {
                @include icon-background(40);
                /* too far right, according to uk
                right: 20px;
                 */
                right: 0;
                font-size: 0;
                text-align: left;
                &:before {
                    margin-top: 0;
                    content: "\e61e";
                    margin-left: 6px;
                    position: absolute;
                }
            }

            &.flex-prev {
                @extend .icon-left;
                @include icon-background(40);
                /* too far left according to uk
                left: 20px;
                 */
                left: 0;
                font-size: 0;

                &:before {
                    margin-right: 6px;
                    vertical-align: top;
                }
            }
        }

        /* tablet */
        @include respond-max($breakpoint-max-m) {
            display: none;
        }
    }
    &:hover {
        .flex-direction-nav {
            a {
                opacity: 0.7;
            }
        }
    }

    .slides {
        img {
            width: auto;
        }

        li {
            background: none;
            margin: 0;
            padding: 0;
            display: none;

            &:first-child {
                display: block;
            }
        }
    }

    /* phone */
    @include respond-min($breakpoint-min-xs) {
        .flex-control-nav {
            bottom: 0;

            a {
                box-shadow: none;

                height: 15px;
                width: 15px;

                background: #fff;
                border: solid 1px #fff;

                &:hover,
                &.flex-active {
                    background: transparent;
                }
            }
        }
    }
}

.mod-carousel-logos {
    .flexslider {
        .flex-direction-nav {
            a.flex-prev {
                left: 0;
            }
            a.flex-next {
                right: 0;
            }
        }
        li {
            margin-right: 20px;
        }
        figure {
            margin: 0;
            background: $white;
        }
    }
}

.no-js {
    .flexslider {
        .slides {
            li {
                display: block;
                margin-bottom: $grid-gutter-width;
            }
        }
    }
}

/* RV MSIE HACK for IE 10 and above: force larger images within flexslider - this might be too much */
_:-ms-lang(x), .flexslider .slides img { width: 100%; }