@charset "UTF-8";

/* mod-teaser */

@each $color in $color-pattern-list {
    $index: index($color-pattern-list, $color);
    $contrast-color: nth($color-pattern-list-contrast, $index);

    .color-pattern-#{$index} .mod-teaser,
    .color-pattern-#{$index} [class*="icon-"].mod-teaser,
    .color-pattern-#{$index} .section-teaser,
    .color-pattern-#{$index} .section-teaser.-bg [class*="icon-"] {
        background-color: $color;

        &:before {
            color: $color;
            background-color: $white;
            border: solid 1px $white;
        }
    }

    .color-pattern-#{$index} .mod-teaser.from-emergency-bg {
        &:before {
            color: $emergency-color;
        }
    }

    .color-pattern-#{$index} {
        .news-teaser-big h5,
        .news-teaser-middle h5,
        .news-teaser-small h5 {
            color: $color;
        }
    }

    .color-pattern-#{$index} .location-teaser,
    .color-pattern-#{$index} .mod-teaser-info {
        border-color: $color;
    }

    .color-pattern-#{$index} .mod-info-teaser a span,
    .color-pattern-#{$index} .mod-vcard a:last-child {
        color: $color;
    }

    :not(.project) .color-pattern-#{$index} .news-teaser-big,
    :not(.project) .color-pattern-#{$index} .news-teaser-middle,
    :not(.project) .color-pattern-#{$index} .news-teaser-small {
        background: rgba($color, .11);
    }

    .color-pattern-contrast.color-pattern-#{$index} .mod-teaser,
    .color-pattern-contrast.color-pattern-#{$index} [class*="icon-"].mod-teaser,
    .color-pattern-contrast.color-pattern-#{$index} .section-teaser,
    .color-pattern-contrast.color-pattern-#{$index} .section-teaser.-bg [class*="icon-"] {
        background-color: $contrast-color !important;

        &:before {
            color: $contrast-color;
        }
    }

    .color-pattern-contrast.color-pattern-#{$index} .location-teaser,
    .color-pattern-contrast.color-pattern-#{$index} .mod-teaser-info {
        border-color: $contrast-color;
    }

    .color-pattern-contrast.color-pattern-#{$index} .mod-info-teaser a span,
    .color-pattern-contrast.color-pattern-#{$index} .mod-vcard a:last-child {
        color: $contrast-color;
    }

    .color-pattern-#{$index} .default-bg {
        background: $color !important;
        @extend %color-override-white;
        transition: all ease-in-out .2s;

        // inverse icons
        &.mod-teaser[class*="icon-"],
        [class*="icon-"]:not(.icon-inversed) {
            &:before {
                color: $color !important;
                background: $white !important;
            }
        }

        @media only screen and (min-width: $breakpoint-min-m) {
            &:hover {
                background: darken($color, 8%) !important;
            }
        }
    }
}

/**
*    %basic-teaser
*
*    functions as template for all other teasers
*    needs to be extended
*/
%basic-teaser {
    display: block;
    text-decoration: none;
    background: $main-color;
    position: relative;
    margin-bottom: $grid-gutter-width;

    span {
        @include fontSizeREM(14);
        text-decoration: none;
        @include prefix(hyphens, 'auto');
    }

    &:after {
        @extend %clearfix;
    }
}

.landpro {
    %basic-teaser {
        background: $white;
    }
}

.landpro .highlighted,
.landpro .container {
    %basic-teaser {
        background: $main-color;
    }
}

/**
*    %tile-teaser
*
*    standard declaration for teaser with set dimensions
*    needs to be extended
*/
%tile-teaser {
    padding: $grid-gutter-width*1.5 $grid-gutter-width*.5;
    height: 200px;
    text-align: center;
    overflow: hidden;

    span,
    strong {
        display: block;

        &:first-child {
            margin-bottom: $grid-gutter-width;
        }
    }
}

/**
*    %news-teaser
*
*    news-teaser classes
*
*    possible teaser:
*        news-teaser-large
*        news-teaser-big
*        news-teaser-middle
*        news-teaser-small
*
*/
.mod-news {
    .mod-news-teaser-big,
    .mod-news-teaser-middle,
    .mod-news-teaser-large {
        position: relative;
        margin-bottom: 20px;

        &:after {
            @extend %clearfix;
        }
    }

    > .row {
        margin-bottom: 0;
    }

    .col-l-6 {

        &:after {
            @extend %clearfix;
        }
    }

    a.moreLink {
        text-decoration: underline;
        bottom: 0;
        position: relative;
        float: right;

        &:hover {
            text-decoration: none;
        }
    }

    .mod-news-teaser-big {
        a.moreLink {
            bottom: 0;
        }

        @include respond-min($breakpoint-min-l) {
            margin-bottom: 20px;
        }
    }

    .mod-news-teaser-large .news-teaser-middle + .moreLink {
        margin-bottom: 20px;
    }
}

%news-teaser {
    background: $main-color;
    font-weight: $webfont-medium;
    overflow: hidden;
    height: auto;

    .teaser-content {
        padding: ($grid-gutter-width * .75);

        p {
            clear: both;
            margin: 0;
        }
    }

    .date {
        font-weight: $webfont-light;
        display: block;
        vertical-align: bottom;
        margin-bottom: $grid-gutter-width;
    }

    h5,
    .date {
        @include fontSizeREM(14);
        float: left;
        display: inline-block;
    }

    h5 {
        margin-right: $grid-gutter-width / 4;
        margin-bottom: 5px;
    }
}

/*
   teaser classes
    */

/**
*    .mod-teaser
*
*    standard teaser with text only
*/
.mod-teaser {
    @extend %basic-teaser;
    @extend %tile-teaser;

    color: $white;
    margin-left: auto;
    margin-right: auto;

    span,
    strong {
        &:first-child {
            min-height: 50px;
        }
    }

    &:hover {
        color: $white;
    }
}

/**
*    .default-teaser
*
*    standard teaser with text only
*/
.default-teaser {
    @extend %basic-teaser;
    @extend %tile-teaser;
    transition: all ease-in-out .2s;
    background: $main-color;

    margin-left: auto;
    margin-right: auto;

    span,
    strong {
        &:first-child {
            min-height: 50px;
        }
    }

    &:hover {
        color: $black;
    }

    @media only screen and (min-width: $breakpoint-min-m) {
        &:hover {
            background: #dfd9d1 !important;
        }
    }
}

/**
*    .mod-teaser
*
*    standard teaser with icon and only headline
*/
[class*="icon-"] {
    &.mod-teaser {

        strong {
            margin-bottom: 0;
            min-height: initial;
        }

        &:before {
            @extend %icon-font;
            @include fontSizeREM(55);
            @include icon-circle(80);
            vertical-align: -30%;
            display: block;
            margin: 0 auto ($grid-gutter-width/2) auto;
        }

        &:after {
            display: none;
        }
    }
}

/**
*    .mod-teaser
*
*    removes the color-decoration if the teaser has an all-over color
*/
[class*="from-"] {
    &.mod-teaser {
        &:after {
            display: none;
        }
    }
}

/**
*    .mod-teaser-info
*
*    standard teaser with text only
*/
.mod-teaser-info {
    @extend %basic-teaser;
    @extend %tile-teaser;
    margin-left: auto;
    margin-right: auto;
    border-bottom: 8px solid $default-color;
    -webkit-transition: border-width;
    transition: border-width;
    transition: all ease-in-out .4s;

    span,
    strong {

        &:first-child {
            min-height: 50px;
        }
    }

    @media only screen and (min-width: $breakpoint-min-m) {
        &:hover {
            color: $black;
            border-width: 16px;
        }
    }
}

/**
*    .section-teaser
*
*    highlighted teaser for each section,
*/
.section-teaser {
    @extend %basic-teaser;
    max-height: 200px;
    height: 200px;
    overflow: hidden;
    color: $white;

    .teaser-content {
        padding: $grid-gutter-width*1.5 $grid-gutter-width;
        text-align: center;
        background: none !important;

        strong {
            display: block;

            &:before {
                @extend %icon-font;
                @include icon-circle(80);
                @include fontSizeREM(55);
                display: block;
                margin: 0 auto ($grid-gutter-width/2) auto;
                border-color: $white;
                vertical-align: -30%;
            }

            &:after {
                display: none !important;
            }
        }
    }

    .teaser-image {
        float: right;
        display: none;
        background: none !important;
        // RV this padding is mandatory, was removed meanwhile (on behalf of radiologie) but requested back (by UK)
        padding-right: $grid-gutter-width/2;
        img {
            float: right;
        }
    }

    &:hover {
        color: $white;
    }

    &.teaser-clinic-link {
        @extend %color-override-white;
        transition: all ease-in-out .2s;
        background: nth($color-pattern-list, 1) !important;

        @media only screen and (min-width: $breakpoint-min-m) {
            &:hover {
                background: #007f58 !important;
            }
        }

        .teaser-content {
            width: 100%;
            height: 200px;
            text-align: left;

            strong {
                vertical-align: top;
            }
        }
    }

    @include respond-min($breakpoint-min-l) {

        .teaser-image {
            display: block;
        }
    }

    @include respond-min($breakpoint-min-l) {

        .teaser-content {
            float: left;
            display: table;

            strong {
                vertical-align: middle;
                display: table-cell;
            }
        }

        .teaser-content,
        .teaser-image {
            width: 50%;
            max-height: 200px;
        }
    }
}

/* max tablet */
@media(max-width: 1120px) {

    .highlighted .section-teaser {
        min-height: 200px;
    }
}

/**
*    .extern-teaser
*
*    mod-teaser modifier for external teaser
*/
.extern-teaser,
.tile-appointment-videochat .section-teaser {
    @extend %basic-teaser;
    @extend %tile-teaser;
    background: $default-color;
    margin-left: auto;
    margin-right: auto;

    &:before {
        @extend %icon-font;
        @extend .icon-extern;
        @include fontSizeREM(25);
        position: absolute;
        top: 2px;
        right: 2px;
    }

    &:after {
        display: none;
    }

    @media only screen and (min-width: $breakpoint-min-m) {
        &:hover {
            -webkit-transition: background .8s ease;
            transition: background .8s ease;
            background: #bdbdbd;
        }
    }

    span,
    strong {
        &:first-child {
            min-height: 50px;
        }
    }
}

// Julianes style
.tile-appointment-videochat {
    .section-teaser {

        @include respond-min($breakpoint-min-l) {
            &:before {
                right: 212px;
            }
        }

        &:before {
            background-color: $default-color;
            border:none;
            color: $text-color;
        }

        &:hover {
            &:before {
                -webkit-transition: background .8s ease;
                transition: background .8s ease;
                background: #bdbdbd;
            }
        }

        .teaser-content .icon-appointment-videochat {
            &:before {
                background-color: $text-color;
                border-color: $text-color;
                color: $default-color;
            }
        }

        padding: 0;
        color: $text-color;
    }
}


// Ronny/UK style
/*
.tile-appointment-videochat {
    .section-teaser {
        @include respond-min($breakpoint-min-l) {
            &:before {
                right: 212px;
            }
        }

        .teaser-content .icon-appointment-videochat {
            &:before {
                background-color: $white;
                border-color: $white;
                color: $icon-color;
            }
        }

        padding: 0;
        color: $white;
        background-color: $icon-color;
    }
}
*/

/**
*    .emergency-teaser
*
*    mod-teaser modifier for external teaser
*/
.emergency-teaser {
    @extend %basic-teaser;
    @extend %tile-teaser;
    background: $main-color;
    margin-left: auto;
    margin-right: auto;
    border-color: $emergency-color !important;
    transition: all ease-in-out .2s;

    strong {
        color: $emergency-color;
    }

    @media only screen and (min-width: $breakpoint-min-m) {
        &:hover {
            border-width: 16px;
        }
    }
}

/**
*    .news-teaser-big
*    .news-teaser-middle
*    .news-teaser-small,
*    .news-teaser-large
*
*/

.news-teaser-big {
    @extend %basic-teaser;
    @extend %news-teaser;

    img {
        width: 100%;
        max-width: 440px;
    }

    @include respond-min($breakpoint-min-s) {
        .teaser-content {
            height: auto;
            min-height: 150px + $grid-gutter-width;
        }
    }

    /* desktop */
    @include respond-min($breakpoint-min-l) {

        .teaser-content {
            padding-top: $grid-gutter-width * 2;
            height: auto;
            min-height: 200px + $grid-gutter-width;
        }
    }
}

.news-teaser-middle {
    @extend %basic-teaser;
    @extend %news-teaser;

    .teaser-image {
        float: right;
        margin: 0 ($grid-gutter-width / 2) 0 0;

        img {
            float: right;
            max-height: 200px;
            display: block;
        }
    }

    .teaser-content {
        float: left;
    }

    &:after {
        @extend %clearfix;
    }

    /* max desktop */
    @include respond-max($breakpoint-min-s) {

        .teaser-image {
            display: none;
        }
    }

    /* min tablet */
    @include respond-min($breakpoint-min-m) {

        .teaser-image {
            display: none;
        }
    }

    /* min desktop */
    @include respond-min($breakpoint-min-l) {

        div {
            width: 50%;
        }

        .teaser-image {
            display: block;
        }

        > div {
            width: 100%;

            .teaser-content,
            .teaser-image {
                width: 100%;
            }

            .col-s-5,
            .col-l-6:last-child,
            .teaser-image {
                margin: 0;
                padding: 0;
            }

            .col-s-7,
            .col-l-6:first-child {
                width: 54%;
            }

            .col-s-5:last-child,
            .col-l-6:last-child {
                width: 46%;
                float: right;
            }
        }
    }
}

.news-teaser-small {
    @extend %basic-teaser;
    @extend %news-teaser;

    @include respond-min($breakpoint-min-l) {
        min-height: 200px;
    }
}

.mod-news-teaser-large {

    .news-teaser-middle {

        span {
            float: none;
        }

        .teaser-image {
            float: right;

            img {
                margin: 0;
                height: auto;
                outline: 0;
                max-width: 100%;
            }

            /* desktop */
            @include respond-max($breakpoint-min-l) {
                display: block;
            }
        }

        .teaser-content {
            float: left;
            height: auto;
        }

        /* max S */
        @include respond-max($breakpoint-min-s) {

            .teaser-image {
                display: none;
            }

            .teaser-content {
                width: 100%;
            }
        }
    }
}

/**
*    .location-teaser
*
*    location-teaser class
*
*/
.location-teaser {
    @extend %basic-teaser;
    @extend %tile-teaser;
    transition: all ease-in-out .2s;

    padding: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    height: auto;

    p {
        padding: 19px 15px;
        margin: 0;
    }

    img {
        width: 100%;
    }

    @media only screen and (min-width: $breakpoint-min-m) {
        &:hover {
            background: #dfd9d1 !important;
        }
    }
}

/**
*    .mod-vcard
*
*    mod-vcard class
*
*/
.mod-vcard {
    @extend %basic-teaser;

    @include respond-min($breakpoint-min-s) {

        .teaser-content,
        .teaser-image {
            width: 50%;
            float: left;
        }
    }

    .teaser-image {
        margin-left: 10px;
        max-width: 200px;

        @include respond-max($breakpoint-min-s) {
            display: none;
        }
    }

    .teaser-content {
        padding: $grid-gutter-width;

        strong {
            display: block;
            margin-bottom: $grid-gutter-width;
        }
    }

    .adr {
        @include fontSizeREM(14);
        margin-bottom: $grid-gutter-width;

        > * {
            display: block;
        }

        .tel,
        .street-address {
            padding-top: 2px;
            margin: 0 0 0 37px;

            &:before {
                @extend %icon-font;
                @include fontSizeREM(42);
                margin-right: $grid-gutter-width/2;
                vertical-align: -30%;
            }

            &:after {
                display: none !important;
            }
        }

        .tel {
            margin-bottom: 10px;
        }

        .tel:before,
        .street-address:before {
            @include icon-background(26, nth($color-pattern-list, 6));
            @include fontSizeREM(24);
            margin: -5px 10px 10px -37px;
            position: absolute;
        }

        .tel:before {
            @extend .icon-phone;
        }

        .street-address:before {
            @extend .icon-locations;
        }
    }

    a:last-child {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

}

/*** vcard clear as rows are missing ***/
@include respond-min($breakpoint-min-l) {
    .bloc-contact .col-l-6:nth-child(odd) {
        clear: left;
    }
}

/**
*    .mod-info-teaser
*
*    mod-info-teaser class
*
*/
.mod-info-teaser {
    @extend %basic-teaser;

    .teaser-image {
        max-width: 200px;
        display: block;
        float: left;
        margin-right: 20px;

    }

    .teaser-content {
        padding: $grid-gutter-width;

        strong {
            display: block;
            margin-bottom: $grid-gutter-width;
        }
    }

    a .teaser-content span {
        font-size: 1em;
        text-decoration: underline;
    }

    a:hover .teaser-content span {
        text-decoration: none;
    }

    @include respond-max($breakpoint-min-s) {
        .teaser-image {
            display: none;
        }
    }
}

/**
*    .mod-teaser
*
*    mod-teaser-from-other-sections
*
*/
.mod-teaser-from-other-sections p {
    margin-bottom: $grid-gutter-width*1.5;
}

/**
*    .mod-teaser
*
*    mod-teaser hover effects
*
*/
.mod-teaser-inside-colored-sections .mod-teaser {
    transition: all ease-in-out .1s;
}

.mod-teaser-list article h3,
.mod-teaser,
.mod-link {
    transition: all ease-in-out .2s;
}

.mobile-text-link {
    @include respond-max ($breakpoint-max-s) {
        background: none !important;
        padding: 0;
        text-align: left;
        height: auto;

        span {
            display: none;
        }

        &.from-clinic-bg strong {
            color: nth($color-pattern-list, 1);
        }
        &.from-research-bg strong {
            color: nth($color-pattern-list, 2);
        }
        &.from-study-bg strong {
            color: nth($color-pattern-list, 3);
        }
        &.from-career-bg strong {
            color: nth($color-pattern-list, 4);
        }
        &.from-international-bg strong {
            color: nth($color-pattern-list, 5);
        }
        &.from-charite-bg strong {
            color: nth($color-pattern-list, 6);
        }
        &.from-contact-bg strong {
            color: nth($color-pattern-list, 7);
        }
        &.from-service-bg strong {
            color: nth($color-pattern-list, 8);
        }
        &.from-bih-bg strong {
            color: nth($color-pattern-list, 9);
        }
    }

    @include respond-min ($breakpoint-min-m) {
        strong {
            color: $white !important;
        }
    }
}

.color-pattern-contrast {
    .mobile-text-link {
        @include respond-max($breakpoint-max-s) {
            background: none !important;
        }
    }
}

// Contrast-Styles
.color-pattern-contrast {

    .mod-teaser-info {
        background: $white;
        border-top: 2px solid;
        border-right: 2px solid;
        border-left: 2px solid;
        color: $black;
    }

    .section-teaser.teaser-clinic-link {
        background: nth($color-pattern-list-contrast, 1) !important;

        @include respond-min($breakpoint-min-m) {
            &:hover {
                background: #007f58 !important;
            }
        }
    }

    .default-teaser {
        outline: 1px solid $black !important;
        color: $black;

        &:hover {
            outline: 2px solid $black !important;
        }
    }

    .mod-vcard,
    .mod-info-teaser,
    .location-teaser {
        background: $white !important;
        outline: 1px solid $black !important;
        color: $black;

        &:hover {
            background: $white !important;
            outline: 2px solid $black !important;
        }
    }

    .news-teaser-middle,
    .news-teaser-big,
    .news-teaser-small {
        background: $white !important;
        outline: 1px solid $black !important;
        color: $black;

        &:hover {
            background: $white !important;
            outline: 2px solid $black !important;
        }
    }

    .moreLink {
        color: $black;
    }

    .extern-teaser {
        background: #e5e7e9;
        color: $black;

        &:hover {
            background: #bdbdbd;
        }
    }

    li:before:not(.mod-article li:before),
    .tel:before,
    .street-address:before {
        background: $icon-contrast-color !important;
    }
}

.extern {
    position: relative;
    &:after {
        @extend %icon-font;
        @extend .icon-extern;
        @include fontSizeREM(25);
        position: absolute;
        top: 2px;
        right: 10px;
        color: $white;
    }
}

.section-teaser .extern:after {
    right: 0;
}

// fix icon problem in address detail
@include respond-max($breakpoint-max-xs) {
    .extern.factscience {
        clear: both;
    }
}

.project {
    .news-teaser-big,
    .news-teaser-middle,
    .news-teaser-small {
        background: $white;
    }
}