@charset "UTF-8";

/* mod-finder */

@each $color in $color-pattern-list {
    $index: index($color-pattern-list, $color);
    $contrast-color: nth($color-pattern-list-contrast, $index);

    //generate pattern-classes and apply css
    .color-pattern-#{$index} .mod-finder .tx-frontendlist-pi-selectorbox.active {
        background-color: $color;

        span:after {
            color: $color;
            border-color: $color;
        }
    }

    .color-pattern-#{$index} .mod-finder,
    .color-pattern-#{$index} .mod-finder .tx-frontendlist-pi-searchbox-wrap .btn-lupe:after,
    .color-pattern-#{$index} .mod-finder .tx-frontendlist-pi-selectorbox .btn-lupe:after,
    .color-pattern-#{$index} .mod-finder form input[type="submit"],
    .color-pattern-#{$index} .mod-finder ul li a:after {
        background-color: $color;
    }

    .color-pattern-#{$index} .mod-finder ul li,
    .color-pattern-#{$index} .mod-finder ul li a:before {
        color: $color;
    }

    .color-pattern-#{$index} .mod-finder ul li a,
    .color-pattern-#{$index} .mod-finder ul li a.active {
        border-color: $color;
    }

    .lt-ie10 .color-pattern-#{$index} .mod-finder .tx-frontendlist-pi-selectorbox.active,
    .lt-ie9 .color-pattern-#{$index} .mod-finder .tx-frontendlist-pi-selectorbox.active {
        background-color: #f3f3f3;

        select {
            border: solid 3px $color;
        }

        span:after {
            color: $text-color;
            border-color: $border-color-form;
        }
    }

    .color-pattern-contrast.color-pattern-#{$index} .mod-finder,
    .color-pattern-contrast.color-pattern-#{$index} .mod-finder ul li a:after,
    .color-pattern-contrast.color-pattern-#{$index} .mod-finder .tx-frontendlist-pi-selectorbox.active {
        background-color: $contrast-color;

        span:after {
            color: $contrast-color;
            border-color: $contrast-color;
        }
    }

    .color-pattern-contrast.color-pattern-#{$index} .mod-finder input.submit,
    .color-pattern-contrast.color-pattern-#{$index} .mod-finder .tx-frontendlist-pi-selectorbox .btn-lupe:after,
    .color-pattern-contrast.color-pattern-#{$index} .mod-finder .tx-frontendlist-pi-searchbox-wrap .btn-lupe:after {
        background-color: $contrast-color;
    }

    .color-pattern-contrast.color-pattern-#{$index} .mod-finder ul li a:before {
        color: $contrast-color;
    }

    .color-pattern-contrast.color-pattern-#{$index} .mod-finder ul li a,
    .color-pattern-contrast.color-pattern-#{$index} .mod-finder ul li a.active {
        border-color: $contrast-color;
    }

    .lt-ie10 .color-pattern-contrast.color-pattern-#{$index} .mod-finder .tx-frontendlist-pi-selectorbox.active,
    .lt-ie9 .color-pattern-contrast.color-pattern-#{$index} .mod-finder .tx-frontendlist-pi-selectorbox.active {
        background-color: $white;

        select {
            border: solid 3px $contrast-color !important;
        }

        span:after {
            color: $text-color;
            border-color: $border-color-form;
        }
    }
}

.mod-finder {
    position: relative;
    padding: 16px 10px 1px 10px;
    width: 100%;
    overflow: visible;
    max-width: (940 / $body-font-size-px) + em;

    .padding-bottom {
        padding-bottom: 16px;
    }

    &.landingpage {
        margin-bottom: 0;
    }

    input, select {
        margin: 0;
        padding: 0;
        outline: none;
        font-family: inherit;
        box-sizing: border-box;
        @include prefix(appearance, none);
        border-radius: 0;
    }

    option[disabled] {
        color: $grey;
    }

    select::-ms-expand {
        display: none;
    }

    legend {
        font-weight: 500;
        margin-bottom: 1em;
        float: left;
    }

    .sub-legend {
        display: none;
    }

    .twitter-typeahead {
        display: block !important;

        .tt-menu {
            width: 100%;
            max-height: 150px;
            overflow: auto;
            background: $white;
            border-left: solid 1px $border-color-form;
            border-bottom: solid 1px $border-color-form;
            border-right: solid 1px $border-color-form;
        }

        .tt-suggestion {
            padding: ($grid-gutter-width / 3) .5em;
            cursor: pointer;

            &:hover {
                background: #f9f9f9;
            }
        }
    }

    .tx-frontendlist-pi-link,
    .tx-frontendlist-pi-searchbox-reset {
        width: 200px;
        text-decoration: underline;
        @include fontSizeREM(14);

        &:hover {
            text-decoration: none;
        }
    }

    .tx-frontendlist-pi-searchbox-reset {
        float: right;
        text-align: right;
        margin-top: 3px;
    }

    .tx-frontendlist-pi-searchbox-wrap {
        border: 1px solid $border-color-form;
    }

    .tx-frontendlist-pi-selectorbox {
        background: #f3f3f3;
        border: 1px solid $border-color-form;
        margin: 0 0 1.25em 0;
        position: relative;
        /* overflow: hidden; RV required for twitter typeahead*/
        height: auto;
        width: 100%;

        &.active {

            select,
            span {
                color: $white;
            }

            option {
                color: $text-color;
                background: #f3f3f3;
            }
        }

        @include respond-min($breakpoint-min-m) {
            float: left;
            width: 49%;

            &:nth-child(even) {
                float: right;
            }
        }

        @media only screen and (min-width: $breakpoint-min-m) {
            &:hover {
                border: 1px solid $icon-hover-color;
            }
        }

        label {
            font-size: 0;
        }

        select {
            border: none;
            position: relative;
            font-weight: 500;
            margin-top: -22px;
            width: 100%;
            height: 71px;
            padding: 15px 10px 33px 10px;
            background: transparent;
            vertical-align: top;
            font-size: 14px;
            z-index: 1;
        }

        span:not(.twitter-typeahead) {
            @include fontSizeREM(12);
            position: absolute;
            top: 35px;
            left: 15px;
            padding: 0 5em 0 0;
            width: 100%;
            z-index: 0;

            @include respond-min($breakpoint-min-m) {
                padding: 0;
            }

            &:before {
                display: none !important;
            }

            &:after {
                @extend %icon-font;
                @extend .icon-down;
                @include icon-background(30, #fff);
                @include fontSizeREM(20);
                margin-left: $grid-gutter-width/2;
                vertical-align: -30%;
                border: 1px solid $border-color-form;
                color: $border-color-form;
                position: absolute;
                right: 22px;
                top: -15px;
            }
        }

        input {
            width: 100%;
            padding: .5em 2.5em .5em .5em;
            margin: 0;
            border: none;
            height: 70px;
            font-size: 1rem;

            &.tx-frontendlist-pi-searchbox-sword::-webkit-input-placeholder {
                @include fontSizeREM(13);
                padding-top: 3px;
            }

            &.tx-frontendlist-pi-searchbox-sword:-moz-placeholder {
                @include fontSizeREM(13);
                padding-top: 3px;
            }

            &.tx-frontendlist-pi-searchbox-sword::-moz-placeholder {
                @include fontSizeREM(13);
                padding-top: 3px;
            }

            &.tx-frontendlist-pi-searchbox-sword:-ms-input-placeholder {
                @include fontSizeREM(13);
                padding-top: 3px;
            }

            &.tx-frontendlist-pi-searchbox-sword.placeholder {
                @include fontSizeREM(13);
                padding-top: 3px;
            }
        }

        .btn-lupe {
            right: 20px;
            left: auto;
            font-size: 0;
            width: 10px;
            height: 10px;
            margin-right: 4px;
            position: absolute;
            top: 40px;
            border: 0;
            background: #fff;

            &:after {
                @extend %icon-font;
                @extend .icon-search;
                @include icon-background(30);
                @include fontSizeREM(26);
                color: #fff;
                border: 0;
                top: -20px;
                right: -10px;
                width: 30px;
                height: 30px;
                position: absolute;
                display: block;
            }
            &:before {
                display: none;
            }
        }
    }

    .tx-frontendlist-pi-searchbox-wrap {
        float: left;
        width: 100%;
        position: relative;

        input {
            width: 100%;
            padding: .5em 2.5em .5em .5em;
            margin: 0;
            height: 50px;
            font-size: 1rem;
            border: none;

            &.tx-frontendlist-pi-searchbox-sword::-webkit-input-placeholder {
                @include fontSizeREM(13);
                padding-top: 3px;
            }
            &.tx-frontendlist-pi-searchbox-sword:-moz-placeholder {
                @include fontSizeREM(13);
                padding-top: 3px;
            }
            &.tx-frontendlist-pi-searchbox-sword::-moz-placeholder {
                @include fontSizeREM(13);
                padding-top: 3px;
            }
            &.tx-frontendlist-pi-searchbox-sword:-ms-input-placeholder {
                @include fontSizeREM(13);
                padding-top: 3px;
            }
        }

        .btn-lupe {
            right: 10px;
            left: auto;
            font-size: 0;
            width: 10px;
            height: 10px;
            margin-right: 4px;
            position: absolute;
            top: 30px;
            border: 0;
            background: #fff;

            &:after {
                @extend %icon-font;
                @extend .icon-search;
                @include icon-background(30);
                @include fontSizeREM(26);
                color: #fff;
                border: 0;
                top: -20px;
                right: -10px;
                width: 30px;
                height: 30px;
                position: absolute;
                display: block;
            }
            &:before {
                display: none;
            }
        }
    }

    .single-item {
        .tx-frontendlist-pi-searchbox-wrap {
            input {
                &.tx-frontendlist-pi-searchbox-largeplaceholder::-webkit-input-placeholder {
                    color: $text-color;
                    font-weight: 400;
                    @include fontSizeREM(16);
                    padding-top: 0;
                }
                &.tx-frontendlist-pi-searchbox-largeplaceholder:-moz-placeholder {
                    color: $text-color;
                    font-weight: 400;
                    @include fontSizeREM(16);
                    padding-top: 0;
                }
                &.tx-frontendlist-pi-searchbox-largeplaceholder::-moz-placeholder {
                    color: $text-color;
                    font-weight: 400;
                    @include fontSizeREM(16);
                    padding-top: 0;
                }
                &.tx-frontendlist-pi-searchbox-largeplaceholder:-ms-input-placeholder {
                    color: $text-color;
                    font-weight: 400;
                    @include fontSizeREM(16);
                    padding-top: 0;
                }
            }
        }
    }

    form input[type=submit] {
        border: none;
        color: $white;
        padding: 1em;
        font-weight: bold;
        font-size: .9em;
        float: none;
        max-width: 200px;
        width: 100%;
        height: auto;
        margin: 0;
        float: right;
    }

    .btn-wrap {
        &:after {
            @extend %clearfix;
        }

        margin: 0 0 1em 0;
        float: right;
        text-align: center;
        width: 100%;

        a {
            display: block;
        }

        @include respond-min($breakpoint-min-m) {
            text-align: left;

            a {
                display: inline-block;
            }
        }
    }

    .btn-search {
        margin-top: 1em;
        @include respond-min($breakpoint-min-s) {
            width: 100%;
            &::before, &::after {
                content: '';
                display: table;
                clear: both;
            }
            input {
                width: 200px;
            }
        }
    }

    .tx-frontendlist-pi-link {
        text-align: left;
        padding: 0 0 1em 0;
        width: 200px;
        margin-top: 0;
    }

    ul {
        @extend %row;
        clear: both;
        padding: 0;
        list-style: none;
        max-width: 920px;
        margin: 0 10px 0 10px;

        li {
            &:first-child {
                padding-left: 0;
                margin: 0 0 1em 0;
                a:before {
                    @extend %icon-font;
                    @extend .icon-locations;
                    font-size: 2rem;
                }
            }

            float: none;
            width: 100%;
            position: relative;
            display: table;

            @include respond-min($breakpoint-min-m) {
                float: left;
                margin: 0;
                width: 49%;

                &:first-child {
                    margin: 0;
                }

                &:nth-child(even) {
                    float: right;
                }
            }

            a {
                background: $white;
                display: block;
                font-size: 1rem;
                font-weight: 400;
                line-height: 20px;
                text-align: center;
                position: relative;
                padding: 20px 50px;

                vertical-align: middle;
                display: table-cell;
                width: inherit;

                &:first-child {
                    margin-bottom: 1em;
                }

                &:before {
                    @extend %icon-font;
                    @extend .icon-expert;
                    position: absolute;
                    left: 15px;
                    top: 15px;
                    font-size: 2em;
                }

                &:after {
                    @include icon-background(30);
                    content: "+";
                    position: absolute;
                    right: 15px;
                    top: 15px;
                    font-size: 1.4em;
                    -webkit-transition: all ease .3s;
                    transition: all ease .3s;
                }

                &.active {

                    @include respond-min($breakpoint-min-m) {
                        padding-bottom: 1em;
                    }

                    &:after {
                        content: "-";
                        font-size: 30px;
                        line-height: 25px;
                        transform: rotate(180deg);
                        transform-origin: 50% 50%;
                    }
                }
            }
        }
    }

    .finder-tab {
        display: none;
    }

    > div,
    .finder-tab {
        background: $white;
        padding: 1em 1em 0;
        //padding: 1em 1em .1em;
        margin: 0 10px;

        &.active {
            display: block;
            margin-bottom: 20px;
        }

        .field {
            width: 100% !important;
        }
    }

    .single-item {
        padding: 0 0 16px 0;
        background-color: transparent;

        fieldset {
            margin-bottom: 0;
        }
    }

    .row {
        margin-bottom: 0;
    }

    .headline {
        display: block;
        margin: 0 0 1em 0;
    }

    .mod-link {
        @extend %mod-link;

        background: $finder-elements-bg !important;
        padding: 10px 15px;
        transition: all ease-in-out .2s;
        &:hover {
            background: $finder-elements-bg-hover !important;
        }

        div {
            word-break: normal;
            @include respond-max($breakpoint-max-xs) {
                @include prefix(hyphens, 'auto');
            }
        }

        strong {
            color: $link-color !important;
        }
    }

    .lexicon-item {
        padding: 10px;
    }

    .mod-teaser,
    .mod-link {
        background: $finder-elements-bg;
        color: $link-color;

        &:hover {
            background: $finder-elements-bg-hover;
        }
    }

    @include respond-min($breakpoint-min-s) {
        .reset-link-wrap {
            width: 200px;
        }

        .tx-frontendlist-pi-link {
            text-align: left;
            margin-top: -50px;
        }
    }

    @include respond-min($breakpoint-min-m) {
        height: 100%;

        ul li a {
            border-bottom: 15px solid;

            &.active {
                border-bottom: none;
                padding-top: 0;

                &:before,
                &:after {
                    top: 15px;
                }
            }

            &:first-child {
                margin-bottom: 0;
            }
        }

        fieldset {
            margin-bottom: 20px;

            .btn-wrap + div {
                clear: both;
            }
        }

        .tx-frontendlist-pi-selectorbox span:after {
            right: 30px;
        }

        .tx-frontendlist-pi-searchbox-wrap {
            .btn-lupe {
                right: 20px;
            }

            input {
                padding: .5em 4em .5em 1em;
            }
        }
    }

    @include respond-max($breakpoint-min-m) {
        .finder-tab {
            margin: 0 0 20px 0;
        }

        .tx-frontendlist-pi-selectorbox select {
            margin-top: -24px;
        }

        ul li {
            margin: 0 0 1em 0;
        }
    }

    @media (min-width: 768px) and (max-width: 826px) {
        ul li a {
            &:before,
            &:after {
                top: 20px;
            }

            &.active {
                &:before,
                &:after {
                    top: 20px;
                }
            }
        }
    }

    @include respond-max($breakpoint-max-s) {
        padding: ($grid-gutter-width * .25) 0 0 0;

        ul {
            margin: 0 ($grid-gutter-width * .25);

            li {
                margin-bottom: ($grid-gutter-width * .25);

                &:first-child {
                    margin-bottom: ($grid-gutter-width * .25);
                }

                a {
                    padding: ($grid-gutter-width * .5) ($grid-gutter-width * 2.5);

                    &:before {
                        font-size: 24px !important;
                    }

                    &:before,
                    &:after {
                        top: 8px;
                    }

                    &.active:after {
                        line-height: 22px;
                    }

                    &:after {
                        @include icon-background(24);
                        @include fontSizeREM(16);
                    }
                }
            }
        }

        legend {
            margin-bottom: 0;
        }

        .btn-search {
            margin-bottom: 2em;
        }

        .tx-frontendlist-pi-selectorbox {

            select {
                height: 55px;
                padding: 0 ($grid-gutter-width * .5) ($grid-gutter-width * 1.25) ($grid-gutter-width * .5);
                @include fontSizeREM(16);
            }

            span:not(.twitter-typeahead) {
                top: 25px;
                margin-left: 2px;
                left: ($grid-gutter-width * .5);
                line-height: 12px;

                &:after {
                    top: -13px;
                    right: 18px;
                }
            }

            input {
                height: 55px;
            }

            .btn-lupe {
                top: 33px;
                right: 13px;
            }
        }

        .finder-tab.active {
            margin-bottom: ($grid-gutter-width * .5);
        }

        > div {
            margin: 0 ($grid-gutter-width * .25);
        }

        .single-item {
            margin-bottom: 0;
            padding-bottom: 5px;

            .btn-lupe {
                right: 20px;
                top: 33px;

                &:after {
                    @include icon-background(30);
                    @include fontSizeREM(26);
                }
            }
        }
    }
}

.color-pattern-contrast {
    .mod-finder {
        .mod-link,
        .mod-teaser {
            outline: 2px solid $black !important;
            background: $finder-elements-bg;
            &:hover {
                background: $finder-elements-bg-hover !important;
            }
        }

        .mod-link strong,
        .mod-teaser {
            color: $black;
        }

        .tx-frontendlist-pi-selectorbox {
            background: $white;

            span:after {
                border-color: $black;
                color: $black;
            }
        }
        &.padding-bottom .tx-frontendlist-pi-searchbox-wrap input {
            border: 0 !important;
        }

        .tx-frontendlist-pi-selectorbox:hover {
            outline: 1px solid $black;
        }
    }

    /*CONTRAST COLORS OF AREAS -- JUST FOR DEVELOPMENT*/
    &.color-pattern-1 .color-pattern-2 .mod-finder {
        background: nth($color-pattern-list-contrast, 2) !important;
        ul li a {
            border-color: nth($color-pattern-list-contrast, 2) !important;

            &:before {
                color: nth($color-pattern-list-contrast, 2) !important;
            }

            &:after {
                background: nth($color-pattern-list-contrast, 2) !important;
            }
        }
        input.submit {
            background: nth($color-pattern-list-contrast, 2) !important;
        }
        button:after {
            background: nth($color-pattern-list-contrast, 2) !important;
        }
    }
    &.color-pattern-1 .color-pattern-3 .mod-finder {
        background: nth($color-pattern-list-contrast, 3) !important;

        ul li a {
            border-color: nth($color-pattern-list-contrast, 3) !important;

            &:before {
                color: nth($color-pattern-list-contrast, 3) !important;
            }

            &:after {
                background: nth($color-pattern-list-contrast, 3) !important;
            }
        }
        input.submit {
            background: nth($color-pattern-list-contrast, 3) !important;
        }
        button:after {
            background: nth($color-pattern-list-contrast, 3) !important;
        }
    }
    &.color-pattern-1 .color-pattern-4 .mod-finder {
        background: nth($color-pattern-list-contrast, 4) !important;
        ul li a {
            border-color: nth($color-pattern-list-contrast, 4) !important;

            &:before {
                color: nth($color-pattern-list-contrast, 4) !important;
            }

            &:after {
                background: nth($color-pattern-list-contrast, 4) !important;
            }
        }
        input.submit {
            background: nth($color-pattern-list-contrast, 4) !important;
        }
        button:after {
            background: nth($color-pattern-list-contrast, 4) !important;
        }
    }
    &.color-pattern-1 .color-pattern-5 .mod-finder {
        background: nth($color-pattern-list-contrast, 5) !important;
        ul li a {
            border-color: nth($color-pattern-list-contrast, 5) !important;

            &:before {
                color: nth($color-pattern-list-contrast, 5) !important;
            }

            &:after {
                background: nth($color-pattern-list-contrast, 5) !important;
            }
        }
        input.submit {
            background: nth($color-pattern-list-contrast, 5) !important;
        }
        button:after {
            background: nth($color-pattern-list-contrast, 5) !important;
        }
    }
    &.color-pattern-1 .color-pattern-6 .mod-finder {
        background: nth($color-pattern-list-contrast, 6) !important;
        ul li a {
            border-color: nth($color-pattern-list-contrast, 6) !important;

            &:before {
                color: nth($color-pattern-list-contrast, 6) !important;
            }

            &:after {
                background: nth($color-pattern-list-contrast, 6) !important;
            }
        }
        input.submit {
            background: nth($color-pattern-list-contrast, 6) !important;
        }
        button:after {
            background: nth($color-pattern-list-contrast, 6) !important;
        }
    }
}

.color-pattern-3 .mod-finder ul li:first-child a:before {
    @extend .icon-basic-study;
}

.color-pattern-4 .mod-finder ul li:first-child a:before {
    @extend .icon-career;
}

.color-pattern-5 .mod-finder ul li:first-child a:before {
    @extend .icon-charite;
}

/*** finder result ***/
.color-pattern-1 .finder-result .mod-finder,
.color-pattern-2 .finder-result .mod-finder,
.color-pattern-3 .finder-result .mod-finder,
.color-pattern-4 .finder-result .mod-finder,
.color-pattern-5 .finder-result .mod-finder,
.color-pattern-6 .finder-result .mod-finder,
.color-pattern-7 .finder-result .mod-finder,
.color-pattern-8 .finder-result .mod-finder,
.color-pattern-9 .finder-result .mod-finder,
.finder-result .mod-finder > div {
    background: #f6f3ed;
    padding: 40px 5px 0 5px; /** media query!! **/
    margin-bottom: 40px;
}

.finder-result {
    .mod-finder legend {
        font-size: 2rem; /** media query!! **/
        font-weight: 300;
    }

    .mod-finder .sub-legend {
        display: block;
        clear: both;
        margin-bottom: 2em;
    }

    .tx-indexedsearch-browsebox {
        margin-left: 10px;
        margin-right: 10px;
    }
    .container {
        > p, > ol, > ul {
            @include respond-min($breakpoint-max-l) {
                max-width: inherit;
            }
        }
    }
}

.wrapper .mod-finder .tx-frontendlist-pi-searchbox-wrap .btn-lupe {
    right: 10px;
    left: auto;
    width: 30px;
    height: 30px;
    margin-right: 4px;
    top: 10px;
}

.wrapper .mod-finder .tx-frontendlist-pi-searchbox-wrap .btn-lupe::after {
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
}

.project {
    .mod-finder {
        width: auto;

        // RV  this doesn't make sense on subpages
        margin: $grid-gutter-width $grid-gutter-width/2 0 $grid-gutter-width/2;
        @include respond-min($breakpoint-min-s) {
            margin: $grid-gutter-width $grid-gutter-width 0 $grid-gutter-width;
        }
    }

    .homepage,
    .be-layout-stage-only-image {
        .mod-finder {
            margin-top: -20px;

            @include respond-min($breakpoint-min-s) {
                margin-top: -80px;
            }

            @include respond-min($breakpoint-min-m) {
                margin-top: -105px;
            }
        }
    }
}

