@charset "UTF-8";

/* mod-mail */

.mail,
.download {
    text-decoration: underline;
    word-wrap: normal;
    white-space: nowrap;
}

.mail {
    margin-right: 20px;
    position: relative;
    -webkit-hyphens: none;

    &:after {
        content: "\e620";
        font-family: charite-icons;
        color: inherit;
        text-decoration: none !important;
        vertical-align: top;
        position: absolute;
        margin-left: 2px;
        font-size: 20px;
        top: -5px;
        text-indent: 0;
    }
}
