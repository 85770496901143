@charset "UTF-8";

/* mod-google-search */
/* THOSE ARE INSERTED DIRECTLY INTO main.css TODO remove there*/

.gsc-control-cse {
    /*
    border: 1px solid orange !important;
    */
    font-size: 16px;
    font-size: 1rem;
}

.gsc-control-cse, .gsc-control-cse * {
    font-family: 'Roboto', sans-serif !important;
}

.cse .gsc-control-cse, .gsc-control-cse {
    padding: 0 !important;
}

.cse form.gsc-search-box, form.gsc-search-box {
    padding: 0 !important;
}

.gsc-table-cell-thumbnail, .gs-promotion-image-cell {
    padding: 0 !important;
}

.cse table.gsc-search-box td.gsc-input,
table.gsc-search-box td.gsc-input {
    padding-right: 0 !important;
}

.gsc-cursor-page {
    font-size: 16px !important;
}

.mod-google-search .gsc-results .gsc-cursor-box .gsc-cursor-current-page {
    padding: 0;
    border-radius: 15px;
    box-sizing: border-box;
    display: inline-block;
    text-align: center;
    background: #a0a5a9;
    color: #fff;
    line-height: 25px;
    height: 25px;
    width: 25px;
}

.gsc-tabHeader,
.gsc-selected-option-container,
input.gsc-search-button {
    font-size: 13px !important;
}

.cse .gsc-tabHeader, .gsc-tabHeader {
    padding: 4px 16px 2px 16px !important;
}

.gs-no-results-result .gs-snippet {
    padding: 4px 8px !important;
    margin: 5px 0 !important;
}

.cse .gsc-webResult.gsc-result,
.gsc-webResult.gsc-result,
.gsc-imageResult-classic,
.gsc-imageResult-column {
    padding-left: 0 !important;
}

.gsc-search-button input {
    width: auto !important;
}

.gsc-result-info {
    margin-left: 0 !important;
    padding-left: 0 !important;
}

.cse .gsc-webResult.gsc-result,
.gsc-webResult.gsc-result,
.gsc-imageResult-classic,
.gsc-imageResult-column {
    margin-bottom: 2em !important;
}

.gs-web-image-box,
.gs-promotion-image-box {
    padding-right: 0 !important;
}

.gsc-table-cell-thumbnail,
.gs-promotion-image-cell {
    padding-right: 10px !important;
}

.contentWrap .gs-webResult.gs-result a.gs-title,
.contentWrap .gs-webResult.gs-result a.gs-title:link,
.contentWrap .gs-webResult.gs-result a.gs-title:hover,
.contentWrap .gs-webResult.gs-result a.gs-title b,
.contentWrap .gs-webResult.gs-result a.gs-title:link b,
.contentWrap .gs-webResult.gs-result a.gs-title:hover b {
    color: #005EBB;
    color: black;
}

.contentWrap .gs-webResult.gs-result a.gs-title:visited,
.contentWrap .gs-webResult.gs-result a.gs-title:visited b {
    color: #4593D1;
    color: black;
}

.cse .gsc-webResult.gsc-result,
.gsc-webResult.gsc-result,
.gsc-webResult.gsc-result.gsc-promotion,
.gsc-results .gsc-imageResult-classic,
.gsc-results .gsc-imageResult-column {
    padding-left: 3px !important;
}

.gs-no-results-result {
    margin-left: -3px !important;
}

.cse .gsc-webResult.gsc-result:hover,
.gsc-webResult.gsc-result:hover,
.gsc-webResult.gsc-result.gsc-promotion:hover,
.gsc-results .gsc-imageResult-classic:hover,
.gsc-results .gsc-imageResult-column:hover {
    border-left: 1px solid;
    border-color: #000000;
    border-bottom: 0 solid;
}

.gsc-selected-option-container {
    max-width: 100% !important;
}

.gsc-selected-option-container {
    max-width: 100% !important;
}

.cse .gsc-results .gsc-cursor-page.gsc-cursor-current-page,
.gsc-results .gsc-cursor-page.gsc-cursor-current-page {
    text-shadow: none !important;
}
