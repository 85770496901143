@charset "UTF-8";

/* mod-glossar */

.mod-glossar {
    @extend .unstyled;

    li {
        margin: 1.5em 0;
    }

    h3 {
        margin: 0;
        font-size: 1.4rem;
        font-weight: 100;
    }

    span {
        font-style: italic;
    }
}

// Firefox fix
acronym,
abbr,
dfn {
    text-decoration: none;
}

.glossary,
.glossary * {
    text-decoration: none !important;
}

// .highlighted background color is the same as standard abbr color
.highlighted abbr {
    background-color: darken($main-color, 5%);
}