@charset "UTF-8";

/* mod-stage-slider */

@each $color in $color-pattern-list {
    $index: index($color-pattern-list, $color);
    $contrast-color: nth($color-pattern-list-contrast, $index);

    .color-pattern-#{$index} .mod-stage-slider .flex-control-nav a,
    .color-pattern-#{$index} .slide-content .sliderIcon {
        border-color: $color;
    }

    .color-pattern-#{$index} .slide-content .sliderIcon,
    .color-pattern-#{$index} .slide-content h2 {
        color: $color;
    }

    .color-pattern-#{$index} .mod-stage-slider .flex-control-nav a.flex-active {
        background: $color;
    }

    .color-pattern-contrast.color-pattern-#{$index} .mod-stage-slider .flex-control-nav a,
    .color-pattern-contrast.color-pattern-#{$index} .slide-content .sliderIcon {
        border-color: $contrast-color;
    }

    .color-pattern-contrast.color-pattern-#{$index} .slide-content .sliderIcon,
    .color-pattern-contrast.color-pattern-#{$index} .slide-content h2 {
        color: $contrast-color;
    }

    .color-pattern-contrast.color-pattern-#{$index} .mod-stage-slider .flex-control-nav a.flex-active {
        background: $contrast-color;
    }
}

.mod-stage-slider {
    margin-top: 75px;
    max-width: (940 / $body-font-size-px) + em;

    .slides {

        > li {
            position: relative;
        }

        > li:first-child {
            /* all li's are now display none by inline style and to prevent zapping in while loading we unhide the first one*/
            display:block !important;
        }

        img {
            width: 100%;
        }
    }

    @include respond-min($breakpoint-min-xxl) {
        max-width: (940 / $body-font-size-px) + em;
    }

    .flex-direction-nav a {

        &:before {
            color: #fff;
        }
    }

    .flex-control-nav {

        a {
            background: $white;
            border: solid 2px $default-color;

            &.flex-active {
                border-color: $white !important;
            }
        }
    }
}

.slide-content {
    background: $white;
    padding: 0 20px 20px;
    min-height: 150px;
    backface-visibility: hidden;

    .information-visualization {
        height: 250px;
        float: left;
        backface-visibility: hidden;
    }

    h2 {
        max-width: 60%;
        word-break: break-word;
        display: inline-block;
        position: relative;
    }

    a {
        display: block;
    }

    .sliderIcon {
        @extend %icon-font;
        @include icon-circle(80);
        @include fontSizeREM(50);
        padding: 5px 5px 5px 7px;
        margin: 20px 20px 50px 0;
        display: block;
        position: relative;
        opacity: 1;
        text-align: center;
        float: left;
        overflow: hidden;
        backface-visibility: hidden;

        &:before {
            @include fontSizeREM(40);
            top: -25px;
            text-align: center;
            position: relative;
            margin-bottom: 20px;
            backface-visibility: hidden;
        }

        &:after {
            display: none;
        }

        &:not([class*="icon-"]) p {
            top: -5px;
        }

        p {
            @include fontSizeREM(18);
            font-family: 'Roboto', sans-serif;
            font-weight: 100;
            letter-spacing: -1px;
            top: -70px;
            position: relative;
            left: 0;
            width: 100%;
            text-align: center;
            padding-right: 5px;
            height: 30px;
        }
    }

    .only-icon-graphic:before {
        top: -10px;
    }

    a {
        text-decoration: underline;
        margin-bottom: 20px;

        &:hover {
            text-decoration: none;
        }
    }

    h2 {
        line-height: 30px;
    }
}

.mod-stage-slider .slider-image {
    background-size: cover;
    background-position: center center;
    max-height: 420px;
    text-indent: -999em;

    /* phone */
    @include respond-min($breakpoint-min-xs) {
        height: 190px;
    }

    /* phone large */
    @include respond-min($breakpoint-min-s) {
        height: 240px;
    }

    /* tablet */
    @include respond-min($breakpoint-min-m) {
        height: 350px;
    }

    /* desktop */
    @include respond-min($breakpoint-min-l) {
        height: 420px

    }
}

/* min Iphone 5 Landscape*/
@media (min-width: 567px) {

    .mod-stage-slider {
        h2 {
            margin: $grid-gutter-width*.5 0 $grid-gutter-width 0;
        }
    }

    .slide-content {
        width: 450px;
        position: absolute;
        padding: $grid-gutter-width * .1 $grid-gutter-width $grid-gutter-width * 1.5 $grid-gutter-width;
        bottom: $grid-gutter-width * 1.5;
        right: $grid-gutter-width * 3;
        max-height: 200px;
        overflow: hidden;

        .information-visualization {
            height: 200px;
            overflow: hidden;
            float: left;
        }
    }

    .categroySlider {
        width: 450px;
        position: absolute;
        padding: $grid-gutter-width * .1 $grid-gutter-width $grid-gutter-width*.5 $grid-gutter-width*.1;
        bottom: $grid-gutter-width * 1.5;
        right: $grid-gutter-width;
        display: inline-block;
        float: left;

        .sliderIcon {
            @include icon-circle(150);
            margin: $grid-gutter-width*1.2 0 10px 10px;

            &:before {
                @include fontSizeREM(70);
                top: -40px;
            }

            p {
                @include fontSizeREM(40);
                letter-spacing: -2px;
                top: -140px;
            }
        }

        .only-icon-graphic:before {
            top: -10px;
        }

        h2,
        p,
        a {
            width: 250px;
            float: right;
        }
    }
}

/* desktop */
@include respond-min($breakpoint-min-l) {
    .mod-stage-slider {
        margin-top: 0;
        h2 {
            margin-top: 0;
        }
    }

    .slide-content {
        max-height: 250px;
        padding-top: $grid-gutter-width;
        right: $grid-gutter-width * 3.5;
    }

    .categroySlider {
        padding: $grid-gutter-width $grid-gutter-width $grid-gutter-width*.5 $grid-gutter-width*.6;

        .sliderIcon {
            @include icon-circle(180);
            margin: 5px 5px 5px 0;

            &:before {
                top: -50px;
            }

            p {
                @include fontSizeREM(45);
                top: -160px;
            }
        }

        .only-icon-graphic:before {
            top: -7px;
        }

        h2 {
            margin: 0 0 $grid-gutter-width 0;
        }

        h2,
        p,
        a {
            width: 220px;
        }
    }
}

/* desktop large */
@include respond-min($breakpoint-min-xl) {

    .mod-stage-slider .slides {

        > li {
            max-height: 420px;
            height: 420px;
        }
    }
}

.mod-stage-slider {
    &.stage-contact-clinic .frontEndEditIconLinks img,
    &.stage-persona .frontEndEditIconLinks img,
    .slides .frontEndEditIconLinks img {
        width: 16px;
        height: 16px;
    }
}

/* remove margin to prevent flickering of stage slider when loading the page */
.no-js .mod-stage-slider .flexslider .slides li {
    margin-bottom: 0;
}