@charset "UTF-8";

/* helpers */

%clearfix {
    display: block;
    content: ".";
    clear: both;
    font-size: 0;
    line-height: 0;
    height: 0;
    overflow: hidden;
}

.clearfix {
    @extend %clearfix;
}

.unstyled {
    margin: 0;
    padding: 0;
    list-style: none;
}

.left {
    display: inline;
    float: left;
}

.right {
    display: inline;
    float: right;
}

.center {
    display: block;
    margin: 0 auto;
    text-align: center;
}

.clear {
    clear: both;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.no-margin {
    margin: 0;
}

.margin-top-60 {
    margin-top: 60px;
}

.show-grid {
    [class*="col-"] {
        //outline: 1px solid red;
    }
}

@include respond-max($breakpoint-max-s) {
    .mobile-hide {
        display: none;
    }
}

.hidden {
    display: none !important;
}

/*
     color-pattern html-classes
    */
/* background color */

// mod-teaser with contrast in Highlighted Areas
.color-pattern-1.color-pattern-contrast .highlighted .mod-teaser:not([class*="from-"]) {
    background: nth($color-pattern-list-contrast, 1) !important;
}

.color-pattern-2.color-pattern-contrast .highlighted .mod-teaser:not([class*="from-"]) {
    background: nth($color-pattern-list-contrast, 2) !important;
}

.color-pattern-3.color-pattern-contrast .highlighted .mod-teaser:not([class*="from-"]) {
    background: nth($color-pattern-list-contrast, 3) !important;
}

.color-pattern-4.color-pattern-contrast .highlighted .mod-teaser:not([class*="from-"]) {
    background: nth($color-pattern-list-contrast, 4) !important;
}

.color-pattern-5.color-pattern-contrast .highlighted .mod-teaser:not([class*="from-"]) {
    background: nth($color-pattern-list-contrast, 5) !important;
}

.color-pattern-6.color-pattern-contrast .highlighted .mod-teaser:not([class*="from-"]) {
    background: nth($color-pattern-list-contrast, 6) !important;
}

.color-pattern-7.color-pattern-contrast .highlighted .mod-teaser:not([class*="from-"]) {
    background: nth($color-pattern-list-contrast, 7) !important;
}

.color-pattern-8.color-pattern-contrast .highlighted .mod-teaser:not([class*="from-"]) {
    background: nth($color-pattern-list-contrast, 8) !important;
}

.color-pattern-9.color-pattern-contrast .highlighted .mod-teaser:not([class*="from-"]) {
    background: nth($color-pattern-list-contrast, 9) !important;
}

// Clinic
.from-clinic-bg {
    background: nth($color-pattern-list, 1) !important;
    @extend %color-override-white;
    transition: all ease-in-out .2s;

    // inverse icons
    &.mod-teaser[class*="icon-"],
    [class*="icon-"]:not(.icon-inversed) {
        &:before {
            color: nth($color-pattern-list, 1) !important;
            background: $white !important;
        }
    }

    @media only screen and (min-width: $breakpoint-min-m) {
        &:hover {
            background: #007f58 !important;
        }
    }
}

.color-pattern-contrast .from-clinic-bg {
    background: nth($color-pattern-list-contrast, 1) !important;
    transition: all ease-in-out .2s;

    // inverse icons
    &.mod-teaser[class*="icon-"],
    [class*="icon-"]:not(.icon-inversed) {
        &:before {
            color: nth($color-pattern-list-contrast, 1) !important;
        }
    }

    @media only screen and (min-width: $breakpoint-min-m) {
        &:hover {
            background: #007f58 !important;
        }
    }
}

// Research
.from-research-bg {
    background: nth($color-pattern-list, 2) !important;
    @extend %color-override-white;
    transition: all ease-in-out .2s;

    // inverse icons
    &.mod-teaser[class*="icon-"],
    [class*="icon-"]:not(.icon-inversed) {
        &:before {
            color: nth($color-pattern-list, 2) !important;
            background: $white !important;
        }
    }

    @media only screen and (min-width: $breakpoint-min-m) {
        &:hover {
            background: #20629a !important;
        }
    }
}

.color-pattern-contrast .from-research-bg {
    background: nth($color-pattern-list-contrast, 2) !important;
    transition: all ease-in-out .2s;

    // inverse icons
    &.mod-teaser[class*="icon-"],
    [class*="icon-"]:not(.icon-inversed) {
        &:before {
            color: nth($color-pattern-list-contrast, 2) !important;
        }
    }

    @media only screen and (min-width: $breakpoint-min-m) {
        &:hover {
            background: #20629a !important;
        }
    }
}

// Study
.from-study-bg {
    background: nth($color-pattern-list, 3) !important;
    @extend %color-override-white;
    transition: all ease-in-out .2s;

    // inverse icons
    &.mod-teaser[class*="icon-"],
    [class*="icon-"]:not(.icon-inversed) {
        &:before {
            color: nth($color-pattern-list, 3) !important;
            background: $white !important;
        }
    }

    @media only screen and (min-width: $breakpoint-min-m) {
        &:hover {
            background: #8f2a57 !important;
        }
    }
}

.color-pattern-contrast .from-study-bg {
    background: nth($color-pattern-list-contrast, 3) !important;
    transition: all ease-in-out .2s;

    // inverse icons
    &.mod-teaser[class*="icon-"],
    [class*="icon-"]:not(.icon-inversed) {
        &:before {
            color: nth($color-pattern-list-contrast, 3) !important;
        }
    }

    @media only screen and (min-width: $breakpoint-min-m) {
        &:hover {
            background: #8f2a57 !important;
        }
    }
}

// Career
.from-career-bg {
    background: nth($color-pattern-list, 4) !important;
    @extend %color-override-white;
    transition: all ease-in-out .2s;

    // inverse icons
    &.mod-teaser[class*="icon-"],
    [class*="icon-"]:not(.icon-inversed) {
        &:before {
            color: nth($color-pattern-list, 4) !important;
            background: $white !important;
        }
    }

    @media only screen and (min-width: $breakpoint-min-m) {
        &:hover {
            background: #817159 !important;
        }
    }
}

.color-pattern-contrast .from-career-bg {
    background: nth($color-pattern-list-contrast, 4) !important;
    transition: all ease-in-out .2s;

    // inverse icons
    &.mod-teaser[class*="icon-"],
    [class*="icon-"]:not(.icon-inversed) {
        &:before {
            color: nth($color-pattern-list-contrast, 4) !important;
        }
    }

    @media only screen and (min-width: $breakpoint-min-m) {
        &:hover {
            background: #817159 !important;
        }
    }
}

// International
.from-international-bg {
    background: nth($color-pattern-list, 5) !important;
    @extend %color-override-white;
    transition: all ease-in-out .2s;

    // inverse icons
    &.mod-teaser[class*="icon-"],
    [class*="icon-"]:not(.icon-inversed) {
        &:before {
            color: nth($color-pattern-list, 5) !important;
            background: $white !important;
        }
    }

    @media only screen and (min-width: $breakpoint-min-m) {
        &:hover {
            background: #ae902b !important;
        }
    }
}

.color-pattern-contrast .from-international-bg {
    background: nth($color-pattern-list-contrast, 5) !important;
    transition: all ease-in-out .2s;

    // inverse icons
    &.mod-teaser[class*="icon-"],
    [class*="icon-"]:not(.icon-inversed) {
        &:before {
            color: nth($color-pattern-list-contrast, 5) !important;
        }
    }

    @media only screen and (min-width: $breakpoint-min-m) {
        &:hover {
            background: #ae902b !important;
        }
    }
}

// Charité
.from-charite-bg {
    background: nth($color-pattern-list, 6) !important;
    @extend %color-override-white;
    transition: all ease-in-out .2s;

    // inverse icons
    &.mod-teaser[class*="icon-"],
    [class*="icon-"]:not(.icon-inversed) {
        &:before {
            color: nth($color-pattern-list, 6) !important;
            background: $white !important;
        }
    }

    @media only screen and (min-width: $breakpoint-min-m) {
        &:hover {
            background: #828485 !important;
        }
    }
}

.color-pattern-contrast .from-charite-bg {
    background: nth($color-pattern-list-contrast, 6) !important;
    transition: all ease-in-out .2s;

    // inverse icons
    &.mod-teaser[class*="icon-"],
    [class*="icon-"]:not(.icon-inversed) {
        &:before {
            color: nth($color-pattern-list-contrast, 6) !important;
        }
    }

    @media only screen and (min-width: $breakpoint-min-m) {
        &:hover {
            background: #828485 !important;
        }
    }
}

// Contact is missing cause unused

// YOU ARE SPECIAL
.from-emergency-bg {
    background: $emergency-color !important;
    @extend %color-override-white;
    transition: all ease-in-out .2s;

    @media only screen and (min-width: $breakpoint-min-m) {
        &:hover {
            background: #ae1a18 !important;
        }
    }
}

// Service
.from-service-bg {
    background: nth($color-pattern-list, 8) !important;
    @extend %color-override-white;
    transition: all ease-in-out .2s;

    // inverse icons
    &.mod-teaser[class*="icon-"],
    [class*="icon-"]:not(.icon-inversed) {
        &:before {
            color: nth($color-pattern-list, 8) !important;
            background-color: $white !important;
        }
    }

    @media only screen and (min-width: $breakpoint-min-m) {
        &:hover {
            background: #139491 !important;
        }
    }
}

.color-pattern-contrast .from-service-bg {
    background: nth($color-pattern-list-contrast, 8) !important;
    transition: all ease-in-out .2s;

    // inverse icons
    &.mod-teaser[class*="icon-"],
    [class*="icon-"]:not(.icon-inversed) {
        &:before {
            color: nth($color-pattern-list-contrast, 8) !important;
        }
    }

    @media only screen and (min-width: $breakpoint-min-m) {
        &:hover {
            background: #006666 !important;
        }
    }
}

// BIH
.from-bih-bg {
    background: nth($color-pattern-list, 9) !important;
    @extend %color-override-white;
    transition: all ease-in-out .2s;

    // inverse icons
    &.mod-teaser[class*="icon-"],
    [class*="icon-"]:not(.icon-inversed) {
        &:before {
            color: nth($color-pattern-list, 9) !important;
            background-color: $white !important;
        }
    }

    @media only screen and (min-width: $breakpoint-min-m) {
        &:hover {
            /* TODO BIH Link color Julianes proposal */
            background: #5A96AA !important;

        }
    }
}

.color-pattern-contrast .from-bih-bg {
    background: nth($color-pattern-list-contrast, 9) !important;
    transition: all ease-in-out .2s;

    // inverse icons
    &.mod-teaser[class*="icon-"],
    [class*="icon-"]:not(.icon-inversed) {
        &:before {
            color: nth($color-pattern-list-contrast, 9) !important;
        }
    }

    @media only screen and (min-width: $breakpoint-min-m) {
        &:hover {
            /* TODO BIH Link color UNDONE */
            background: #006666 !important;
        }
    }
}


/* font color */

// Clinic
.from-clinic-text,
.from-clinic-text:hover {
    color: nth($color-pattern-list, 1) !important;
}

.color-pattern-contrast .from-clinic-text,
.color-pattern-contrast .from-clinic-text:hover {
    color: nth($color-pattern-list-contrast, 1) !important;
}

// Research
.from-research-text,
.from-research-text:hover {
    color: nth($color-pattern-list, 2) !important;
}

.color-pattern-contrast .from-research-text,
.color-pattern-contrast .from-research-text:hover {
    color: nth($color-pattern-list-contrast, 2) !important;
}

// Study
.from-study-text,
.from-study-text:hover {
    color: nth($color-pattern-list, 3) !important;
}

.color-pattern-contrast .from-study-text,
.color-pattern-contrast .from-study-text:hover {
    color: nth($color-pattern-list-contrast, 3) !important;
}

// Career
.from-career-text,
.from-career-text:hover {
    color: nth($color-pattern-list, 4) !important;
}

.color-pattern-contrast .from-career-text,
.color-pattern-contrast .from-career-text:hover {
    color: nth($color-pattern-list-contrast, 4) !important;
}

// International
.from-international-text,
.from-international-text:hover {
    color: nth($color-pattern-list, 5) !important;
}

.color-pattern-contrast .from-international-text,
.color-pattern-contrast .from-international-text:hover {
    color: nth($color-pattern-list-contrast, 5) !important;
}

// Charité
.from-charite-text,
.from-charite-text:hover {
    color: nth($color-pattern-list, 6) !important;
}

.color-pattern-contrast .from-charite-text,
.color-pattern-contrast .from-charite-text:hover {
    color: nth($color-pattern-list-contrast, 6) !important;
}

// Contact is missing cause unused

// Service
.from-service-text,
.from-service-text:hover {
    color: nth($color-pattern-list, 8) !important;
}

.color-pattern-contrast .from-service-text,
.color-pattern-contrast .from-service-text:hover {
    color: nth($color-pattern-list-contrast, 8) !important;
}

// BIH
.from-bih-text,
.from-bih-text:hover {
    color: nth($color-pattern-list, 9) !important;
}

.color-pattern-contrast .from-bih-text,
.color-pattern-contrast .from-bih-text:hover {
    color: nth($color-pattern-list-contrast, 9) !important;
}


%improve-animation {
    backface-visibility: hidden;
}

// generate color-label for navigation-item and mod-header-infolinks
%nav-color-label {

    &:before {
        @extend %icon-font;
        content: "\e61e";
        font-size: $icon-font-size;
        position: absolute;
        right: $grid-gutter-width;
        top: 60%;
        margin-top: -18px;
    }

    &:after {
        @extend %icon-font;
        transition: width .2s ease;
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 15px;
    }

    &:hover {

        &:after {
            width: 20px;
        }
    }
}

// override color
%color-override-white {
    color: #fff !important;

    &:before {
        border-color: #fff !important;
    }
}

// button keyboard
%keyboard {
    background: $grey;
    opacity: 0.8;
    box-shadow: 3px 3px 0 rgba($grey-dark, .7);
    border-radius: 5px;
    color: $white;
    display: inline-block;
    margin: 0 .2em;
    padding: .5em;
}
