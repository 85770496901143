@charset "UTF-8";

/* mod-teaser-list */

@each $color in $color-pattern-list {
    $index: index($color-pattern-list, $color);
    $contrast-color: nth($color-pattern-list-contrast, $index);

    .color-pattern-#{$index} .mod-teaser-list h3 {
        background-color: $color;

        &:hover {
            background: darken($color, 8%);
        }
    }

    .color-pattern-#{$index} .mod-teaser-list article div a {
        color: $color;
    }

    .color-pattern-contrast.color-pattern-#{$index} .mod-teaser-list h3 {
        background-color: $contrast-color;

        &:hover {
            background: lighten($contrast-color, 6%);
        }
    }

    .color-pattern-contrast.color-pattern-#{$index} .mod-teaser-list article div a {
        color: $contrast-color;
    }
}

.mod-teaser-list {

    h3,
    article div {
        padding: $grid-gutter-width;
    }

    h3 {
        color: $white;
        display: block;
        // min-height: 150px;
        min-height: 200px;
        margin: 0;
    }

    p {
        font-weight: 400;
    }

    .row {
        margin: 0 -10px;
    }

    article {
        margin: 0 0 1em 0;

        div {
            background: $main-color;
            position: relative;

            &:after {
                @extend %clearfix;
            }

            ul {
                margin-bottom: 2em;

                li a {
                    display: block;
                    padding: .4em 0;
                }
            }

            a {
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }

            > a {
                position: absolute;
                bottom: 1em;
                right: 1em;
            }
        }
    }

    @include respond-min($breakpoint-min-m) {
        margin: 0 0 1em 0;

        article {
            margin: 0 0 20px 0;
        }
    }
}

// Contrast-Styles
.color-pattern-contrast .mod-teaser-list article div {
    background: $white;
    color: $black;
    border: 1px solid;
}

/* or else boxes without keywords are too low */
.container .mod-teaser-list article div {
    padding: 25px 20px 25px 20px;
}