@charset "UTF-8";

/* mod-meta-navigation */

@each $color in $color-pattern-list {
    $index: index($color-pattern-list, $color);
    $contrast-color: nth($color-pattern-list-contrast, $index);

    .color-pattern-#{$index} .mod-meta-navigation {

        .flyout-content {
            border: 1px solid $color;
        }

        .fadeInUp:before {
            border-bottom-color: $color;
        }
    }

    .color-pattern-#{$index} .mod-meta-navigation .contact > a:before,
    .color-pattern-#{$index} .mod-meta-navigation .accessibility > a:before,
    .color-pattern-#{$index} .mod-meta-navigation .language > a span,
    .color-pattern-#{$index} .mod-meta-navigation .accessibility .flyout-content .switch.active {
        background-color: $white;
        color: $color;
    }

    .color-pattern-#{$index} .meta-list,
    .color-pattern-#{$index} .mod-meta-navigation .contact .flyout-content .icon-phone:before,
    .color-pattern-#{$index} .mod-meta-navigation .contact .flyout-content .icon-mail:before,
    .color-pattern-#{$index} .mod-meta-navigation .contact .flyout-content .icon-locations:before,
    .color-pattern-#{$index} .mod-meta-navigation .contact .flyout-content .icon-appointment-videochat:before,
    .color-pattern-#{$index} .mod-meta-navigation .contact .flyout-content .icon-news:before {
        background-color: $color;
    }

    .color-pattern-contrast.color-pattern-#{$index} .mod-meta-navigation .contact > a:before,
    .color-pattern-contrast.color-pattern-#{$index} .mod-meta-navigation .accessibility > a:before,
    .color-pattern-contrast.color-pattern-#{$index} .mod-meta-navigation .language > a span {
        color: $contrast-color;
    }
    .color-pattern-contrast.color-pattern-#{$index} .mod-meta-navigation .accessibility .flyout-content .switch.active {
        background-color: $black;
    }

    .color-pattern-contrast.color-pattern-#{$index} .meta-list {
        background-color: $contrast-color;
    }
    .color-pattern-contrast.color-pattern-#{$index} .mod-meta-navigation .contact .flyout-content .icon-phone:before,
    .color-pattern-contrast.color-pattern-#{$index} .mod-meta-navigation .contact .flyout-content .icon-mail:before,
    .color-pattern-contrast.color-pattern-#{$index} .mod-meta-navigation .contact .flyout-content .icon-locations:before,
    .color-pattern-contrast.color-pattern-#{$index} .mod-meta-navigation .contact .flyout-content .icon-appointment-videochat:before,
    .color-pattern-contrast.color-pattern-#{$index} .mod-meta-navigation .contact .flyout-content .icon-news:before {
        background: $contrast-color;
    }
}

/* max-width and global Position of Meta-Navi: Start */
.max-width {
    max-width: 921px;
    margin: 0;
    position: relative;

    @media (min-width: 1180px) {
        .mod-meta-navigation {
            left: 805px;
            right: auto;
        }

        .mod-meta-navigation.space-for-search {
            left: 742px;
        }

        .mod-meta-navigation.active {
            left: 785px;
        }
    }

    @include respond-min($breakpoint-min-l) {
        .btn-meta-menu {
            display: none;
        }
    }
}

/* max-width and global Position of Meta-Navi: End */

/* mod-meta-navigation: Start */
.mod-meta-navigation {
    position: fixed;
    width: 100.5%;
    max-width: 414px;
    left: -1px;
    padding-right: 1px;
    z-index: 14;
    font-size: 18px;
    top: 77px;

    &.active {
        transform: translateX(0%);
    }

    /* Meta-Navigation-Bar (without Flyout): Start */
    > ul {
        margin: 0;
        padding: 0;
        list-style: none;
        overflow: hidden;
        @include respond-min($breakpoint-min-l) {
            float: right;
        }

        /* first ul content: emergency, contact, accessibility, language */
        &.meta-list {
            padding-left: 1px;
            > li {
                float: left;
                width: 25%;
            }
        }
    }

    > ul > li {
        float: right;
        padding: $grid-gutter-width/2 $grid-gutter-width/4 $grid-gutter-width/2 $grid-gutter-width/2;

        > a, > span {
            font-size: 0;
            display: block;
            height: 41px;
            position: relative;
            text-align: center;

            &:before,
            &:after {
                @extend %icon-font;
            }

            &:before {
                @include icon-background(40);
                @include fontSizeREM(40);
            }
        }
    }
    /* Meta-Navigation-Bar (without Flyout): End */

    /* ICONS Meta-Navigation-Bar : Start */
    .contact > a,
    .accessibility > a {

        &:after {
            transition: all ease .3s;
            @extend .icon-down;
            float: right;
            margin: 15px 0 0 5px;
            color: $white;
        }
    }

    //language-icon
    .language {
        > a {
            span {
                @include icon-background(40);
                @include fontSizeREM();
                text-decoration: none;
                font-weight: 500;
            }

            &:after {
                transition: all ease .3s;
                @extend .icon-down;
                margin: 15px 0 0 5px;
                float: right;
                color: $white;
            }
        }
    }

    .btn-active:after {
        transform: rotate(-180deg);
    }

    //contact-icon
    .contact > a:before {
        @extend .icon-mail;
    }

    //accessibility-icon
    .accessibility > a:before {
        @extend .icon-accessible;
    }

    //emergency-icon
    .emergency {
        padding-right: $grid-gutter-width/2;

        span {
            font-size: 0;
            display: block !important;
            text-decoration: none;

            &:before {
                @extend %icon-font;
                @extend .icon-ambulant_service;
                @include icon-background(40);
                @include fontSizeREM(40);
                background: $emergency-color !important;
                float: right;
                margin-right: 25%;
                vertical-align: -30%;
            }

            &:after {
                display: none !important;
            }
        }

        @include respond-min($breakpoint-min-l) {
            span:before {
                margin-right: 0;
            }
        }
    }

    //search-icon
    .search {
        padding: $grid-gutter-width*.5 0;
        width: 100%;
    }
    /* ICONS Meta-Navigation-Bar : End */

    /* Flyouts : Start */
    .flyout {
        position: absolute;
        width: 100.5%;
        top: 61px;
        right: 0;
        left: -1px;
        padding-top: 13px;
        visibility: hidden;
        z-index: 12;
    }

    .flyout-content {
        background: $white;
        border: solid 1px $border-color;
        padding: $grid-gutter-width;
        text-align: left;

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            float: left;
        }

        li {
            margin-bottom: 1em;
        }

        a,
        li > span {
            position: relative;
            text-decoration: none;
            padding: 0 0 0 ($grid-gutter-width * 1.75);

            &:before {
                @extend %icon-font;
                @include fontSizeREM(42);
                @include icon-background(27, #a0a5a9);
                margin-right: $grid-gutter-width/2;
                vertical-align: -30%;
                position: absolute;
                left: 0;
                top: 50%;
                margin-top: -15px;
                display: inline-block;
            }

            &:after {
                display: none !important;
            }
        }

        li > span:hover {
            text-decoration: underline;
        }

        span {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }
    /* Flyouts : End */

    /* Contact-Flyout : Start */
    .contact .flyout-content {
        overflow-y: scroll;

        ul {
            float: none;
            margin-bottom: $grid-gutter-width;
        }

        a {
            display: inline-block;
        }

        .icon-locations:before,
        .icon-phone:before,
        .icon-mail:before,
        .icon-expert:before {
            font-size: $icon-font-size !important;
        }

        .icon-appointment-videochat:before,
        .icon-news:before {
            font-size: 18px !important;
        }

        p {
            strong {
                display: block;
                margin-top: 12px;
            }
        }

        @include respond-min($breakpoint-min-s) {
            overflow-y: hidden;
            max-height: 430px;
        }

        @media only screen and (max-device-width: 666px) and (orientation: landscape) {
            overflow-y: scroll;
            max-height: 100px;
        }

        @media only screen and (min-device-width: 667px) and (max-device-width: 736px) and (orientation: landscape) {
            max-height: 150px;
        }
    }
    /* Contact-Flyout : End */

    /* Accessibility-Flyout : Start */
    .accessibility .flyout-content {

        span, p {
            font-size: 18px;
            text-decoration: none;
        }

        span {
            font-weight: 400;
            display: inline-block;
        }

        .mod-contrast {
            width: 300px;
            margin: 0 auto;
        }

        /* Contrast-Switch : Start */
        .icon-morecontrast {
            position: relative;
            font-size: 0;
            &:after {
                margin: 0;
                position: relative;
                left: -18px;
            }
        }

        .switch {
            background: $default-color;
            border-radius: 10px;
            cursor: pointer;
            height: 10px;
            position: relative;
            width: 70px;
            left: -8px;
            top: 6px;
            text-indent: -2000px;

            &:before {
                display: none;
                content: "";
                background: $grey;
                border: 1px solid $grey-light;
                border-radius: 50%;
                height: 15px;
                width: 15px;
                position: absolute;
                top: -3px;
                left: 0;
                transition: all ease-in-out .3s;
            }

            &.active {

                &:before {
                    left: 55px;
                }
            }
            @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
                top: 0;
            }
            @media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
                top: 0;
            }
        }
        /* Contrast-Switch : End */

        /* Font-Size : Start */
        .mod-fontsize {
            display: none;
        }

        .wrap-button {
            display: block;
            margin: 1em 0;

            span {
                @extend %keyboard;
                &:nth-child(3) {
                    margin: 0 3em 0 .2em;
                }
            }
        }

        @include respond-min($breakpoint-min-l) {
            .mod-contrast {
                width: 100%;
            }

            .mod-fontsize {
                display: block;
            }
        }

        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
            .mod-fontsize {
                display: none;
            }
        }
        /* Font-Size : End */
    }

    /* hide switch while fadeIn flyout*/
    .accessibility .fadeInUp .flyout-content span.switch:before {
        display: block;
    }

    .language .flyout-content {
        overflow: hidden;

        ul {
            margin: 0 auto;
            width: 110px;
            float: none;
        }

        li {
            @include fontSizeREM(16);
            display: inline-block;
            margin: .5em;
            width: 100%;
            line-height: 2rem;
            text-align: left;

            &.active {
                padding-left: 35px;
                opacity: .6;
            }
        }

        a {
            padding: 6px 0 7px 35px;

            &.active {
                opacity: .7;
            }

            span {
                display: block;
            }
        }

        /* Flags: Start */
        .de {
            @include svgBG('1_german');
        }

        .en {
            @include svgBG('1_english');
        }

        .ru {
            @include svgBG('1_russian');
        }

        .pl {
            @include svgBG('1_polish');
        }
        .es {
            @include svgBG('1_spanish');
        }
        .tr {
            @include svgBG('1_turkish');
        }

        .de,
        .en,
        .ru,
        .pl,
        .es,
        .tr {
            -ms-background-position-x: -33px;
            -webkit-background-position-x: -33px;
        }
        /* Flags: End */

        @include respond-min($breakpoint-min-l) {
            ul {
                width: 100% !important;
                li {
                    width: auto !important;
                }
            }
        }

        @include respond-min($breakpoint-min-m) {
            padding: $grid-gutter-width * .5;
        }

        @media only screen and (max-device-width: 666px) and (orientation: landscape) {
            overflow-y: scroll;
            max-height: 100px;
        }

        @media only screen and (min-device-width: 667px) and (max-device-width: 736px) and (orientation: landscape) {
            max-height: 150px;
        }
    }

    @media (min-width: 415px) {
        ul.meta-list > li {
            width: 25%;

            &.emergency {
                width: 75px;

                span:before {
                    margin-right: 10px;
                }
            }

            > a:after {
                margin: 15px 2px 0 2px;
            }
        }
    }

    @include respond-min($breakpoint-min-m) {
        height: 63px;
        position: fixed;
        width: 320px;

        &.active {
            -webkit-transition: 0.3s linear;
            transition: 0.3s linear;
            margin: 0;
            overflow: visible;
            height: auto;
        }
    }

    @include respond-min($breakpoint-min-l) {
        overflow: visible;
        margin-left: 0;
        left: auto;
        width: 375px;
        top: 25px;
        position: fixed;
        right: 0;
        transform: translateX(0);
        transition: all ease 0s;

        &.active {
            transform:none;
        }

        > ul {

            &.meta-list {
                left: -1px;

                > li {
                    width: auto;

                    a {
                        cursor: pointer !important;

                        &:after {
                            margin-right: 0;
                        }
                    }
                }
            }
        }

        .search {
            width: auto;
            padding: $grid-gutter-width*.5;
        }

        .mod-search .search-toggle {
            display: block;
        }

        .flyout {
            padding-top: 12px !important;
            width: 100.2%;
        }
    }
}

/*hide module till desktop-view: Start */
@include respond-max($breakpoint-min-l) {
    .mod-meta-navigation {
        > ul:first-child {
            display: none;
            margin-right: 0;
        }
    }
    .mod-meta-navigation.active {
        right: 0;

        .emergency, .contact, .accessibility, .language {
            padding: 10px 5px;
        }

        > ul:first-child {
            display: block;

            .flyout {
                top: 122px;

                .col-l-6:first-child {
                    margin-bottom: $grid-gutter-width*.5;
                }
            }

            .icon-morecontrast,
            .icon-lesscontrast {

                &:before,
                &:after {
                    font-size: 35px;
                    margin: 0;
                }
            }
        }
    }
}

/*Animation for Flyout: Start */
.fadeInUp {
    animation-name: fadeInUp;
    animation-duration: .3s;
    animation-fill-mode: both;
    z-index: 14;

    &:after, &:before {
        bottom: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    &:after {
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #fff;
        border-width: 10px;
        margin-left: -10px;
        top: 0;
    }
    &:before {
        border-color: rgba(233, 233, 233, 0);
        border-bottom-color: #e9e9e9;
        border-width: 11px;
        margin-left: -11px;
        top: -2px;
    }
}

@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(-3%);
    }

    100% {
        opacity: 1;
        transform: none;
        visibility: visible;
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translate3d(0, -3%, 0);
    }

    100% {
        opacity: 1;
        transform: none;
        visibility: visible;
    }
}

.contact .fadeInUp {
    &:before {
        left: 59%;
        top: -8px;
    }
    &:after {
        left: 59%;
        top: -6px;
    }
}

.accessibility .fadeInUp {
    &:before {
        left: 83%;
        top: -8px;
    }
    &:after {
        left: 83%;
        top: -6px;
    }
}

.language .fadeInUp {
    &:before {
        left: 34%;
        top: -8px;
    }
    &:after {
        left: 34%;
        top: -6px;
    }
}

@include respond-min($breakpoint-min-l) {
    .mod-meta-navigation {
        background: none;
    }

    .mod-meta-navigation.active {
        .emergency, .contact, .accessibility, .language {
            padding: 10px 11px;
        }
    }

    .contact .fadeInUp {
        &:before {
            left: 69%;
            top: -9px;
        }
        &:after {
            left: 69%;
            top: -7px;
        }
    }
    .accessibility .fadeInUp {
        &:before {
            left: 89%;
            top: -9px;
        }
        &:after {
            left: 89%;
            top: -7px;
        }
    }
    .language .fadeInUp {
        &:before {
            left: 50%;
            top: -9px;
        }
        &:after {
            left: 50%;
            top: -7px;
        }
    }
}

/*Animation for Flyout: End */

// Contrast-Color
.color-pattern-contrast {

    .mod-meta-navigation {

        &.active > ul:last-child {
            outline: solid 1px $black;
        }

        p,
        a {
            color: $black;
        }

        button {
            border: none !important;
        }

        .flyout {
            top: 62px;
        }

        .flyout-content {
            border-color: $black;
        }

        .fadeInUp:before {
            border-bottom-color: $black;
        }
    }
}

.mod-meta-navigation {
    transition: left .3s ease-out;

    /* tablet */
    @include respond-max($breakpoint-max-m) {
        left: -243px;
    }

    /* phone */
    @include respond-max($breakpoint-max-s) {
        left: -101%;
    }

    @include respond-min($breakpoint-min-l) {
        width: 375px;
    }
}

.navigation-is-active {
    .mod-meta-navigation {
        left: -1px;

        /* tablet */
        @include respond-max($breakpoint-max-m) {
            width: 321px;
        }

        /* phone */
        @include respond-max($breakpoint-max-s) {
            width: 101%;
        }

        @media (min-width: 415px) {
            width: 321px;
        }
    }
}

@include respond-min($breakpoint-min-l) {
    .mod-meta-navigation.fixTop0 {
        top: 0;
    }
}

.landingpage {
    @media (min-width: 1180px) {
        .mod-meta-navigation {
            right: $grid-gutter-width;
            left: auto;
        }
    }
}