@charset "UTF-8";

/* mod-goto-article */

@each $color in $color-pattern-list {
    $index: index($color-pattern-list, $color);
    $contrast-color: nth($color-pattern-list-contrast, $index);

    .color-pattern-#{$index} .mod-goto-article .field select,
    .color-pattern-#{$index} .mod-goto-article .field span:after,
    .color-pattern-#{$index} .mod-goto-article > span:before {
        color: $color;
    }

    .color-pattern-contrast.color-pattern-#{$index} .mod-goto-article .field select,
    .color-pattern-contrast.color-pattern-#{$index} .mod-goto-article .field span:after,
    .color-pattern-contrast.color-pattern-#{$index} .mod-goto-article > span:before {
        color: $contrast-color;
    }
}

.mod-goto-article {
    position: fixed;
    bottom: 20px;
    right: 0;
    z-index: 10;
    display: none;
    max-width: 310px;

    .field {
        border: 1px solid $border-color-form;
        float: left;
        margin-right: ($grid-gutter-width * .5);

        select {
            height: 50px;
            padding: ($grid-gutter-width * .5) 45px ($grid-gutter-width * .5) ($grid-gutter-width * .5);
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 232px;
            border: 0;
            font-weight: 400;
            @include fontSizeREM(16);

            &:hover {
                border: 0;
            }
        }

        span {
            left: 25px;
            min-height: initial !important;

            &:after {
                top: -25px;
                right: 35px !important;
                font-size: 20px !important;
            }
        }
    }

    > span {
        position: relative;
        float: right;
        cursor: pointer;
        margin: 0 $grid-gutter-width 0 0;

        &:before {
            @extend %icon-font;
            @extend .icon-top;
            border-radius: 50%;
            margin-top: 8px;
            background: $finder-elements-bg;
            border: 1px solid $border-color-form;
            box-sizing: border-box;
            display: inline-block;
            font-size: 1.5rem;
            padding: .2em;
            text-align: center;
            vertical-align: middle;
        }

        &:after {
            display: none !important;
        }
    }

    @include respond-min($breakpoint-min-s) {
        .field select {
            width: 240px;
        }
    }

    @include respond-min($breakpoint-min-m) {
        .field select {
            @include fontSizeREM(14);
        }

        > span {
            margin: 0 $grid-gutter-width 0 0;
        }
    }

    @include respond-min(75em) {
        left: 870px;
    }
}

// Contrast-Color
.color-pattern-contrast {

    .mod-goto-article {

        select {
            border: none !important;
            background: $white;
        }

        .field {
            border: none;
            outline: 1px solid $black;

            &:hover {
                outline: 2px solid $black !important;
            }
        }

        > span:before {
            background: $white;
            border-color: $black;
        }

        > span:hover:before {
            border: 2px solid $black;
        }

        span:after {
            background: $white;
            border-color: $black;
        }
    }
}
