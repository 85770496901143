@charset "UTF-8";

/* mod-search-jobs */

@each $color in $color-pattern-list {
    $index: index($color-pattern-list, $color);
    $contrast-color: nth($color-pattern-list-contrast, $index);

    .color-pattern-#{$index} .tx-indexedsearch-browsebox .tx-indexedsearch-browselist-currentPage strong {
        @include icon-background(30, $color);
    }
}

.mod-search-jobs {

    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        input {
            background: $white !important;
            padding: 1.5em 3.5em 1.5em 0.7em !important;
        }

        ::-webkit-input-placeholder {
            font-style: italic;
            @include fontSizeREM(16);
        }
        ::-moz-placeholder {
            font-style: italic;
            @include fontSizeREM(16);
        }
        /* firefox 19+ */
        :-ms-input-placeholder {
            font-style: italic;
            @include fontSizeREM(16);
        }
        /* ie */
        input:-moz-placeholder {
            font-style: italic;
            @include fontSizeREM(16);
        }

        .field select {
            padding: 15px 55px 33px 10px;
            text-overflow: ellipsis;
        }
    }
}

/* Pager */
.tx-indexedsearch-browsebox {
    margin: 2em 0 1em 0;
    clear: both;

    &:after {
        @extend %clearfix;
    }

    > p {
        float: left;
        margin: 0 0 1rem 0;
        font-size: 1.5rem;

        @include respond-min($breakpoint-min-s) {
            margin: 0;
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        float: left;
        display: block;

        @include respond-min($breakpoint-min-s) {
            float: right;
        }

        li {
            display: inline-block;
            margin: 0 .25em;

            a {
                display: block;
                text-align: center;
                width: 20px;
                font-size: 1.2em;

                &:before {
                    @extend %icon-font;
                    @include fontSizeREM(42);
                    margin-right: $grid-gutter-width/2;
                    vertical-align: -30%;
                }

                &:after {
                    display: none !important;
                }

                &:hover {
                    opacity: .6;
                }
            }

            .less:before,
            .more:before {
                @include fontSizeREM(20);
                vertical-align: top;
            }

            .less:before {
                @extend .icon-left;
            }

            .more:before {
                content: "\e61e";
                position: relative;
            }
        }
    }

    .tx-indexedsearch-browselist-currentPage {
        strong {
            color: $white;
            width: 30px;
            padding: 0;
            @include fontSizeREM(18)
        }
    }
}

/* List jobs teaser */
.list-jobs {
    &:after {
        @extend %clearfix;
    }

    li {
        background: $main-color;
        margin: 1rem 0 0 0;
        width: 100%;
        min-height: 250px;
        float: left;
        position: relative;
        vertical-align: top;

        @include respond-min($breakpoint-min-l) {
            min-height: 315px;
            width: 49%;

            &:nth-child(even) {
                float: right;
            }
        }

        p:first-child {

            &:after {
                @extend %clearfix;
            }

            span {
                float: right;
            }
        }

        h5 + p {
            margin: 0;
        }

        a {
            padding: 1rem 1rem 3rem;
            float: left;
            width: 100%;
            height: 100%;

            @include respond-min($breakpoint-min-l) {
                padding: 1rem 1rem 2rem;
            }

            &:hover .meta-info {
                text-decoration: none;
            }
        }
    }

    .meta-info {
        bottom: 0;
        left: 0;
        padding: 0 1rem;
        position: absolute;
        width: 100%;
        text-align: right;
        text-decoration: underline;

        span {
            font-weight: 500;
            float: left;
            width: 100%;
            text-align: left;
        }
    }

    @include respond-min($breakpoint-min-s) {

        .meta-info span {
            width: auto;
        }
    }
}

.project .list-jobs  li {
    /* falsely corrected for all: on 5.9.2019 by Fabian Martin */
    background: $white;
}

.landpro .highlighted .list-jobs li,
.landpro .whiteBackground .list-jobs ul {
    margin-bottom:20px;
}

// Contrast-Color
.color-pattern-contrast {

    .tx-indexedsearch-browsebox a {
        color: $black;
    }

    .tx-indexedsearch-browselist-currentPage strong {
        background: $icon-contrast-color;
        color: $white;
    }

    select {
        background: transparent !important;
    }

    .list-jobs li,
    .list-events li,
    .list-topics li a {
        background: $white;
        color: $black;
        border: 1px solid $black;
    }
}