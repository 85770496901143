@charset "UTF-8";

/* mod-download */

@each $color in $color-pattern-list {

    $index: index($color-pattern-list, $color);
    $contrast-color: nth($color-pattern-list-contrast, $index);

    .color-pattern-#{$index} .mod-download-link-list a .doctype:after {
        @include icon-background(35, $color);
    }

}

.mod-download-link-list {

    a,
    .not_linked {
        padding: $grid-gutter-width / 2;
        @include respond-min($breakpoint-min-s) {
            padding: $grid-gutter-width;
        }
        background: $main-color;
        margin-bottom: $grid-gutter-width;
        display: inline-block;
        position: relative;

        strong {
            display: inline-block;
        }

        strong,
        p:not(.doctype) {
            max-width: 80%;
            margin: 5px 0 0 0;
        }

        @include respond-max($breakpoint-max-s) {
            strong,
            p:not(.doctype) {
                max-width: 66%;
                margin: 5px 0 0 0;
            }
        }

        .doctype {
            position: absolute;
            right: 60px;
            top: 33%;
            @include respond-min($breakpoint-min-s) {
                top: 44%;
            }
            width: auto;

            &:before {
                display: none !important;
            }

            &:after {
                @extend %icon-font;
                @include fontSizeREM(24);
                position: absolute;
                top: -10px;
            }
        }
    }

    &:after {
        @extend %clearfix;
    }

    // fix pdf
    .doctype.icon-pdf:before,
    .doctype.icon-pdf:after,
    .doctype.icon-PDF:before,
    .doctype.icon-PDF:after {
        font-size: 1.8rem;
    }
}

// Contrast-Styles
.color-pattern-contrast {

    .mod-download-link-list a,
    .mod-download-link-list .not_linked {
        background: $white;
        border: 1px solid $black;
        color: $black;

        .doctype:after {
            background: $icon-contrast-color;
        }

        &:hover {
            outline: 1px solid $black;
        }
    }

}

/** podcast: new **/
.podcasts .mod-download-link-list a,
.podcasts .mod-download-link-list .not_linked {
    background: #fff;
}

.landpro .mod-download-link-list a,
.landpro .mod-download-link-list .not_linked {
    background: $white;
}