@charset "UTF-8";

/* main-ms */
/**
 *  core variables and mixins
 *  - modify this for custom colors, font-sizes, etc
 */
@import "partials/functions";
@import "partials/variables";
@import "partials/mixins";
/**
 * normalize
 * https://github.com/necolas/normalize.css
 */
@import "vendor/normalize";
/**
 * h5bp main.css
 * https://github.com/h5bp/html5-boilerplate
 */
@import "vendor/h5bp";
/**
 * old-ie mixin
 * https://gridsetapp.com/
 */
@import "vendor/sass-ie";
/**
 * vendor utilities
 * - remove comment when mixin is needed
 */
// @import "vendor/retina-sprites";
// @import "vendor/retina-background-image";
/**
 * layout and grid
 * - brandung grid
 * - based on the bootstrap v3 gridset
 *
 * - brandung layout files
 * - here you can add new files if needed
 */
@import "partials/font";
@import "partials/layout";
@import "partials/grid";
@import "partials/icons";
@import "partials/typo";
@import "partials/buttons";
@import "partials/helpers";
@import "partials/header";
@import "partials/footer";
@import "partials/navigation";
@import "partials/custom-select";
// disable Cookie notification - see Jour Fixe IT/UK 19.11.2019 by Haiko Buchholz @import "partials/cookie";
/**
 * modules placeholder
 * - import all modules files
 *
 * - for grunt-build-process:
 * - package files by download builder
 * - add files by appendAssets task in project builder (bra-pd)
 */
// --- start|bra-pb: scss ---
@import "partials/mod/mod-accordion";
@import 'partials/mod/mod-form';
@import 'partials/mod/mod-meta-navigation';
@import 'partials/mod/mod-search';
@import 'partials/mod/mod-flexslider';
@import 'partials/mod/mod-simple-flexslider';
@import 'partials/mod/mod-stage-slider';
@import 'partials/mod/mod-stage-static';
@import 'partials/mod/mod-teaser';
@import 'partials/mod/mod-lexicon';
@import 'partials/mod/mod-slider-full-width';
@import 'partials/mod/mod-breadcrumbs';
@import 'partials/mod/mod-teaser-list';
@import 'partials/mod/mod-table';
@import 'partials/mod/mod-surgery';
@import 'partials/mod/mod-finder';
@import 'partials/mod/mod-article';
@import 'partials/mod/mod-highlighted';
@import 'partials/mod/mod-link';
@import 'partials/mod/mod-goto-article';
@import 'partials/mod/mod-map';
@import 'partials/mod/mod-search-jobs';
@import 'partials/mod/mod-search-events';
@import 'partials/mod/mod-search-topics';
@import 'partials/mod/mod-download';
@import 'partials/mod/mod-carousel';
@import 'partials/mod/mod-video';
@import 'partials/mod/mod-bloc-contact';
@import 'partials/mod/mod-google-search';
@import 'partials/mod/mod-loading';
@import 'partials/mod/mod-glossar';
@import 'partials/mod/mod-table-of-content';
@import 'partials/mod/mod-csc-customization';
@import 'partials/mod/mod-mail';
@import 'partials/mod/mod-custom-fixes';
@import 'partials/mod/mod-baustelle';
@import 'partials/print';

// --- end|bra-pb: scss ---

// project and landingpage
.landpro {

    #main {
        background: $main-color;
        max-width: (940 / $body-font-size-px) + em;
    }

}

// landingpage
.landingpage {
    body {
        background: $main-color;
    }

    #main {
        max-width: none;
    }
}

body > .extbase-debugger {
    margin-left: 15em !important;
}