@charset "UTF-8";

/* mod-search-topics */

.mod-search-topics {

    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        input {
            background: $white !important;
        }
    }

    @include respond-max($breakpoint-max-m) {
        .mod-form .form-list .fields .field.size-one-third {
            width: 49%;
        }
    }

}

/* List topics teaser */
.list-topics {

    &:after {
        @extend %clearfix;
    }

    li {
        background: $main-color;
        margin: 1em 0 0 0;
        width: 100%;
        float: left;
        position: relative;
        vertical-align: top;

        a {
            color: $text-color;
            margin-bottom: 0;

            &:hover {
                color: #303030;
            }
        }

        .teaser-content {
            text-align: left;

            h5 {
                float: left;
                margin-right: 10px;
            }

            strong {
                @include fontSizeREM(16);
            }
        }

        @include respond-min($breakpoint-min-l) {
            width: 49%;

            &:nth-child(even) {
                float: right;
            }
        }

        p:first-child {

            &:after {
                @extend %clearfix;
            }

            span {
                float: right;
            }
        }
    }
}

// Contrast-Styles
.color-pattern-contrast {
    .list-topics li:hover {
        outline: 1px solid $black;
    }
}
