@charset "UTF-8";

/* variables */

/* Base sizes */
$base-em: 16;

$featurephone: 320;
$smartphone: 480;
$tablet: 768;
$desktop: 992;
$retina: 1200;
$beyond: 1500;

// Breakpoints in Pixels
// xs: 000 .. 479
//  s: 480 .. 767
//  m: 768 .. 991
//  l: 992 .. 1199
// xl: 1200 .. 1499
// xxl: 1500+


/* Body font size */
$body-font-size-px: 16;
$body-font-size: percentage(($body-font-size-px / $base-em) * 100 / 100); // in percent

/* Icon font size */
$icon-font-size: 25px; // 25px

/* Device widths */

/* Container width */
$container-max-width: 920; // in px without unit!
$container-min-width: $featurephone; // in px without unit!

// Number of columns in the grid.
$grid-columns: 12 !default; // Padding between columns.
$grid-gutter-width: 20px !default; // Gets divided in half for the left and right.

/* Media queries Breakpoints */
// $breakpoint-min-xs: pxToEm($featurephone); //=> mobile: min-width 320px
$breakpoint-min-xs: pxToEm(0); // RV make it zero or it  will beak the navigation between 0..320px
$breakpoint-min-s: pxToEm($smartphone); // => large mobile: min-width 480px
$breakpoint-min-m: pxToEm($tablet);     // => tablet: min-width 768px
$breakpoint-min-l: pxToEm($desktop);    // => desktop: min-width 992px
$breakpoint-min-xl: pxToEm($retina);    // => extra large desktop: min-width 1200px
$breakpoint-min-xxl: pxToEm($beyond);   //=> ultra large desktop: min-width 1500px = 93.75em; //(1500/16) + em;

// So media queries don't overlap when required, provide a maximum
$breakpoint-max-xs: pxToEm($smartphone - 1);    // => mobile: max-width 479px
$breakpoint-max-s: pxToEm($tablet - 1);         // => large mobile: max-width 767px
$breakpoint-max-m: pxToEm($desktop - 1);        // => tablet: max-width 991px
$breakpoint-max-l: pxToEm($retina - 1);         // => desktop: max-width 1199px
$breakpoint-max-xl: pxToEm($beyond - 1);        // => extra large desktop: max-width 1499px

// color pattern list
$color-pattern-list: (
        #1d9a73, // 1 Klinikum          from-clinic
        #3e7dbd, // 2 Forschung         from-research
        #ad4371, // 3 Studium & Lehre   from-study
        #a09076, // 4 Karriere          from-career
        #d2b345, // 5 International     from-international
        #a0a5a9, // 6 Die Charité       from-charite
        #dadcde, // 7 Kontakt           from-contact - UNUSED
        #18b1ae, // 8 Türkis            from-service
        #70acc0  // 9 BIH-Graublau      from-bih
        // RV untested alternate #467896 or #3C788C
);

// color pattern list contrast
$color-pattern-list-contrast: (
        #00663f, // 1 Klinikum          from-clinic
        #003399, // 2 Forschung         from-research
        #9B0065, // 3 Studium & Lehre   from-study
        #66563c, // 4 Karriere          from-career
        #AA6100, // 5 International     from-international
        #252525, // 6 Die Charité       from-charite
        #a0a5a9, // 7 Kontakt           from-contact - UNUSED
        #009999, // 8 Türkis            from-service
        #003754  // 9 BIH Blau          from-bih
        // RV recommends #4C626C
);

/* Colors */
$black: #000 !default;
$white: #fff !default;
$grey: #555 !default;
$grey-dark: darken($grey, 15%) !default;
$grey-light: lighten($grey, 15%) !default;
$green-dark: #00663f !default;

/* Scaffolding */
$border-color: #e9e9e9 !default;
$border-color-form: #c6c6c6 !default;
$default-color: nth($color-pattern-list, 7);
$main-color: #f6f3ed !default;
$emergency-color: #cb0404 !default;

/* text */
$ci-color: #868686 !default;
$text-color: #565656 !default;
$icon-color: #a0a5a9 !default;
$icon-hover-color: #808487 !default;
$icon-contrast-color: #252525 !default;

/* notification */
$error-color: #ca0000 !default;
$success-color: green !default;
$info-color: yellow !default;

/* Links */
$link-color: #565656 !default;
$link-color-hover: darken($link-color, 15%) !default;

/* Finder-Grey*/
$finder-elements-bg: #f3f3f3 !default;
$finder-elements-bg-hover: #dfdddc !default;

/*Table-bg*/
$table-color-1: #f4f4f4 !default;
$table-color-2: #ededed !default;
