@charset "UTF-8";

/* footer */

footer {
    padding: $grid-gutter-width 0 $grid-gutter-width * 1.5 0;
    background: $table-color-1;
    max-width: (940 / $body-font-size-px) + em;

    a {
        text-decoration: none;
    }

    .highlight {
        color: #ca0000;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    li.highlight:hover {
        text-decoration: underline;
    }

    span {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    ul {
        padding: 0;
        list-style: none;
        margin: 0 auto;
        width: 200px;

        &.meta-navi {
            height: 200px;
            width: 200px;

            li {
                width: 100px;
                float: left !important;
                margin-bottom: $grid-gutter-width;

                &:before {
                    display: none;
                }
            }

            p {
                margin: 0;
            }
        }

        &.service-navi li:before {
            display: none;
        }
    }

    .logo {
        margin-bottom: 20px;
        left: -83px;
    }

    .copyright {
        float: right;

        @include respond-max($breakpoint-min-l) {
            margin-right: $grid-gutter-width;
        }
    }

    .flyout {
        position: absolute;
        top: -245px;
        padding-top: 20px;
        display: none;
        left: -20px;
        width: 245px;
        height: 245px !important;
    }

    .flyout-content {
        height: 220px !important;
        background: $white;
        border: solid 1px $border-color;
        padding: $grid-gutter-width !important;

        strong {
            font-weight: 400;
        }

        a {
            float: left;
            margin-right: $grid-gutter-width;
            text-decoration: none !important;

            span {
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }

            &.recommendation-mail {

                &:before {
                    @extend %icon-font;
                    @extend .icon-mail_alt;
                    @include fontSizeREM(35);
                    margin-right: $grid-gutter-width*.25;
                    vertical-align: -30%;
                }

                &:after {
                    display: none !important;
                }
            }

            &.bookmark {
                &:before {
                    @extend %icon-font;
                    @extend .icon-bookmark;
                    @include fontSizeREM(35);
                    margin-right: $grid-gutter-width*.25;
                    vertical-align: -30%;
                }

                &:after {
                    display: none !important;
                }
            }
        }

        input {
            float: left;
            width: 100%;
            padding: $grid-gutter-width*.5 $grid-gutter-width*.25;
            margin-bottom: $grid-gutter-width*.5;
        }
    }

    .footer-content {
        padding-bottom: 5.5em;

        .recommend {
            position: relative;
        }
    }

    .flyout.active {
        display: block;

        &:before {
            left: 20%;
            top: 264px;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border: solid transparent;
            border-color: rgba(255, 255, 255, 0);
            border-top-color: #fff;
            border-width: 10px;
            margin-left: -10px;
        }

        span:hover {
            text-decoration: none;
        }
    }

    @include respond-min($breakpoint-min-s) {
        ul {
            &.meta-navi {
                width: 320px;

                li {
                    width: 160px;
                }
            }

            &.service-navi {
                // new: 2 ul for right order
                width: 150px;
                float: left !important;
                margin: 0;

                li {
                    padding: 0 !important;
                }
            }
        }
    }

    @media(min-width: 550px) {
        .flyout {
            top: -270px;
            padding-right: 20px;
            padding-top: 20px;
            right: 0;
            width: 340px;
            left: auto;

            &.active:before {
                left: 80%;
            }
        }

        .flyout-content {
            height: 190px;
        }
    }

    //smartphone landscape
    @media only screen and (min-device-width: 320px) and (orientation: landscape) {
        .flyout {
            right: 105%;
            top: -100px;
            width: 255px;

            &.active:before {
                left: 99.7%;
                top: 110px;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border: solid transparent;
                border-color: rgba(255, 255, 255, 0);
                border-top-color: transparent;
                border-left-color: #fff;
                border-width: 10px;
                margin-top: -10px;
                margin-left: 0;
            }
        }
    }

    @include respond-min($breakpoint-min-m) {
        ul {
            &.meta-navi {
                width: 240px;
                margin: 0;

                li {
                    width: 120px;
                }
            }

            &.service-navi {
                margin-top: 0;
            }
        }

        .flyout {
            top: -30px;
            width: 445px;

            &.active:before {
                top: 40px;
            }
        }
    }

    @include respond-min($breakpoint-min-l) {
        padding: 20px;

        .logo {
            left: 0;
        }
    }

    nav {
        margin: 0 0 30px 0;
        padding-top: 20px;

        &:after {
            @extend %clearfix;
        }

        ul {
            &:after {
                @extend %clearfix;
            }
        }

        div {

            li {
                margin-bottom: 20px;
            }

            &:first-child li {

                a:hover {
                    text-decoration: underline;
                }

                /* desktop */
                @include respond-min($breakpoint-min-l) {
                    text-align: left;
                }
            }
        }
    }

    a[class*="icon-"] {
        text-decoration: none !important;

        &:before {
            @extend %icon-font;
            @include icon-background(26, nth($color-pattern-list, 6));
            @include fontSizeREM(24);
            vertical-align: bottom;
            margin-right: $grid-gutter-width/2;
        }

        &:after {
            display: none !important;
        }

        &:hover span {
            text-decoration: underline;
        }

    }

    .row {
        margin-bottom: 0;
    }

    .col-l-9 {

        > div {

            li {
                float: none;
            }

            div:first-child,
            div:nth-child(2n) {
                height: 190px;
            }

            &:first-child li {
                float: none;
            }
        }
    }

    /* max 479px */
    @media (max-width: 479px) {

        .col-l-9 {
            width: 300px;
            margin: 0 auto;
        }

        .col-xs-6 {
            width: 50%;
        }
    }

    /* max: tablet*/
    @include respond-max($breakpoint-min-m) {

        .col-l-9 {
            width: auto;
            margin: 0 auto;
        }
    }

    /* smartphone to tablet */
    @media (min-width: 550px) {

        .col-l-9 {
            width: 345px;

            > div {
                margin: 0;
                padding: 0;

                div {
                    height: auto;

                    &:first-child,
                    &:nth-child(2n) {
                        padding-right: 0;
                    }
                }

                li {
                    float: left;
                }
            }

            [class*="icon-"] {
                @include fontSizeREM(14);

                a {
                    margin-bottom: 0;
                    display: inline-block;
                }
            }
        }
    }

    /* smartphone */
    @include respond-min($breakpoint-min-s) {

        .col-l-9 {

            div li {
                padding-right: 10px;

                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }

    /* tablet */
    @include respond-min($breakpoint-min-m) {

        .col-l-9 {
            width: auto;

            > div {
                overflow: visible;

                li {
                    float: none;
                }
            }
        }
    }

    /* desktop */
    @include respond-min($breakpoint-min-l) {

        .col-l-9 {
            width: 75%;
            margin: 0;
        }

        .col-l-3 {
            padding: 0;

            a {
                margin-left: 0;
            }
        }
    }
}

.color-pattern-contrast {

    footer {
        background: #252525;

        p,
        a:not(.highlight) {
            color: $white;

            &:before {
                color: $black;
                background: $white;
            }
        }

        .flyout-content {

            p,
            a {
                color: $black;
            }
        }

        .logo {
            @include svgBG('logo_weiss');
        }
    }
}

@each $color in $color-pattern-list {

    $index: index($color-pattern-list, $color);
    $contrast-color: nth($color-pattern-list-contrast, $index);

    .color-pattern-#{$index} footer {
        a[class*="icon-"]:before {
            background: rgba($color, .55);
        }
    }

    .color-pattern-#{$index}.color-pattern-contrast footer {
        a[class*="icon-"]:before {
            background: $white;
            color: $black;
        }
    }

    .landpro .color-pattern-#{$index} footer {
        background: $color;

        a[class*="icon-"]:before {
            color: $color;
            background: $white;
        }
    }

    .landpro .color-pattern-contrast.color-pattern-#{$index} footer {
        background: $contrast-color;

        a[class*="icon-"]:before {
            color: $contrast-color;
        }
    }

}

.landpro {
    footer {
        color: $white;
        a {
            color: $white;
        }

        .logo {
            @include svgBG('logo_weiss');
        }

        .logoPortal {
            background: url(../img/svg/navi-portal_weiss.svg) transparent 0 0 no-repeat;
            background-size: 35px 35px;
            text-decoration: none;
            padding: 10px 0 10px 40px;

            display: block;
            left: 0;

            /*
            text-indent: -999em;
            */
            margin-left: auto;
            margin-right: auto;
            margin-top: 10px;

            width: 140px;

            span {
                @include fontSizeREM(12);
                margin: 0 auto;
            }

            &:hover {
                text-decoration: none;
            }
        }
        .logoPortal:hover {
        }

        .col-l-9 > div div:first-child,
        .col-l-9 > div div:nth-child(2n),
        ul.meta-navi {
            height: auto !important;
        }

        .service-navi {
            //margin-bottom: 20px;
            margin-bottom: 0;

            .flyout,
            .flyout a {
                color: $text-color;
            }

            .flyout-content {
                overflow: hidden;
            }
        }

        .meta-navi {
            width: 200px;
            li {
                width: 200px;
            }
        }

        .copyright {
            float: none;
            position: relative;
            display: block;
            margin-left: $grid-gutter-width;
        }

        @include respond-min($breakpoint-min-s) {
            .service-navi {
                margin: 0 auto;
                width: 200px;
                /* RV restore two colpos layout for landingpages -on explicit wish of juliane
                float: none !important;
                */
            }
        }

        @include respond-min($breakpoint-min-m) {
            .col-l-3 {
                width: 25%;
                float: left;
            }

            .logo {
                margin-left: $grid-gutter-width / 2;
                left: 0;
            }

            .logoPortal {
                margin-left: $grid-gutter-width / 2;
                left: 0;
                width: 160px;

                span {
                    @include fontSizeREM(14);
                }
            }

            nav {
                margin-bottom: 0;
            }

            .col-m-5 {
                width: auto;
                margin-left: $grid-gutter-width;
            }

            .col-m-7 {
                float: right !important;
                width: auto;
            }

            .copyright {
                display: inline-block;
            }

            .service-navi {
                float: left;
            }
        }

        @include respond-min($breakpoint-min-l) {
            .logo,
            .logoPortal {
                margin-left: 0;
            }

            .copyright {
                margin-left: 0;
            }
        }
    }
}

.landingpage {
    footer {
        max-width: none;
    }
}