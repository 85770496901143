@charset "UTF-8";

/* mod-stage-static */

@each $color in $color-pattern-list {
    $index: index($color-pattern-list, $color);
    $contrast-color: nth($color-pattern-list-contrast, $index);

    .color-pattern-#{$index} .mod-stage-static .highlighted a,
    .color-pattern-#{$index} .mod-stage-static .back-to-overview {
        color: $color;
    }

    .color-pattern-#{$index} .mod-stage-static {
        background: rgba($color, .11);

        &.stage-persona {

            li:before {
                @include icon-background(26, $color);
                line-height: 26px !important;
            }

            .street-address:before {
                @include icon-background(26, $color);
                line-height: 26px !important;
            }
        }
    }

    .landingpage .color-pattern-#{$index} .mod-stage-static {
        background: none;
    }

    .color-pattern-contrast.color-pattern-#{$index} .mod-stage-static .highlighted a,
    .color-pattern-contrast.color-pattern-#{$index} .mod-stage-static .back-to-overview {
        color: $contrast-color;
    }
}

.mod-stage-static {
    position: relative;
    background: $main-color;
    padding: 75px 10px 20px 10px;
    @include respond-min($breakpoint-min-l) {
        padding: 5px 30px 20px 30px;
    }
    max-width: (940 / $body-font-size-px) + em;

    > ul,
    > ol {
        margin-left: 10px;
        margin-right: 10px;
    }

    h1 {
        margin-top: 20px;
    }

    > h1,
    > h2,
    > p {
        margin-left: ($grid-gutter-width * .5);
        margin-right: ($grid-gutter-width * .5);
    }

    &.mod-info {
        padding: $grid-gutter-width;

        h2[class*="icon-"] {
            padding: 0;

            &:before {
                position: relative;
                top: auto;
                display: block;
                margin: .5em auto;
            }
        }

        @include respond-min($breakpoint-min-s) {

            h2[class*="icon-"] {
                padding-left: 56px;

                &:before {
                    position: absolute;
                    top: 50%;
                    margin-top: -23px;
                    display: inline-block;
                }
            }

            .row > div {
                padding: 0 $grid-gutter-width 0 56px;
            }
        }
    }

    .row {
        max-width: 920px;
        margin: 0;

        @include respond-min($breakpoint-min-xxl) {
            max-width: (920 / $body-font-size-px) + em;
            margin-left: 0;
        }

        /* --- Emergency-Stage: Start --- */
        .from-emergency-bg {

            strong:first-child {
                @include fontSizeREM(35);
                height: 45px;
            }

            @media (min-width: $breakpoint-min-s) and (max-width: $breakpoint-min-m) {
                margin-top: 75px;
            }
        }

        .from-clinic-bg:not(.mod-teaser strong) {

            strong {
                margin-top: 23px;
            }
        }

        .from-emergency-bg {
            &:before {
                @include fontSizeREM(55);
                @include icon-circle(75);
                border: solid 2px #fff !important;

                content: url(../font-2020/charite-icons-erstehilfe.svg) !important;
                background-color: #fff;
                padding: 0 5px;
                line-height: 70px;
            }
        }

        .from-clinic-bg {

            &:before {
                @include fontSizeREM(55);
                @include icon-circle(75);
                border: solid 2px #fff !important;
                padding: 0 5px 5px 7px;
            }
        }

        /* --- Emergency-Stage: End --- */
    }

    .date,
    .job-section {
        display: block;
        margin-bottom: 0;
    }

    .highlighted {
        border: solid 1px $border-color;
        background: #fff;
        padding: 12px $grid-gutter-width;

        .info-text {

            h2 {
                @include fontSizeREM(16);
                font-weight: 500;
                margin: ($grid-gutter-width * .5) 0 0;
                padding-left: 0 !important;

                &:before {
                    display: none !important;
                }
            }

            > div {
                padding-left: 0 !important;
                margin: ($grid-gutter-width * .5) 0;

                p {
                    margin: 0;
                }
            }
        }

        a {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .stage-content {
        h1 {
            line-height: initial;
        }

        h3 {
            margin: $grid-gutter-width ($grid-gutter-width * .5) 0 0;

            @include respond-min($breakpoint-min-m) {
                margin: -2px ($grid-gutter-width * .5) 0 0;
            }
        }

        @include respond-max($breakpoint-max-s) {
            > p {
                margin-top: $grid-gutter-width !important;
            }
        }
    }

    > div:not(.mod-stage-static.row) .stage-content {
        margin: 20px 0 0 0;
    }

    @include respond-min($breakpoint-min-m) {
        .row .stage-content {
            margin-bottom: ($grid-gutter-width * 1.5);
        }
    }

    @include respond-min($breakpoint-min-m) {
        .mod-teaser {
            margin-top: 85px;
        }
    }

    @include respond-min($breakpoint-min-l) {
        .col-m-8 {
            margin-top: 30px;
            padding: 0;
        }
    }

    .mod-teaser {
        span,
        strong {
            &:first-child {
                @include respond-min($breakpoint-min-xs) {
                    min-height: 0;
                }
                @include respond-min($breakpoint-min-m) {
                    min-height: 50px;
                }
            }
        }
    }

    .stage-content,
    .mod-teaser {
        /* tablet */
        @include respond-min($breakpoint-min-m) {
            margin-top: 60px;

            h1,
            h2 {
                margin-top: 10px;
            }
        }

        /* desktop */
        @include respond-min($breakpoint-min-l) {
            margin-top: 60px;

            h1,
            h2 {
                margin-top: 0;
            }
        }
    }

    @include respond-min($breakpoint-min-m) {
        .stage-content {
            margin: 20px 0 0 0;
        }

    }

    /* tablet max */
    @include respond-max($breakpoint-max-m) {
        img {
            display: block;
            margin: 0 auto;
        }
    }

    /* desktop min */
    @include respond-min($breakpoint-min-l) {
        overflow: hidden;
        height: auto;
        // padding-top: 65px;

        .stage-content {
            margin: 0;
        }
    }

    /* large desktop min-height would work, but we force the stage to be a fixed height */
    @include respond-min($breakpoint-min-xl) {
        height: 337px;

        &.stage-only-image {
            height: 420px;
        }
    }

    &.stage-persona,
    &.stage-contact-clinic {

        img {
            max-width: 297px;
            width: 100%;
            height: auto;
        }

        .stage-content {
            float: left;
            display: inline-block;
            padding-left: 10px;

            strong {
                display: block;
            }

            li {
                list-style-type: none;
            }

            .contact,
            .street-address {
                max-width: 245px;
                margin-bottom: 0;
                padding: 0;
                position: relative;
                display: inline-block;
                float: left;
            }

            .contact {
                margin-right: ($grid-gutter-width * 3);

                .tel,
                .fax,
                .mail,
                .route {
                    margin: 10px 0 0 37px !important;
                    text-decoration: none;

                    &:before {
                        @extend %icon-font;
                        margin: -5px 10px 10px -37px;
                        position: absolute;
                        @include fontSizeREM(24);
                    }

                    &:after {
                        display: none !important;
                    }
                }

                .tel:before {
                    @extend .icon-phone;
                }

                .fax:before {
                    @extend .icon-print;
                }

                .mail:before {
                    @extend .icon-mail;
                }

                .route:before {
                    @extend .icon-route;
                }
            }

            .street-address {
                margin: 10px 0 0 37px;

                &:after {
                    display: none !important;
                }

                &:before {
                    @extend %icon-font;
                    @extend .icon-locations;
                    @include fontSizeREM(24);
                    vertical-align: -30%;
                    margin: -5px 10px 0 -37px;
                    position: absolute;
                }
            }
        }

        @include respond-min($breakpoint-min-s) {

            img {
                float: left;
            }
        }

        @include respond-min($breakpoint-min-l) {

            .col-s-8 {
                margin-top: 35px;
            }
        }
    }

    &.stage-contact-clinic {
        padding: 10px 20px 0 20px;

        @include respond-max($breakpoint-max-m) {
            padding: 80px 20px 0 20px;
        }

        div {
            padding: 0;

            .street-address,
            .contact {
                width: 100%;
            }

            .street-address {
                margin-top: 0 !important;
            }

            strong {
                margin-bottom: $grid-gutter-width;
            }
        }

        .contact {

            .route {
                margin-top: ($grid-gutter-width * 1.25) !important;
            }
        }

        @include respond-min($breakpoint-min-m) {

            div:first-child {

                strong,
                p {
                    width: 90%;
                }
            }
        }

        .col-m-8 {
            margin-top: 0;
        }
    }

    &.stage-persona,
    &.stage-persona .col-s-4 {

        @include respond-min($breakpoint-min-m) {
            padding-left: 0 !important;
        }
    }

    &.stage-press-release {

        h3 {
            float: left;
        }

        h3,
        p {
            margin: ($grid-gutter-width * .5) ($grid-gutter-width) ($grid-gutter-width * .5) 0;
        }

        p {
            padding-top: 13px;
        }

        a {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }

        @include respond-min($breakpoint-min-m) {

            h1 {
                margin-bottom: ($grid-gutter-width * 2);
            }

            p {
                padding-top: 3px;
            }

            h3 {
                margin: 0 $grid-gutter-width 0 0;
            }
        }
    }

    &.stage-fullheight-image,
    &.stage-fullheight-image .col-m-6:first-child,
    &.stage-fullheight-image .col-m-6-first-child {
        padding-left: 0 !important;
        padding-bottom: 0 !important;
        padding-right: 0 !important;
    }

    // THERE MIGHT BE AN ANCHOR BEFORE blocking the rule above
    // @see why first-child fails http://stackoverflow.com/questions/2717480/css-selector-for-first-element-with-class
    &.stage-fullheight-image div.csc-default > div:first-of-type {
        padding: 0;
    }

    &.stage-fullheight-image .col-m-6:last-child {
        padding-left: 30px;
    }

    &.stage-fullheight-image {
        padding-top: 75px;

        @include respond-min($breakpoint-min-l) {
            padding-top: 0;

            .col-m-6:last-child {
                padding-top: 30px;
            }
        }

        .header {
            display: none;
        }

        figcaption {
            display: none;
        }
    }

    &.stage-fullsize-image {
        padding: 0;
        width: 100%;

        .slide-content {
            bottom: 20px;
            right: 20px;
        }

        h2 {
            max-width: none;
        }

        p {
            margin-bottom: 0;
        }

        @include respond-min($breakpoint-min-m) {
            .slide-content {
                padding: 20px 30px;
                position: absolute;
                bottom: 40px;
                width: 440px;
                right: 60px;
            }

            h2 {
                margin-top: 0;
            }
        }

        @include respond-min($breakpoint-min-l) {
            margin-top: 0;
            height: 420px;
        }
    }

    &.stage-only-image {
        .row {
            max-width: 940px;
        }

        .header {
            display: none;
        }

        figcaption {
            display: none;
        }

    }

    @include respond-max($breakpoint-max-s) {
        &.stage-probands {
            .stage-content {
                margin-top: $grid-gutter-width;

                p {
                    margin: 0 !important;
                }

                h2 {
                    margin: 0 0 $grid-gutter-width 0;
                }

                .back-to-overview {
                    position: relative;
                }
            }
        }
        &.job {
            .stage-content {
                p {
                    margin: 5px 0 !important;
                }
            }

            .mod-teaser,
            .mod-teaser strong {
                margin-bottom: 0;
            }
        }
    }
}

.back-to-overview {
    text-decoration: underline;
    position: relative;
    bottom: 10px;

    @include respond-min($breakpoint-min-m) {
        position: absolute;
    }

    &:hover {
        text-decoration: none;
    }
}

// Kontrast-Styles
.color-pattern-contrast {
    .mod-stage-static {
        a,
        p,
        .fax {
            color: $black;
        }

        .stage-content .contact .tel,
        .stage-content .contact .fax,
        .stage-content .contact .mail,
        .stage-content .contact .route,
        .stage-content .street-address {
            &:before {
                @extend %icon-font;
                background: $icon-contrast-color;
            }
        }
    }
}

.landingpage {
    .mod-stage-static {
        max-width: 1920px;
        margin: 0 auto;

        &.stage-fullheight-image {
            &.stage-only-image {
                .row {
                    img {
                        max-height: 1100px;
                    }
                }
            }
        }

        &.stage-only-image {
            height: auto;

            .row {
                max-width: none;
            }

            .header {
                display: block;
                max-width: 940px;
                margin: 0 auto 0 auto;
                padding: 0 $grid-gutter-width;

                h1, h2 {
                    font-size: 1.5rem;
                    line-height: 1.1;
                }

                @include respond-min($breakpoint-min-m) {
                    color: $white;
                    position: relative;
                    bottom: 50px + 74px;
                    text-align: right;
                    padding: 0 $grid-gutter-width * 2;

                    /* RV DEMO and when the font is bolder; you could use -webkit-font-smoothing: antialiased */
                    /* this is used on landing page layout */
                    text-shadow: 0 0 5px #222, 0 0 5px #222, 0 0 5px #222, 0 0 5px #222, 0 0 5px #222;

                    h1, h2 {
                        font-size: 2rem;
                        margin: 0 0 $grid-gutter-width 0;
                        margin-left: 11rem;
                        @include respond-max($breakpoint-max-xs) {
                            @include prefix(hyphens, 'auto');
                        }
                    }
                }

                @include respond-min($breakpoint-min-l) {
                    bottom: 80px + 74px;

                    h1, h2 {
                        margin-left: 21rem;
                    }
                }

                @include respond-min($breakpoint-min-xl) {
                    bottom: 100px + 74px;
                }

                @include respond-min($breakpoint-min-xxl) {
                    bottom: 124px + 74px;
                }
            }
        }
    }
}

.mod-stage-static.stage-fullheight-image.stage-only-image {
    .row {
        img {
            width: 100%;
            max-height: 420px;
        }
    }
}

.mod-stage-static.stage-fullheight-image {
    .row {
        .col-m-6.col-m-6-first-child {
            height: 337px;

            img {
                // do not stretch small header images, see id=32183
                @include respond-max($breakpoint-max-xs) {
                    max-width: none;
                    height: 100%;
                }
            }
        }
    }
}