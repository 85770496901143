@charset "UTF-8";

/* custom-select */

@each $color in $color-pattern-list {
    $index: index($color-pattern-list, $color);
    $contrast-color: nth($color-pattern-list-contrast, $index);

    .color-pattern-#{$index} .mod-search-jobs,
    .color-pattern-#{$index} .mod-search-events,
    .color-pattern-#{$index} .mod-search-topics,
    .color-pattern-#{$index} .mod-goto-article {

        .wide button:after {
            background: $color;
        }
        .wide button:hover:after {
            background: $color;
        }

        .field button:after,
        .field span:after {
            background: $color !important;
            color: $white !important;
        }
    }

    .color-pattern-#{$index}.color-pattern-contrast .mod-search-jobs,
    .color-pattern-#{$index}.color-pattern-contrast .mod-search-events,
    .color-pattern-#{$index}.color-pattern-contrast .mod-search-topics,
    .color-pattern-#{$index}.color-pattern-contrast .mod-goto-article {
        .field button:after,
        .field span:after {
            background: $contrast-color !important;
            color: $white;
        }
    }
}

.mod-search-jobs,
.mod-search-events,
.mod-search-topics,
.mod-goto-article {
    /* --- Reset form elements --- */
    input,
    select {
        margin: 0;
        padding: 0;
        outline: none;
        font-family: inherit;
        -moz-box-sizing: border-box; /* Firefox */
        -webkit-box-sizing: border-box; /* Safari */
        box-sizing: border-box;
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        appearance: none;
    }

    select::-ms-expand {
        display: none;
    }

    /* --- Inputs and selects --- */

    .field {
        position: relative;
        background: $finder-elements-bg;
        border: 1px solid $border-color-form;

        @media only screen and (min-width: $breakpoint-min-m) {
            &:hover {
                border: 1px solid $icon-hover-color;
            }
        }

        select {
            @include fontSizeREM(16);
            font-weight: 600;
            padding: 15px 10px 33px 10px;
            vertical-align: top;
            z-index: 1;
            background: transparent;
            position: relative;

            &:active {
                border: none;
                outline: none;
            }

            @include respond-min($breakpoint-min-m) {
                @include fontSizeREM(14);
            }
        }

        button,
        span {
            position: absolute;
            top: 35px;
            left: 15px;
            @include fontSizeREM(12);
            @extend .icon-after;
            width: 100%;
            z-index: 0;

            &:after {
                @extend .icon-down;
                @include icon-background(30, #fff);
                @include fontSizeREM(20);
                border: 1px solid $border-color-form;
                color: $border-color-form;
                position: absolute;
                right: 30px;
                top: -15px;
            }
        }

        button {
            right: 20px;
            left: auto;
            font-size: 0;
            width: 10px;
            height: 10px;
            margin-right: 5px;
            border: 0;
            background: transparent;

            &:after {
                right: -10px;
                height: 30px;
            }

            &:hover:after {
                background: $icon-hover-color;
            }

        }
    }

    .wide {
        input {
            @include fontSizeREM(16);
            height: 60px;
            background: $white;
        }

        button:after,
        span:after {
            @extend .icon-search;
            @include icon-background(30, nth($color-pattern-list, 6));
            @include fontSizeREM(26);
            color: #fff;
            border: 0;
            top: -20px;
        }
    }

    /* --- Select menu --- */

    /* For IE and Firefox */

    div.styled {
        overflow: hidden;
        padding: 0;
        margin: 0;
    }

    div.styled select {
        width: 115%;
        background-color: transparent;
        background-image: none;
        -webkit-appearance: none;
        border: none;
        box-shadow: none;
    }

    /* --- Form Submit and Next buttons --- */

    input[type="submit"],
    input[type="button"] {
        padding: 0.5em 1em;
        line-height: 1em;
        cursor: pointer;
        border-radius: 4px;
        color: #000;
        font-weight: bold;
        font-size: inherit;
        border: solid 1px #ccc;
        background-position: center bottom;
    }

    /* Gradient Generator by : http://www.colorzilla.com/gradient-editor/ */

    input[type="submit"]:active,
    input[type="button"]:active {
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    /*--- Media Queries --- */

    @media screen and (max-width: 600px) {

        body {
            width: 80%;
            font-size: 15px;
        }
    }
    /* end of query */

    @media screen and (max-width: 400px) {

        input[type="text"],
        select,
        div.styled {
            width: 100%
        }
    }
    /* end of query */

}

.color-pattern-contrast {

    .mod-search-jobs,
    .mod-search-events,
    .mod-search-topics,
    .mod-goto-article {

        .field {
            background: $white;
            border: 1px solid $black;

            &:hover {
                outline: 1px solid $black;
            }

            button:after {
                color: $white;
                border-color: $black;
                background: $icon-contrast-color;
            }

            > span:after {
                background: $white;
                border-color: $black;
                color: $black;
            }
        }
    }
}

.landpro {
    .mod-search-events {
        .field {
            background: $white;
        }
    }
    .btn-set input.submit {
        background: $white !important;
    }
}
