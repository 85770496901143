@charset "UTF-8";

/* mod-table */

@each $color in $color-pattern-list {
    $index: index($color-pattern-list, $color);
    $contrast-color: nth($color-pattern-list-contrast, $index);

    .color-pattern-#{$index} .mod-table thead tr {
        background: rgba($color, .5) !important;
    }

    .color-pattern-contrast.color-pattern-#{$index} .mod-table thead tr {
        background: rgba($contrast-color, .5) !important;
    }
}

%general-th-styles {
    padding: 1em;
    text-align: left;
}

.mod-table {
    text-align: left;
    width: 100%;
    font-size: 14px;
    margin-top: $grid-gutter-width;
    margin-bottom: $grid-gutter-width;

    .scroll-table {
        max-height: 400px;
        overflow: scroll;
    }

    // RV why: because else any content would be squished to the minimal with causing thousands of breaks
    table {
        width: 900px;
    }

    thead {

        tr {
            border-bottom: 5px solid $white;
        }

        th {
            @extend %general-th-styles;
            vertical-align: top;
            font-weight: 500;
            /* RV WHY
            width: 215px;
            */

            span {
                font-weight: 100;
            }

            /* RV WHY
            &:first-child {
                width: 360px;
            }

            &:last-child {
                width: 180px;
            }
            */
        }
    }

    tbody {
        th {
            @extend %general-th-styles;
        }

        td {
            min-height: 62px;
            padding: 1em;
            vertical-align: top;
        }

        tr {
            background: $table-color-1;
            border-bottom: 2px solid $white;

            &:nth-child(even) {
                background: $table-color-2;
            }
        }
    }

    @include respond-min($breakpoint-min-xl) {

        .scroll-table {
            overflow: inherit;
            max-height: 100%;
        }
    }
}

.cf {
    &:after {
        clear: both;
        content: " ";
        display: block;
        font-size: 0;
        height: 0;
        visibility: hidden;
    }
}

// Contrast-Styles
.color-pattern-contrast {

    .mod-table {
        color: $black;

        thead {
            border: 2px solid;

            tr {
                background: $white !important;
                color: $black !important;
            }
        }

        tr {

            border: 1px solid $black;
            background: $white;
            margin-bottom: 5px;

            &:nth-child(even) {
                background: $white;
            }
        }
    }
}