@charset "UTF-8";

/* layout */

* {
    box-sizing: border-box;
}

body {
    background: #fff;
    color: $text-color;
    font-size: $body-font-size;
    line-height: 1.4;
    position: relative;
    @include respond-max($breakpoint-max-xs) {
        @include prefix(hyphens, 'auto');
    }
}

.page-wrapper {
    overflow: hidden;
}

.wrapper {
    position: relative;
    left: 0;

    /* tablet */
    @include respond-min($breakpoint-min-m) {
        padding-left: calcPxToEm(76);
    }

    /* desktop */
    @include respond-min($breakpoint-min-l) {
        padding-left: calcPxToEm(240);
    }

    &:after {
        @extend %clearfix;
    }
}

main {

    padding-bottom: $grid-gutter-width * 4;

    &:after {
        @extend %clearfix;
    }

    .container {
        @include respond-min($breakpoint-max-l) {
            .csc-bulletlist {
                max-width: 65%;

                .csc-bulletlist {
                    max-width: 100%;
                }
            }
        }
    }
}

.container {
    transition: max-width .3s linear;

    .row:last-child {
        margin-bottom: 0;
    }

    .row_block {
        display: block;
        clear: both;
        float: none;

    }

    > p, > ol, > ul {
        @include respond-min($breakpoint-max-l) {
            max-width: 73%;
        }
    }

    /* wrapper .content-wrap will be created by rte */
    .content-wrap {
        /* RV this will fuck up accordions beside table of contents
        clear: both;
        */
        overflow: hidden;
    }

    @include respond-min($breakpoint-max-l) {

        /*
        RV disabled .box p cause leaves too much space and is unexpected
        $breakpoint-max-l is just some pixels before maximum
        .box {
            ul, ol {
                max-width: 68%;
            }
        }
        */

        .accordion-item:not(.wide) {
            /* RV this rule sucks
            max-width: 75%;
            */
            max-width: 100%;

            p, ul, ol {
                max-width: 100%;
            }

            .mod-table {
                margin-top: 0;
            }

            table {
                width: auto;
                max-width: 100%;
            }
        }
    }
}

.spaceBefore {
    padding-top: 20px;
}

.spaceAfter {
    padding-bottom: 20px;
}

.spaceAfter2 {
    padding-bottom: 30px;
}

.clearimage {
    clear: both;
}

.spaceBefore10 {
    padding-top: 10px;
}

.spaceBefore20 {
    padding-top: 20px;
}

.spaceBefore30 {
    padding-top: 30px;
}

.spaceBefore40 {
    padding-top: 40px;
}

.spaceBefore50 {
    padding-top: 50px;
}

.spaceAfter10 {
    padding-bottom: 10px;
}

.spaceAfter20 {
    padding-bottom: 20px;
}

.spaceAfter30 {
    padding-bottom: 30px;
}

.spaceAfter40 {
    padding-bottom: 40px;
}

.spaceAfter50 {
    padding-bottom: 50px;
}

/*
  Content Elements
   */

noscript {
    color: $white;
    margin: .2em 0;
    background: $ci-color;
    padding: .2em 0;
    text-align: center;

    p {
        margin: 0 auto;
        max-width: #{$container-max-width}px;
    }
}

a {
    color: $link-color;

    &:hover {
        color: $link-color-hover;
        text-decoration: none;
    }
}

p {
    margin: 20px 0;
}

.csc-bulletlist {
    > li {
        margin: 5px 0 5px 0;
    }
}

ul.csc-bulletlist {
    > li {
        position: relative;
        text-indent: -10px;

        /* bugfix for above, fix Charité 3<sup>R</sup> */
        sup,sub {
            text-indent: 0;
        }

        &:before {
            content: "•";
            font-size: 1.7em;
            // position: absolute;
            position: relative;
            top: 3px;
            left: -5px;
        }
    }

    p {
        margin: 0;
    }
}

ol.csc-bulletlist {
    counter-reset: item;

    > li {
        text-indent: -15px;
        &:before {
            content: counter(item) ". ";
            counter-increment: item;
        }
    }
}

dfn {
    font-style: normal;
}

img {
    height: auto;
    outline: 0;
    max-width: 100%;
}

hr {
    @extend %clearfix;
    margin: 20px 0 30px 0;
}

ul, ol {
    margin-top: 0;
    @include fontSizeREM(14);
    line-height: 20px;
    padding-left: 16px;
    list-style: none;
}

p ul,
p ol {
    padding-left: 11px;
}

nav ul,
nav ol {
    margin: 0;
    padding: 0;
    list-style: none;
}

select {
    cursor: pointer;
}

input,
textarea,
select {
    @include fontSizeREM(16);

    @include respond-min($breakpoint-min-m) {
        @include fontSizeREM(14);
    }
}

.skiplinks,
.hidden-text,
dfn.menu {
    text-indent: -10000px;
    height: 0;
    margin: 0;
    position: absolute;
    left: -1000px;
    top: -1000px;
    width: 0;
    overflow: hidden;
    display: inline;
}

// Contrast-Styles
.color-pattern-contrast {
    color: $black;

    p:not(.sliderIcon p),
    li,
    .glossary {
        color: #000;
    }

    hr {
        height: 2px;
        background: $black;
    }
}

.print-ie-only,
.print-only,
.showInPrint {
    display: none !important;
}

/*
   media queries -
   for viewport detection only!
    */

/* Featurephones and Smartphones (portrait) */
@include respond-min($breakpoint-min-xs) {
    body:after {
        content: 'featurephone';
        position: absolute;
        top: -100em;
        left: -100em;
        width: 1px;
        height: 1px;
        overflow: hidden;
    }
}

/* Smartphones (landscape)  */
@include respond-min($breakpoint-min-s) {
    body:after {
        content: 'smartphone';
        position: absolute;
        top: -100em;
        left: -100em;
        width: 1px;
        height: 1px;
        overflow: hidden;
    }
}

/* Tablet (portrait) */
@include respond-min($breakpoint-min-m) {
    body:after {
        content: 'tablet';
        position: absolute;
        top: -100em;
        left: -100em;
        width: 1px;
        height: 1px;
        overflow: hidden;
    }
}

/* Desktop */
@include respond-min($breakpoint-min-l) {
    body:after {
        content: 'desktop';
        position: absolute;
        top: -100em;
        left: -100em;
        width: 1px;
        height: 1px;
        overflow: hidden;
    }
}

/* Full HD and Retina */
@include respond-min($breakpoint-min-xl) {
    body:after {
        content: 'retina';
        position: absolute;
        top: -100em;
        left: -100em;
        width: 1px;
        height: 1px;
        overflow: hidden;
    }
}

.landpro {
    .box {
        background: $white;
        overflow: hidden;
        padding: $grid-gutter-width;

        p:last-child {
            margin-bottom: 0;
        }
    }

    h3, h4, h5 {
        margin-top: $grid-gutter-width;
    }

    .teaser-content h3,
    .teaser-content h4,
    .teaser-content h5,
    .list-events h3,
    .list-events h4,
    .list-events h5,
    .mod-search-jobs h3,
    .mod-search-jobs h4,
    .mod-search-jobs {
        margin-top: 0;
    }

    .video-element .box {
        padding: 0;
        background: none;

        figcaption {
            background: none;
        }
    }
}

// .landpro .whiteBackground shall overrule existing
// but .whiteBackground shall be supported everywhere
.landpro .whiteBackground,
.whiteBackground {

    background: $white;
    overflow: hidden;
    padding: $grid-gutter-width $grid-gutter-width 0 $grid-gutter-width;

    > h1, > h2, > h3, > h4, > strong {
        margin-top: 0;
        background-color: white;
    }

    > .box {
        background: transparent;
        overflow: hidden;
        padding: 0;
    }
}

.landingpage {
    .container {
        max-width: (980 / $body-font-size-px) + em;
    }

    @include respond-min($breakpoint-min-m) {
        .container {
            margin: 0;
            padding: 0 $grid-gutter-width;
        }
    }

    @include respond-min($breakpoint-min-l) {
        .container {
            margin: 0 auto;
        }
    }
}

iframe {
    border: 0;
}