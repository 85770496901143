@charset "UTF-8";

/* mod-bloc-contact */

@each $color in $color-pattern-list {
    $index: index($color-pattern-list, $color);
    $contrast-color: nth($color-pattern-list-contrast, $index);

    .color-pattern-#{$index} .mod-bloc-contact {
        .tel,
        .fax,
        .mail,
        .route,
        .street-address,
        .street-address > p {
            &:before {
                background-color: $color;
            }
        }
    }

    .color-pattern-contrast.color-pattern-#{$index} .mod-bloc-contact {
        .tel,
        .fax,
        .mail,
        .route,
        .street-address,
        .street-address > p {
            &:before {
                background-color: $contrast-color;
            }
        }
    }
}

.mod-bloc-contact {
    @extend %row;
    margin: 0 0 $grid-gutter-width 0;

    > div {
        width: 100%;
        background: $main-color;
        padding: $grid-gutter-width * .5;

        &:after {
            @extend %clearfix;
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    p > span {
        display: block;

        &[itemprop="postalCode"],
        &[itemprop="addressLocality"] {
            display: inline-block !important;
        }
    }

    // icons: start
    .tel,
    .fax,
    .mail,
    .route,
    .street-address > p {
        margin: ($grid-gutter-width * .5) 0 0 37px;
        text-decoration: none; // a21 ie11 fix underline icon

        &:before {
            @extend %icon-font;
            @include icon-background(26, #a0a5a9);
            @include fontSizeREM(24);
            margin: -5px 10px 0 -37px;
            vertical-align: middle;
            z-index: 5;
        }

        &:after {
            display: none !important;
        }
    }

    .tel:before {
        @extend .icon-phone;
    }

    .fax:before {
        @extend .icon-print;
    }

    .mail:before {
        @extend .icon-mail;
    }
    // icons: end

    .person {
        padding: $grid-gutter-width * .5 $grid-gutter-width * .5 0 $grid-gutter-width * .5;
        overflow: auto;

        a {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
            &.glossary {
                text-decoration: none;
            }
        }

        p {
            word-wrap: break-word;
        }
    }

    .contact,
    .street-address > p {
        margin: 0 0 1em 0;
    }

    .street-address {
        > p {
            margin-top: 0.8em;
            padding: 0 0 0 2.5em;
            display: inline-block;
            float: inherit;

            &:before {
                @extend .icon-locations;
            }

            &:after {
                display: none !important;
            }
        }

        p {
            float: left;
        }

        > div {
            margin-left: ($grid-gutter-width * 1.75);
        }
    }

    .route {
        margin: ($grid-gutter-width * .5) 0 0 ($grid-gutter-width * 1.75);
        float: left;

        @include fontSizeREM(14);

        &:before {
            @extend .icon-route;
        }
    }

    .extern-link-set li:first-child {
        font-weight: bold;
    }

    @include respond-min($breakpoint-min-s) {

        .contact, .street-address {
            width: 100%;
        }

        .contact {
            float: left;
            margin-bottom: 2rem;
        }

        .street-address {
            float: right;
        }
    }

    @include respond-min($breakpoint-min-m) {
        .person + div {
            margin-bottom: ($grid-gutter-width * .5);

            + div {
                margin-bottom: ($grid-gutter-width * .5);
            }
        }
    }

    @include respond-min($breakpoint-min-l) {
        > div {
            width: 50%;
            float: left;
        }

        &.normal-bloc > div {
            width: 49% !important;
            margin-right: 2%;
            margin-bottom: 20px;

            &:nth-child(odd) {
                clear: left;
            }

            &:nth-child(even) {
                margin-right: 0;
            }
        }
    }

    &.big-bloc {
        .col-m-4:first-child {
            display: none;
        }

        .extern-link-set {
            float: left;
        }

        @include respond-min($breakpoint-min-m) {
            > div {
                padding: 0 $grid-gutter-width 0 0;
            }

            .person {
                padding: $grid-gutter-width ($grid-gutter-width * .5) 0 ($grid-gutter-width * .5);
                overflow: auto;
            }

            .col-m-4:first-child {
                display: block;
                padding: 0 ($grid-gutter-width * .5) 0 0;
            }
        }

        @include respond-min($breakpoint-min-l) {

            > div {
                width: 100%
            }
        }
    }

    &.small-link {
        > div {
            width: 100%;
            margin-bottom: 5px;
        }

        .person {
            float: left;
            padding: 10px;

            p {
                margin-bottom: 0;
                word-wrap: break-word;
            }
        }

        .contact {
            width: auto;
            margin: 5px 0 10px 0;
        }

        .contact li {
            display: inline-block;
            margin: $grid-gutter-width * .5 $grid-gutter-width * .5 0 $grid-gutter-width * 2.5;
        }

        @include respond-min($breakpoint-min-s) {
            .contact {
                float: right;
            }

            .person {
                max-width: 50%;
            }
        }
    }

    &.vcard {
        > div {
            width: 100%;
            padding: 0;
        }

        .person {
            float: left;
            display: inline-block;
            padding: $grid-gutter-width;

            strong {
                min-height: 45px;
                display: block;
            }
        }

        .contact {
            margin-bottom: 0;

            li {
                margin-top: 0;
            }
        }

        img {
            display: none;
            max-height: 200px;
        }

        @include respond-min($breakpoint-min-s) {
            img {
                display: block;
                float: right;
                width: 40%;
                max-width: 155px;
            }

            .person {
                max-width: 60%;
            }
        }
    }
}

// Contrast-Color
.color-pattern-contrast {
    .mod-bloc-contact > div {
        background: $white;
        border: 1px solid $black;
        color: $black;

        .tel:before, .fax:before, .mail:before, .route:before, .street-address p:before {
            background: $icon-contrast-color;
        }
    }
}

@include respond-min($breakpoint-min-l) {
    .col-s-6 .mod-bloc-contact.normal-bloc > div {
        width: 100% !important;
    }
}

/* unset this stupid rule - it conflicts with glossary language span */
.wrapper .mod-bloc-contact p > span {
    display: inline;
    &.block {
        display: block;
    }
}

// new for microsites
.mod-bloc-contact {
    &.vcard > div {
        background: $main-color;
    }

    &.big-vcard > div {
        background: $main-color;
        padding: 0;

        width: auto;
        float: none;

        .person {
            float: left;
            padding: $grid-gutter-width $grid-gutter-width 0 $grid-gutter-width;

            strong {
                min-height: 45px;
                display: block;
            }
        }

        img {
            display: none;
            max-height: 200px;
        }

        @include respond-min($breakpoint-min-s) {
            .person {
                width: 60%;
            }
            img {
                display: block;
                float: right;
                width: 40%;
                max-width: 155px;
            }
        }
        @include respond-min($breakpoint-min-m) {
            .person {
                width: 75%;
            }
        }
    }

    &.small-vcard > div {
        background: $main-color;
        padding: $grid-gutter-width $grid-gutter-width 0 $grid-gutter-width;
        width: auto;

        img {
            display: none;
            margin-bottom: $grid-gutter-width;
        }

        .person {
            padding: 0;
            min-height: 120px;

            strong {
                display: block;
                margin: 0 0 $grid-gutter-width 0;
            }

        }

        @include respond-min($breakpoint-min-s) {
            img {
                display: block;
            }
        }
    }
}

#vita table {
    width: 100%;
    font-size: .875rem;

    td {
        padding: 5px 5px 5px 0;
        vertical-align: top;
    }
    td:first-child {
        width: 20%;
        font-weight: bold;
    }
}

.landpro {
    .mod-bloc-contact {
        &.vcard > div,
        &.big-vcard > div,
        &.small-vcard > div,
        &.small-link > div,
        &.normal-bloc > div {
            background: $white;
        }
    }
}

.wrapper .mod-stage-static .mod-bloc-contact > div {
    background: transparent;
    margin-bottom: 0;
}