@charset "UTF-8";

/* TODO REMOVE BUT mixins below are crucial */

@mixin respond-min($width) {
    @media screen and (min-width: $width) {
        @content;
    }
}

@mixin respond-max($width) {
    @media screen and (max-width: $width) {
        @content;
    }
}
