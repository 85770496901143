@charset "UTF-8";

/* icons */

// RV IMPORTANT we use microsite icons now - cause they are expanded
// icon font declaration
@font-face {
    font-family: 'charite-icons';
    src: url('../font-2020/charite-icons-2020.eot?1bbzt4');
    src: url('../font-2020/charite-icons-2020.eot?#iefix1bbzt4') format('embedded-opentype'),
    url('../font-2020/charite-icons-2020.woff?1bbzt4') format('woff'),
    url('../font-2020/charite-icons-2020.ttf?1bbzt4') format('truetype'),
    url('../font-2020/charite-icons-2020.svg?1bbzt4#charite') format('svg');
    font-weight: normal;
    font-style: normal;
}

/**
 *    icon-circle
 *
 *    desc:    create a cycle around an icon
 *
 *    @param:    $size    [number]        //diameter of the cycle
 *
 */
@mixin icon-circle($size) {
    border-radius: ($size/2) + px;
    box-sizing: border-box;

    border: solid 1px $text-color;
    display: inline-block;
    text-align: center;
    line-height: $size + px;
    height: $size + px;
    width: $size + px;
}

/**
 *    icon-background
 *
 *    desc:    create a cycle around an icon with a background-color
 *
 *    @param:    $size    [number]        //diameter of the cycle
 *    @param:    $color    [string]        //color
 *
 */
@mixin icon-background($size, $color: #dadcde) {
    border-radius: ($size/2) + px;
    box-sizing: border-box;

    display: inline-block;
    text-align: center;
    background: $color;
    color: #fff;
    line-height: $size + px;
    height: $size + px;
    width: $size + px;
}

// icon-font definition
%icon-font {
    font-family: 'charite-icons';
    @media speech {
        speak: none;
    }
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-size: 1rem;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// HELPERS for Icons
.iconStandardSize:before, .iconStandardSize:after {
    font-size: $icon-font-size !important;
}

.iconSizeBig:before, .iconSizeBig:after {
    font-size: $icon-font-size*2 !important;
}

//icon-before
.icon-before {
    &:before {
        @extend %icon-font;
        @include fontSizeREM(42);
        margin-right: $grid-gutter-width/2;
        vertical-align: -30%;
    }

    &:after {
        display: none !important;
    }
}

//icon-after
.icon-after {
    &:before {
        display: none !important;
    }

    &:after {
        @extend %icon-font;
        @include fontSizeREM(42);
        margin-left: $grid-gutter-width/2;
        vertical-align: -30%;
    }
}

//icon-both
.icon-both {
    &:before,
    &:after {
        @extend %icon-font;
    }
}

//icon-only
.icon-only {
    font-size: 0 !important;

    &:before {
        @extend %icon-font;
        @include fontSizeREM(42);
        margin: 0;
        vertical-align: -30%;
    }

    &:after {
        display: none !important;
    }
}

//icon-only-both
.icon-only-both {
    font-size: 0;

    &:before,
    &:after {
        @include fontSizeREM();
        @extend %icon-font;
    }
}

//icon-before

.icon-help:before {
    content: "\e653";
}

.icon-mail_alt:before {
    content: "\e652";
}

.icon-accessible:before {
    content: "\e600";
}

.icon-advanced-study:before {
    content: "\e601";
}

.icon-ambulant:before {
    content: "\e602";
}

.icon-ambulant_service:before {
    content: "\e603";
}

.icon-arabic:before {
    content: "\e604";
}

.icon-arrival:before {
    content: "\e605";
}

.icon-attentiojn:before {
    content: "\e606";
    vertical-align: -15%;
}

.icon-audio:before {
    content: "\e607";
}

.icon-basic-study:before {
    content: "\e608";
}

.icon-career:before {
    content: "\e609";
}

.icon-charite:before {
    content: "\e60a";
}

.icon-clinic:before {
    content: "\e60b";
}

.icon-complainment:before {
    content: "\e60c";
}

.icon-construction:before {
    content: "\e60d";
}

.icon-delete:before {
    content: "\e60e";
}

.icon-DOC:before,
.icon-doc:before {
    content: "\e61c";
}

.icon-down:before {
    content: "\e610";
}

.icon-english:before {
    content: "\e611";
}

.icon-expert:before {
    content: "\e612";
}

.icon-extern:before {
    content: "\e613";
}

.icon-feedback:before {
    content: "\e614";
}

.icon-finder-close:before {
    content: "\e615";
}

.icon-finder-open:before {
    content: "\e616";
}

.icon-german:before {
    content: "\e617";
}

.icon-graduation:before {
    content: "\e618";
}

.icon-history:before {
    content: "\e619";
}

.icon-information:before {
    content: "\e61a";
}

.icon-infografik1:before {
    content: "\e651";
}

.icon-infografik2:before {
    content: "\E64F";
}

.icon-infografik3:before {
    content: "\E650";
}

.icon-international:before {
    content: "\e61b";
}

.icon-JPG:before,
.icon-jpg:before {
    content: "\e60f";
}

.icon-leader-staff:before {
    content: "\e61d";
}

.icon-left:before {
    content: "\e635";
}

.icon-right:before {
    content: "\e61e";
}

.icon-locations:before {
    content: "\e61f";
}

.icon-mail:before {
    content: "\e620";
}

.icon-material:before {
    content: "\e621";
}

.icon-medicacenter:before {
    content: "\e622";
}

.icon-medical-field:before {
    content: "\e623";
}

.icon-menu:before {
    content: "\e624";
}

.icon-MP3:before,
.icon-mp3:before {
    content: "\e625";
}

.icon-MP4:before,
.icon-mp4:before {
    content: "\e626";
}

.icon-MPG:before,
.icon-mpg:before {
    content: "\e627";
}

.icon-museum:before {
    content: "\e628";
}

.icon-network:before {
    content: "\e900";
}

.icon-news:before {
    content: "\e629";
}

.icon-organisation:before {
    content: "\e62a";
}

.icon-PDF:before,
.icon-pdf:before {
    content: "\e62b";
}

.icon-phone:before {
    content: "\e62c";
}

.icon-phone-alt:before {
    content: "\e62d";
}

.icon-piblication:before {
    content: "\e62e";
}

.icon-PNG:before,
.icon-png:before {
    content: "\e62f";
}

.icon-press:before {
    content: "\e630";
}

.icon-print:before {
    content: "\e631";
}

.icon-publications:before,
.icon-publication:before {
    content: "\e902";
}

.icon-research:before {
    content: "\e632";
}

.icon-research-projects-24:before {
    content: "\e633";
}

.icon-research-projects-48:before {
    content: "\e634";
}

.icon-route:before {
    content: "\e636";
}

.icon-rss:before,
.icon-rs_block_s:before {
    content: "\e637";
}

.icon-russian:before {
    content: "\e638";
}

.icon-science:before {
    content: "\e639";
}

.icon-search:before {
    content: "\e63a";
}

.icon-share:before,
.icon-sha_block_re:before {
    content: "\e63b";
}

.icon-starbust:before {
    content: "\e63c";
}

.icon-study-info:before {
    content: "\e63d";
}

.icon-TIF:before,
.icon-tif:before {
    content: "\e63e";
}

.icon-top:before {
    content: "\e63f";
}

.icon-tradition:before {
    content: "\e640";
}

.icon-training:before {
    content: "\e641";
}

.icon-treatment:before {
    content: "\e642";
}

.icon-university:before {
    content: "\e643";
}

.icon-complainment:before {
    content: "\e60C";
}

.icon-video:before {
    content: "\e644";
}

.icon-XLS:before,
.icon-xls:before {
    content: "\e645";
}

.icon-ZIP:before,
.icon-zip:before {
    content: "\e646";
}

.icon-zoom-in:before {
    content: "\e647";
}

.icon-zoom-out:before {
    content: "\e648";
}

.icon-bookmark:before {
    content: "\e64c";
}

.icon-DOCX:before,
.icon-docx:before {
    content: "\e64d";
}

.icon-GIF:before,
.icon-gif:before {
    content: "\e64e";
}

.icon-lesscontrast:before {
    content: "\e649";
}

.icon-morecontrast:before {
    content: "\e64a";
}

.icon-download:before {
    content: "\e64b";
}

.icon-heartbeat-infographic:before {
    content: "\e64f";
}

.icon-kindergarden-infographic:before {
    content: "\e650";
}

.icon-rocket-infographic:before {
    content: "\e651";
}

// icon:after

.icon-help:after {
    content: "\e653";
}

.icon-mail_alt:after {
    content: "\e652";
}

.icon-accessible:after {
    content: "\e600";
}

.icon-advanced-study:after {
    content: "\e601";
}

.icon-ambulant:after {
    content: "\e602";
}

.icon-ambulant_service:after {
    content: "\e603";
}

.icon-arabic:after {
    content: "\e604";
}

.icon-arrival:after {
    content: "\e605";
}

.icon-attentiojn:after {
    content: "\e606";
}

.icon-audio:after {
    content: "\e607";
}

.icon-basic-study:after {
    content: "\e608";
}

.icon-career:after {
    content: "\e609";
}

.icon-charite:after {
    content: "\e60a";
}

.icon-clinic:after {
    content: "\e60b";
}

.icon-complainment:after {
    content: "\e60c";
}

.icon-construction:after {
    content: "\e60d";
}

.icon-delete:after {
    content: "\e60e";
}

.icon-DOC:after,
.icon-doc:after {
    content: "\e61c";
}

.icon-down:after {
    content: "\e610";
}

.icon-english:after {
    content: "\e611";
}

.icon-expert:after {
    content: "\e612";
}

.icon-extern:after {
    content: "\e613";
}

.icon-feedback:after {
    content: "\e614";
}

.icon-finder-close:after {
    content: "\e615";
}

.icon-finder-open:after {
    content: "\e616";
}

.icon-german:after {
    content: "\e617";
}

.icon-graduation:after {
    content: "\e618";
}

.icon-history:after {
    content: "\e619";
}

.icon-information:after {
    content: "\e61a";
}

.icon-infografik1:after {
    content: "\e651";
}

.icon-infografik2:after {
    content: "\E64F";
}

.icon-infografik3:after {
    content: "\E650";
}

.icon-international:after {
    content: "\e61b";
}

.icon-JPG:after,
.icon-jpg:after {
    content: "\e60f";
}

.icon-leader-staff:after {
    content: "\e61d";
}

.icon-left:after {
    content: "\e635";
}

.icon-right:after {
    content: "\e61e";
}

.icon-locations:after {
    content: "\e61f";
}

.icon-mail:after {
    content: "\e620";
}

.icon-material:after {
    content: "\e621";
}

.icon-medicacenter:after {
    content: "\e622";
}

.icon-medical-field:after {
    content: "\e623";
}

.icon-menu:after {
    content: "\e624";
}

.icon-MP3:after,
.icon-mp3:after {
    content: "\e625";
}

.icon-MP4:after,
.icon-mp4:after {
    content: "\e626";
}

.icon-MPG:after,
.icon-mpg:after {
    content: "\e627";
}

.icon-museum:after {
    content: "\e628";
}

.icon-network:after {
    content: "\e900";
}

.icon-news:after {
    content: "\e629";
}

.icon-organisation:after {
    content: "\e62a";
}

.icon-PDF:after,
.icon-pdf:after {
    content: "\e62b";
}

.icon-phone:after {
    content: "\e62c";
}

.icon-phone-alt:after {
    content: "\e62d";
}

.icon-piblication:after {
    content: "\e62e";
}

.icon-PNG:after,
.icon-png:after {
    content: "\e62f";
}

.icon-press:after {
    content: "\e630";
}

.icon-print:after {
    content: "\e631";
}

.icon-publications:after,
.icon-publication:after {
    content: "\e902";
}

.icon-research:after {
    content: "\e632";
}

.icon-research-projects-24:after {
    content: "\e633";
}

.icon-research-projects-48:after {
    content: "\e634";
}

.icon-route:after {
    content: "\e636";
}

.icon-rss:after,
.icon-rs_block_s:after {
    content: "\e637";
}

.icon-russian:after {
    content: "\e638";
}

.icon-science:after {
    content: "\e639";
}

.icon-search:after {
    content: "\e63a";
}

.icon-share:after,
.icon-sha_block_re:after {
    content: "\e63b";
}

.icon-starbust:after {
    content: "\e63c";
}

.icon-study-info:after {
    content: "\e63d";
}

.icon-TIF:after,
.icon-tif:after {
    content: "\e63e";
}

.icon-top:after {
    content: "\e63f";
}

.icon-tradition:after {
    content: "\e640";
}

.icon-training:after {
    content: "\e641";
}

.icon-treatment:after {
    content: "\e642";
}

.icon-university:after {
    content: "\e643";
}

.icon-complainment:after {
    content: "\e60C";
}

.icon-video:after {
    content: "\e644";
}

.icon-XLS:after,
.icon-xls:after {
    content: "\e645";
}

.icon-ZIP:after,
.icon-zip:after {
    content: "\e646";
}

.icon-zoom-in:after {
    content: "\e647";
}

.icon-zoom-out:after {
    content: "\e648";
}

.icon-bookmark:after {
    content: "\e64c";
}

.icon-DOCX:after,
.icon-docx:after {
    content: "\e64d";
}

.icon-GIF:after,
.icon-gif:after {
    content: "\e64e";
}

.icon-lesscontrast:after {
    content: "\e649";
}

.icon-morecontrast:after {
    content: "\e64a";
}

.icon-download:after {
    content: "\e64b";
}

.icon-heartbeat-infographic:after {
    content: "\e64f";
}

.icon-kindergarden-infographic:after {
    content: "\e650";
}

.icon-rocket-infographic:after {
    content: "\e651";
}

p.phone,
p.fax,
p.email,
p.location,
p.route {

    margin: ($grid-gutter-width * .5) 0 $grid-gutter-width 37px;

    &:before {
        @extend %icon-font;
        @include icon-background(26, #a0a5a9);
        @include fontSizeREM(24);
        margin: -5px 10px 0 -37px;
        vertical-align: middle;
        z-index: 5;
        content: "\e62c";
    }

    a {
        text-decoration: underline;
        color: $text-color !important;
    }
}

p.fax:before {
    content: "\e631";
}

p.email:before {
    content: "\e620";
}

p.location:before {
    content: "\e61f";
}

p.route:before {
    content: "\e636";
}

@each $color in $color-pattern-list {
    $index: index($color-pattern-list, $color);
    $contrast-color: nth($color-pattern-list-contrast, $index);

    .color-pattern-#{$index} {
        p.phone,
        p.fax,
        p.email,
        p.location,
        p.route {
            &:before {
                background-color: $color;
            }
        }
    }

    .color-pattern-contrast.color-pattern-#{$index} {
        p.phone,
        p.fax,
        p.email,
        p.location,
        p.route {
            &:before {
                background-color: $contrast-color;
            }
        }
    }
}

.sliderIcon.icon-gutestun-4c {
    background-image: url(/typo3conf/ext/charite_site/Resources/Public/Images/Icons/icon-gutestun-4c.svg);
    background-repeat: no-repeat;
    background-size: cover;
}

.icon-before.icon-gutestun-4c:before,
.mod-teaser.icon-gutestun-4c:before {
    background-image: url(/typo3conf/ext/charite_site/Resources/Public/Images/Icons/icon-gutestun-4c.svg);
    background-repeat: no-repeat;
    background-size: cover;
    content: " ";
}

.sliderIcon.icon-gutestun-white {
    background-image: url(/typo3conf/ext/charite_site/Resources/Public/Images/Icons/icon-gutestun-white.svg);
    background-repeat: no-repeat;
    background-size: cover;
}

.icon-before.icon-gutestun-white:before,
.mod-teaser.icon-gutestun-black:before {
    background-image: url(/typo3conf/ext/charite_site/Resources/Public/Images/Icons/icon-gutestun-white.svg);
    background-repeat: no-repeat;
    background-size: cover;
    content: " ";
}

.sliderIcon.icon-gutestun-black {
    background-image: url(/typo3conf/ext/charite_site/Resources/Public/Images/Icons/icon-gutestun-black.svg);
    background-repeat: no-repeat;
    background-size: cover;
}

.icon-before.icon-gutestun-black:before,
.mod-teaser.icon-gutestun-white:before {
    background-image: url(/typo3conf/ext/charite_site/Resources/Public/Images/Icons/icon-gutestun-black.svg);
    background-repeat: no-repeat;
    background-size: cover;
    content: " ";
}

.icon-instagram:after {
    content: "\e939";
}

.icon-videochat:after {
    content: "\e93f";
}

.icon-appointment-videochat:after {
    content: "\e940";
}

.icon-twitter:after {
    content: "\e93a";
}

.icon-facebook:after {
    content: "\e93c";
}

.icon-icon-gutestun-black:after {
    content: "\e903";
}

.icon-icon-gutestun-white:after {
    content: "\e904";
}

.icon-logo:after {
    content: "\e906";
}

.icon-logo-white:after {
    content: "\e905";
}


.icon-instagram:before {
    content: "\e939";
}

.icon-videochat:before {
    content: "\e93f";
}

.icon-appointment-videochat:before {
    content: "\e940";
}

div footer a.icon-instagram:before {
    font-size: 0.95em;
    position: relative;
    top: 4px;
}

.icon-twitter:before {
    content: "\e93a";
}

.icon-facebook:before {
    content: "\e93c";
}

.icon-icon-gutestun-black:before {
    content: "\e903";
}

.icon-icon-gutestun-white:before {
    content: "\e904";
}

.icon-logo:before {
    content: "\e906";
}

.icon-logo-white:before {
    content: "\e905";
}

footer .footer-content a.icon-twitter:after,
footer .footer-content a.icon-twitter:before,
footer .footer-content a.icon-facebook:after,
footer .footer-content a.icon-facebook:before {
    font-size: 20px;
    font-size: 1.2rem;
}

.mod-meta-navigation .force_new_icon a span:before {
    content: url('../font-2020/charite-icons-erstehilfe.svg') !important;
    border-radius: 0 !important;
    background: transparent !important;
}